import React, { useMemo } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-scroll'
import Button from 'components/Button'

import { btns } from '.'

const Menu = ({ isOpen, onClose }) => {
  const buttons = useMemo(() => btns?.concat([
    { name: '訂閱追蹤', key: 'subscription' },
    { name: '關於我們', key: 'about' },
  ]), [])
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent pt="4em" px="2em">
        <DrawerCloseButton />
        <Stack>
          {buttons.map(({ name, key }) => (
            <Box key={key}>
              <Button
                as={(p) => <Link to={key} offset={-50} smooth {...p} />}
                p={'0.5em 0.75em'}
                w={'100%' }
                h={'auto'}
                border="1px solid black"
                borderRadius="full"
                bg="transparent"
                onClick={onClose}
              >
                {name}
              </Button>
            </Box>
          ))}
        </Stack>
      </DrawerContent>
    </Drawer>
  )
}

export default Menu
