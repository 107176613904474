import { groupBy } from 'lodash';
import Papa from 'papaparse';
import { useAsync } from 'react-use';
import getCacheTime from './getCacheTime';

const mainHeader = [
  'title',
  'category',
  'city',
  'description',
  'tags',
  'budget',
];

const childrenHeader = ['department', 'title', 'description', 'progress'];

const scheduleHeader = ['date', 'info'];

const handleChildren = (header) => (row) =>
  Object.fromEntries(header.map((key, j) => [key, row[j + 1]]));

const useSheetData = () =>
  useAsync(async () => {
    const t = getCacheTime();

    const [main, children, schedule, chartData] = await Promise.all(
      ['sheet-1.csv', 'sheet-2.csv', 'sheet-3.csv', 'sheet-4.csv'].map(
        (name) =>
          new Promise((resolve) => {
            Papa.parse(`/data/${name}?t=${t}`, {
              download: true,
              skipEmptyLines: true,
              escapeChar: '\\',
              complete: ({ data }) => resolve(data.slice(1)),
            });
          })
      )
    );
    const groupedChildren = groupBy(children, (row) => row[0]);
    const groupedSchedule = groupBy(schedule, (row) => row[0]);
    const chart = chartData.reduce((res, [city, before, after]) => {
      res[city] = { before: +before, after: +after };
      return res;
    }, {});
    // console.log(chart)
    return {
      data: main.map((row) => {
        const obj = Object.fromEntries(
          mainHeader.map((key, j) => [key, row[j]])
        );

        return {
          ...obj,
          tags: obj.tags.split('、').map((tag) => tag.trim()),
          children: groupedChildren[obj.title]?.map(
            handleChildren(childrenHeader)
          ),
          schedule: groupedSchedule[obj.title]?.map(
            handleChildren(scheduleHeader)
          ),
        };
      }),
      chart,
    };
  });

export default useSheetData;
