import React, { useEffect, useState } from 'react';
import { Box, Center, Image, Spinner, useDisclosure } from '@chakra-ui/react';

import Cover from './Cover';
import Description from './Description';
import Introduction from './Introduction';
import Explanation from './Explanation';
import Track from './Track';
import Overview from './Overview';
import Report from './Report';
import Subscription from './Subscription';
import Footer from './Footer';
import subscription from './subscription.png';
import RegisterModal from './RegisterModal';

const HomePage = () => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 1500)
  })
  const { onOpen, onClose, isOpen } = useDisclosure()
  return (
    <>
      <Center pos="absolute" top="0" left="0" right="0" opacity={+loading} h="100vh"><Spinner /></Center>
      <Box pos="relative" opacity={+!loading} transition={'opacity 1s'} overflow={'hidden'}>
        <Cover />
        <Description />
        <Introduction />
        <Explanation />
        <Track />
        <Overview />
        <Report />
        <Subscription />
        <Footer />
        <Box
          pos="fixed"
          right={{ base: '0.875em', md: '1.25em' }}
          bottom={{ base: '0.875em', md: '1.5em' }}
          width={{ base: '3.275em', md: '5.25em' }}
          cursor={'pointer'}
          onClick={onOpen}
          zIndex={55}
        >
          <Image src={subscription} />
        </Box>
        <RegisterModal isOpen={isOpen} onClose={onClose} />
      </Box>
    </>
  );
};

export default HomePage;
