import SVG from 'components/SVG';
import { useScroll, motion } from 'framer-motion';
import React, { useRef } from 'react';

const EnvelopeAnimation = (props) => {
  const ref = useRef(null);

  // const { scrollYProgress } = useScroll({
  //   target: ref,
  //   offset: ['1, 1', '0.5, 0.5'],
  // });

  return (
    <div ref={ref}>
      <SVG viewBox="0 0 466 402" {...props}>
        <rect x="121.717" width="225.536" height="318.614" fill="white" />
        <rect
          x="119.332"
          y="112.171"
          width="227.923"
          height="206.443"
          fill="#E85130"
          // initial={{
          //   scaleY: 0,
          // }}

          // style={{
          //   scaleY: scrollYProgress,
          //   originY: 1,
          // }}
        />
      </SVG>
    </div>
  );
};

export default EnvelopeAnimation;
