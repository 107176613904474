import React from 'react'
import { Box, Image, Container, useMediaQuery } from '@chakra-ui/react'
import { motion, useScroll, useTransform } from 'framer-motion';

import Text from 'components/Text'

import explanation_circle from './explanation_circle.png'
import deco_img from './deco_img.png'
import deco_mobile from './deco_mobile.png'
import right_deco from './right_deco.png'
import bottom_deco from './bottom_deco.png'

const MotionBox = motion(Box);

const startProgress = [0.245, 0.23];

const AnimateMove = ({ fullScroll, initialY, children, start = startProgress }) => {
  const [isMobile] = useMediaQuery('(max-width: 895px)')
  const { scrollYProgress } = useScroll();
  // console.log(scrollYProgress)
  const y = useTransform(scrollYProgress, [start[+isMobile], fullScroll[+isMobile]], [0, initialY[+isMobile]]);
  return (
    <MotionBox
      style={{ y }}
    >
      {children}
    </MotionBox>
  );
};

const Explanation = () => {
  return (
    <Box bg="custom.bgGray" pt={{ base: '7.5em', lg: '6.125em' }} pb={{ base: '2em', lg: '8em' }} pos={'relative'} zIndex={10}>
      <Box pos="absolute" right={{ base: '50%', lg: '7.5em' }} transform={{ base: 'translateX(50%)', lg: 'none' }} top={{ base: '-5.5em', lg: '-8.5em' }} width={{ base: '11em', lg: '29.125em' }}>
        <Image src={explanation_circle} />
      </Box>
      <Box pos="absolute" left={0} top={'19.375em'} width={'74.6%'} display={{ base: 'none', md: 'block' }}>
        <AnimateMove fullScroll={[0.28, 0.29]} initialY={[-100, -100]}>
          <Image src={deco_img} />
        </AnimateMove>
      </Box>
      <Box pos="absolute" right={{ base: 0 }} top={{ base: '27.25em', md: '40.125em' }} width={{ base: '21.75em', md: '42.5%', lg: '49.3%' }}>
        <AnimateMove fullScroll={[0.30, 0.32]} initialY={[-150, -80]}>
          <Image src={right_deco} />
        </AnimateMove>
      </Box>
      <Container>
        <Text
          textAlign={{ base: 'center', md: 'left' }}
          fontSize={{ base: '1.5em', md: '1.625em', lg: '2.25em' }}
          fontWeight={'black'}
          color="custom.red"
        >
          為什麼政見追蹤這麼難？
        </Text>
        <Text
          mt={{ base: '1.375rem', md: '5.625rem' }}
          textAlign={{ base: 'center', md: 'left' }}
          fontSize={{ base: '1.125em', md: '1.5em', lg: '2.25em' }}
          fontWeight={'black'}
        >
          選後政見進度分歧，難窺全貌
        </Text>
        <AnimateMove fullScroll={[0.30, 0.32]} initialY={[-120, -60]}>
          <Text
            mt={{ base: '12rem', md: '17.5rem' }}
            pr={{ base: '0', lg: '7.5rem' }}
            textAlign={{ base: 'center', lg: 'right' }}
            fontSize={{ base: '1.125em', md: '1.5em', lg: '2.25em' }}
            fontWeight={'black'}
          >
            閱讀時間破碎，缺乏量化指標
          </Text>
        </AnimateMove>
        <AnimateMove fullScroll={[0.30, 0.32]} initialY={[-120, -85]}>
          <Text
            mt={{ base: '10rem', md: '6.375rem' }}
            textAlign={{ base: 'center', md: 'left' }}
            fontSize={{ base: '1.125em', md: '1.5em', lg: '2.25em' }}
            fontWeight={'black'}
          >
            繁雜的往來公文，民眾不易理解進度
          </Text>
        </AnimateMove>
      </Container>
      <Box pos="absolute" left={0} top={'13.75em'} width={'15.5em'} display={{ base: 'block', md: 'none' }}>
        <AnimateMove fullScroll={[0.30, 0.32]} initialY={[-100, -24]}>
          <Image src={deco_mobile} />
        </AnimateMove>
      </Box>
      <Box pos="absolute" left={{ base: 0 }} bottom={{ base: -50, lg: -100 }} width={{ base: '21.25em', lg: '53.4%' }} maxWidth={'60em'}>
        <AnimateMove start={[0.29, 0.29]} fullScroll={[0.35, 0.34]} initialY={[-70, -60]}>
          <Image src={bottom_deco} />
        </AnimateMove>
      </Box>
    </Box>
  )
}

export default Explanation
