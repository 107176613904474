import SVG from 'components/SVG';
import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { motion } from 'framer-motion';
import { shuffle } from 'lodash';

const transformReg =
  /translate\((-?\d+(?:\.\d+)?)\s(-?\d+(?:\.\d+)?)\)\srotate\(([^)]+)\)/;

const svgFormatToJSON = (svg) =>
  svg.split('\n').map((d) => {
    const json = d.replace(/=/g, '":').replace(/"\s/g, '","');
    return JSON.parse(`{"${json}}`);
  });

const src =
  svgFormatToJSON(`x="39.08" y="16.39" transform="translate(0 0) rotate(-64.11)" fill="#458461"
x="56.93" y="77.87" transform="translate(0 0) rotate(-16.51)" fill="#f2af0f"
x="217.49" y="8.06" transform="translate(0 0) rotate(-16.51)" fill="#99c8d7"
x="24.71" y="132.92" transform="translate(0 0) rotate(-70.98)" fill="#deb0ae"
x="70.08" y="160.84" transform="translate(0 0) rotate(-70.98)" fill="#99c8d7"
x="288.25" y="160.84" transform="translate(0 0) rotate(-70.98)" fill="#b28aba"
x="93.73" y="217.65" transform="translate(0 0) rotate(-21.83)" fill="#b28aba"
x="143.8" y="67.01" transform="translate(0 0) rotate(-55.81)" fill="#b28aba"
x="382.91" y="122.86" transform="translate(0 0) rotate(-74.73)" fill="#fff"
x="220.43" y="105.24" transform="translate(0 0) rotate(-64.71)" fill="#f2af0f"
x="332.13" y="16.23" transform="translate(0 0) rotate(-64.71)" fill="#f2af0f"
x="202.42" y="209.4" transform="translate(0 0) rotate(-59.81)" fill="#99c8d7"
x="168.73" y="160.01" transform="translate(0 0) rotate(-7.23)" fill="#fff"
x="333.78" y="209.86" transform="translate(0 0) rotate(-7.23)" fill="#458461"
x="305.85" y="78.96" transform="translate(0 0) rotate(-7.23)" fill="#deb0ae"`).map(
    (d) => {
      const [, translateX, translateY, rotate] = transformReg.exec(d.transform);
      return {
        style: {
          x: +d.x + +translateX,
          y: +d.y + +translateY,
          rotate: +rotate,
        },
        fill: d.fill,
      };
    }
  );

const animated =
  svgFormatToJSON(`x="180.11" y="321.28" transform="translate(18.72 391.56) rotate(-64.11)" fill="#458461"
x="153.93" y="314.84" transform="translate(1.19 67.52) rotate(-16.51)" fill="#f2af0f"
x="217.49" y="329.92" transform="translate(2.85 86.21) rotate(-16.51)" fill="#99c8d7"
x="138.76" y="314.84" transform="translate(-109.72 401.86) rotate(-70.98)" fill="#deb0ae"
x="143.8" y="314.84" transform="translate(-9.72 401.86) rotate(-70.98)" fill="#99c8d7"
x="288.97" y="344.68" transform="translate(-140.08 559.22) rotate(-70.98)" fill="#b28aba"
x="132.01" y="331.65" transform="translate(23.83 87.6) rotate(-21.83)" fill="#b28aba"
x="185.15" y="321.94" transform="translate(98.1 336.13) rotate(-55.81)" fill="#b28aba"
x="230.05" y="303.34" transform="translate(140.72 551.82) rotate(-74.73)" fill="#fff"
x="223.26" y="321.28" transform="translate(0 434.84) rotate(-64.71)" fill="#f2af0f"
x="303.64" y="321.94" transform="translate(28.16 507.88) rotate(-64.71)" fill="#f2af0f"
x="213.33" y="336.37" transform="translate(-196.87 396.76) rotate(-59.81)" fill="#99c8d7"
x="180.11" y="314.84" transform="translate(-42.13 29.63) rotate(-7.23)" fill="#fff"
x="288.25" y="331.65" transform="translate(-43.38 43.38) rotate(-7.23)" fill="#458461"
x="247.63" y="314.84" transform="translate(-41.59 38.13) rotate(-7.23)" fill="#deb0ae"`).map(
    (d, i) => {
      return {
        x: +d.x,
        y: +d.y,
      };
    }
  );

const delayIndex = shuffle([...Array(src.length).keys()]);

const TicketsAnimation = (props) => {
  const ref = useRef(null);
  const [isVisible, setVisible] = useState(false);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
  });

  useEffect(() => {
    if (intersection && intersection.isIntersecting) {
      setTimeout(() => {
        setVisible(true);
      }, 500);
    } else {
      setVisible(false);
    }
  }, [intersection]);

  return (
    <SVG viewBox="0 0 466 369" {...props} ref={ref}>
      {src.map(({ fill, style }, i) => (
        <motion.rect
          width="66.32"
          height="66.32"
          rx="3"
          fill={fill}
          variants={{
            init: {
              ...style,
              transition: {
                duration: 0.5,
              },
            },
            active: {
              ...animated[i],
              transition: {
                duration: 1.25,
                delay: delayIndex[i] * 0.02,
              },
            },
          }}
          initial="init"
          animate={isVisible ? 'active' : 'init'}
          key={i}
        />
      ))}
      <rect width="466" height="69" x="0" y="300" fill="#F2AF0F" />
    </SVG>
  );
};

export default TicketsAnimation;
