import React from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const RegisterModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      size="auto"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        width={{ base: '90%', md: '750px' }}
        px={{ base: '1.25em', md: '2.75em' }}
        pt={{ base: '3.125em', md: '6.25em' }}
        pb={{ base: '1.5em', md: '3em' }}
        height="80vh"
      >
        <ModalCloseButton _hover={{}} />
        <ModalBody p="0">
          <iframe
            title="subscription"
            width={'100%'}
            height="100%"
            src="https://lb.benchmarkemail.com/listbuilder/signupnew?IkfHTmyPVq%252FsVYeAi5hyEv5pwVnAjsSIEWbcBFHWNJXtO5iNRn8gSyp0RW5HI7%252FzNyIeD9VsXLU%253D"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RegisterModal;
