import React, { useEffect, useState } from 'react';

import Context from './context'

let dom

const Provider = ({ children }) => {
  const [hideHeader, setHideHeader] = useState()
  const [id, setId] = useState()
  useEffect(() => {
    if (document) {
      dom = document.getElementById(id)
      if (dom) {
        dom.scrollIntoView({ behavior: 'smooth' }, true)
        setTimeout(() => setId(), 1000)
      }
    }
  } ,[id])
  return (
    <Context.Provider value={{ hideHeader, setHideHeader, setId }}>
      {children}
    </Context.Provider>
  )
}

export default Provider;
