import React from 'react';
import { Box, Flex, Image, Container } from '@chakra-ui/react';
import Text from 'components/Text';

import right_top from './right_top.png';
import envelope from './envelope.png';
import tickets from './tickets.png';

import EnvelopeAnimation from './EnvelopeAnimation';
import TicketsAnimation from './TicketsAnimation';
import PingPong from '../PingPong';

const NumText = (props) => (
  <Text as="span" fontSize={{ base: '2.25em', md: '2.75rem' }} fontWeight={700} {...props} />
);

const data = [
  {
    img: envelope,
    imgOverlay: <EnvelopeAnimation />,
    text: '千萬選民的期待，\n有被好好重視嗎？',
    info: (
      <>
        <Text fontWeight={700}>
          超過 <NumText>60%</NumText> 投票率，
        </Text>
        <Text fontWeight={700}>
          約有 <NumText>1,100</NumText> 萬位選民參與投票
        </Text>
      </>
    ),
  },
  {
    img: tickets,
    imgOverlay: <TicketsAnimation />,
    text: '你知道，\n這些政見後來怎麼了嗎？',
    info: (
      <>
        <Text fontWeight={700}>
          全臺 22 位縣市首長，
          <Box as="br" />
          共提出 <NumText>
           6
          </NumText>{' '}
          大面向、
          <NumText>322</NumText>
          {' '}條政見，
        </Text>
        <Text mt={{ base:0, md: '0.375rem' }} fontWeight={700}>但你我卻難以一一了解是否如期兌現</Text>
      </>
    ),
  },
];

const Introduction = () => {
  return (
    <Box
      bg={'custom.darkGray'}
      pt={{ base: '4.375em', lg: '6.25em' }}
      pb={{ base: '9.625em', lg: '14.875em' }}
      pos="relative"
      id="intro"
    >
      <Box
        pos="absolute"
        right="0"
        top="0"
        width={{ base: '6.625em', md: '12.75em' }}
      >
        <Image src={right_top} />
        <PingPong />
      </Box>
      <Container pos="relative">
        <Flex flexDir="column" gap={{ base: '2em', lg: '5.25em' }}>
          {data.map(({ img, text, info, imgOverlay }, i) => (
            <Flex
              flex
              gap={{ base: '1.5em', lg: '8.875em' }}
              flexDir={{ base: 'column-reverse', lg: 'row' }}
              key={i}
            >
              <Box
                mx={{ base: 'auto' }}
                pos="relative"
                width={{ base: '100%', md: '50%', lg: '29.125em' }}
              >
                <Image src={img} />
                {imgOverlay && (
                  <Box
                    pos="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                  >
                    {imgOverlay}
                  </Box>
                )}
              </Box>
              <Box flex={1} color="white">
                <Text
                  fontWeight={{ base: i ? 'bold' : 'black', lg: 'black' }}
                  whiteSpace={'pre-wrap'}
                  fontSize={{ base: i ? '1.125em' : '1.5em', lg: '2.25em' }}
                >
                  {text}
                </Text>
                <Box
                  whiteSpace={'pre-wrap'}
                  mt={{ base: '0.875em', lg: '5.5rem' }}
                  fontSize={{ base: '1em', lg: '1.25em' }}
                >
                  {info}
                </Box>
              </Box>
            </Flex>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default Introduction;
