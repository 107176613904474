import React from 'react';
import {
  Box,
  Flex,
  Image,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { Element } from 'react-scroll';
import Text from 'components/Text';

// import cover from './subscription_cover.png'
import btn from './btn.png';
import RegisterModal from '../RegisterModal';
import video from './color-blocks.mp4';

const Subscription = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 895px)');
  return (
    <Box bg="custom.bgGray" pos="relative">
      {isMobile && (
        <Text.Title pt="1.25rem" mx="2em">
          訂閱追蹤政見進度，
          <br />
          延長選票價值
        </Text.Title>
      )}
      <Element id="subscription">
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gap={{ base: '2em', md: '1.5em', lg: '9em' }}
          py={{ base: '2.5em', md: 0 }}
        >
          <Box
            width={{ base: '100%', md: '58%' }}
            maxW={{ base: '100%', md: '51.875em' }}
            overflow="hidden"
            pointerEvents="none"
          >
            <Box
              as="video"
              loop
              autoPlay
              muted
              controls={false}
              playsInline
              src={video}
              width="100%"
              transform={{ md: 'scale(1.01)' }}
            />
          </Box>
          <Flex
            mx={{ base: '2em', md: 0 }}
            flex={1}
            justifyContent={'center'}
            pr={{ base: '0', md: '1em' }}
            flexDir={'column'}
          >
            {!isMobile && (
              <Text.Title>
                訂閱追蹤政見進度，
                <br />
                延長選票價值
              </Text.Title>
            )}
            <Text
              mt={{ base: '2em', md: '5.25em' }}
              fontSize={{ base: '0.875em', md: '1.25em' }}
            >
              勾選關注議題，並留下 Email，
              <br />
              當有相關報導、調查與執行進度時，
              <br />
              我們都會在第一時間通知你！
            </Text>
            <Box
              mt={{ base: '1.5em', md: '1.5em' }}
              width={{ base: '7em', md: '9.375em' }}
              cursor={'pointer'}
              onClick={onOpen}
            >
              <Image src={btn} />
            </Box>
          </Flex>
        </Flex>
      </Element>
      <RegisterModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Subscription;
