import React, { useRef, useState, useEffect } from 'react';
import { Box, Flex, Image, Container } from '@chakra-ui/react';
import Text from 'components/Text';

import bg from './bg.png';
import dim_circle from './dim_circle.png';
import Circle from './Circle';
import { useIntersection } from 'react-use';

const Track = () => {
  const blockRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  // const [isFirst, setFirst] = useState(true);
  const intersection = useIntersection(blockRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
  });
  useEffect(() => {
    if (intersection?.isIntersecting) {
      setTimeout(() => {
        setIsVisible(true);
      }, 500);
    } else {
      setIsVisible(false);
    }
  }, [intersection?.isIntersecting]);

  return (
    <Box bgColor="custom.bgGray" pos="relative" overflow={'hidden'}>
      <Box
        pos="absolute"
        top={0}
        left={0}
        // bg="custom.red"
      >
        <Image bgColor="custom.bgGray" src={bg} />
      </Box>
      <Container
        color="white"
        pt={{ base: '22vw', md: '18.5em' }}
        pb={{ base: '1em', md: '2.5em' }}
        px={{ base: 0, md: '2em' }}
        // bg="custom.red"
      >
        <Flex
          gap={{ base: '3em', md: '8em', lg: '10em' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Box flex="1" textAlign={'center'}>
            <Text
              fontWeight={'bold'}
              fontSize={{ base: '2.125em', lg: '3.125em' }}
              letterSpacing={{ base: '1.2px', lg: '1.5px' }}
            >
              TRACK POLITICS
            </Text>
            <Text
              fontWeight={'black'}
              fontSize={{ base: '1.5em', lg: '2.25em' }}
              letterSpacing="wider"
            >
              該如何長期、客觀的
              <Box as="br" display={{ base: 'inline', md: 'none' }} />
              追蹤政見？
            </Text>
            <Box
              mx="auto"
              pt={{ base: '1em', lg: '1.5em' }}
              width={{ base: '10em', lg: '20em' }}
            >
              <Image src={dim_circle} />
            </Box>
          </Box>
          <Box flex="1" ref={blockRef} px={{ base: '2em' }}>
            <Circle isVisible={isVisible} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Track;
