import React, {
  useState,
  useMemo,
  createElement,
  useRef,
  useEffect,
} from 'react';
import {
  Box,
  Flex,
  IconButton,
  Image,
  Input,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import range from 'lodash/range';
import intersection from 'lodash/intersection';
import compact from 'lodash/compact';
import Button from 'components/Button';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import styled from '@emotion/styled';
import { UAParser } from 'ua-parser-js';
import { useIntersection } from 'react-use';

import Card from './Card';
import Filter from './Filter';
import CityFilter from './CityFilter';

import arrow from './arrow.png';
import culture from './culture.png';
import economy from './economy.png';
import medicine from './medicine.png';
import security from './security.png';
import technique from './technique.png';
import environment from './environment.png';

const StyledBox = styled(Box)`
  overflow: hidden;
  display: inline-flex;
  justify-content: flex-end;
  font-size: 1em;
  line-height: 1.2em;
  width: 2.4em;
  @media screen and (max-width: 895px) {
    font-size: 0.875em;
  }
`;

const StyledText = styled(Text)`
  display: -webkit-box;
  writing-mode: vertical-rl;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
`;

const StyledSafariText = styled(Text)`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin: 0;
`;

const cities = [
  '基隆市',
  '臺北市',
  '新北市',
  '桃園市',
  '新竹市',
  '新竹縣',
  '苗栗縣',
  '臺中市',
  '南投縣',
  '彰化縣',
  '雲林縣',
  '嘉義市',
  '嘉義縣',
  '臺南市',
  '高雄市',
  '屏東縣',
  '宜蘭縣',
  '花蓮縣',
  '臺東縣',
  '澎湖縣',
  '金門縣',
  '連江縣',
];

const filters = [
  {
    name: '政見類別',
    options: [
      '經濟就業',
      '社福醫療',
      '治安交通',
      '環境永續',
      '文化教育',
      '數位科技與其他',
    ],
  },
  {
    name: '年齡',
    options: ['幼兒', '兒童', '青年', '銀髮族'],
  },
  {
    name: '族群',
    options: ['客家', '原民', '新住民'],
  },
];

const selector = ['篩選城市', '篩選標籤'];

const pics = {
  經濟就業: economy,
  社福醫療: medicine,
  治安交通: security,
  環境永續: environment,
  文化教育: culture,
  數位科技與其他: technique,
};

const SearchInput = ({ setSearch }) => (
  <Box>
    <Input
      bg="white"
      borderRadius={'full'}
      placeholder="請輸入關鍵字搜尋"
      _focus={{}}
      _placeholder={{ textAlign: 'center' }}
      fontSize={{ base: '0.875em', md: '1em' }}
      onChange={(e) => setSearch(e.target.value)}
      h={{ base: '1.75rem', md: '2.25rem' }}
    />
  </Box>
);

// () => title.replace(/\s/g, '\n').split('\n').filter(Boolean).join('\n'),

const VerticalOverflowTitle = ({ title = '', mainRef }) => {
  const wrapperRef = useRef();
  const ref = useRef();
  const intersection = useIntersection(wrapperRef, {
    root: mainRef.current,
    rootMargin: '0px',
    threshold: 0,
  });
  const [hasOverflow, setHasOverflow] = useState(true);
  const textWithLines = useMemo(
    () => title,
    [title]
  );
  // const [firstLineIndex, secondLineIndex] = useMemo(() => {
  //   let foundFirstLine;
  //   const foundSecondIndex = Array.from(textWithLines).findIndex((d, i) => {
  //     if (d === '\n') {
  //       if (foundFirstLine) {
  //         return true;
  //       }
  //       foundFirstLine = i;
  //     }
  //     return false;
  //   });
  //   return [foundFirstLine, foundSecondIndex];
  // }, [textWithLines]);

  useEffect(() => {
    if (intersection?.isIntersecting) {
      const { width } = ref.current.getBoundingClientRect();
      const { width: wrapperWidth } =
        wrapperRef.current.getBoundingClientRect();
      setHasOverflow(width > wrapperWidth);
    }
  }, [intersection?.isIntersecting]);
  // if (intersection?.isIntersecting && hasOverflow) {
  //   console.log(title, firstLineIndex, secondLineIndex);
  // }

  return (
    <Box
      // fontSize={{ base: '0.875em', md: '1em' }}
      pos="relative"
      // _after={
      //   hasOverflow && {
      //     content: '"…"',
      //     pos: 'absolute',
      //     top: '100%',
      //     left: '0.5em',
      //     display: 'inline-block',
      //     transform: 'rotate(90deg)',
      //   }
      // }
    >
      <StyledBox fontWeight={'bold'} height="6em" ref={wrapperRef}>
        <StyledSafariText whiteSpace={'pre-wrap'} ref={ref}>
          {textWithLines.trim()}
        </StyledSafariText>
      </StyledBox>
    </Box>
  );
};

const Detail = ({ index, open, data, ...props }) => {
  const mainRef = useRef();
  const { browser } = UAParser();
  // console.log(browser)
  const [isMobile] = useMediaQuery('(max-width: 895px)');
  const [openCard, setOpenCard] = useState();
  const [isExpand, setExpand] = useState();
  const [allowScroll, setScroll] = useState();
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState();
  const [city, setCity] = useState(range(cities.length).fill(false));
  const [checkedItems, setCheckedItems] = useState(
    filters.map((d) => range(d.options.length).fill(false))
  );
  const isSafari = useMemo(() => browser?.name.includes('Safari'), [browser]);
  const isOpen = useMemo(() => open.includes(index), [open]);
  const choices = useMemo(
    () =>
      filters.map((d, i) => d.options.filter((opt, k) => checkedItems[i][k])),
    [checkedItems]
  );
  const filterData = useMemo(
    () =>
      !data.loading
        ? data?.value?.data
            ?.filter((d) => {
              if (search) {
                return d.title.includes(search) || d.city.includes(search);
              } else {
                return d;
              }
            })
            .filter((d) => {
              if (compact(city).length) {
                return city.includes(d.city);
              } else {
                return d;
              }
            })
            .filter((d) => {
              if (choices[0].length) {
                return choices[0].includes(d.category);
              } else {
                return d;
              }
            })
            .filter((d) => {
              if (choices[1].length) {
                return intersection(choices[1], d.tags).length;
              } else {
                return d;
              }
            })
            .filter((d) => {
              if (choices[2].length) {
                return intersection(choices[2], d.tags).length;
              } else {
                return d;
              }
            })
        : [],
    [city, choices, data, search]
  );
  // console.log(browser)
  return (
    <Box
      transition={'all 0.5s'}
      height={isOpen ? 'auto' : 0}
      opacity={isOpen ? 1 : 0}
      pt={isOpen && { base: '2.25em', lg: '1.5em' }}
      {...props}
    >
      {isMobile && <SearchInput setSearch={setSearch} />}
      <Flex
        pos={'relative'}
        pt={{ base: '0.625em', lg: 0 }}
        zIndex={10}
        gap={4}
        display={{ base: 'flex', md: 'inline-flex' }}
      >
        {selector.map((name, key) => (
          <Box w={{ base: '50%', md: 'auto' }} pos="relative" key={key}>
            <Button
              width={{ base: '100%', md: 'auto' }}
              bg={'custom.btnBg'}
              px={{ base: '1em', lg: '2.75em' }}
              color="white"
              borderRadius="full"
              h={{ base: '1.75rem', md: '2.25rem' }}
              fontSize={{ base: '0.875em', md: '1em' }}
              onClick={() => {
                // e.stopPropagation();
                if (filter === key) {
                  setFilter();
                } else {
                  setFilter(key);
                }
              }}
            >
              {name}
              <Box
                transformOrigin={'center'}
                transform={filter === key ? 'rotate(-90deg)' : 'rotate(180deg)'}
                width={{ base: '0.75em', md: '0.875rem' }}
                ml="0.625em"
                display={'inline-block'}
              >
                <Image src={arrow} />
              </Box>
            </Button>
            {!isMobile &&
              filter === key &&
              createElement(filter ? Filter : CityFilter, {
                setCheckedItems,
                checkedItems,
                setCity,
                city,
                filters: filter ? filters : cities,
              })}
          </Box>
        ))}
        {isMobile &&
          typeof filter === 'number' &&
          createElement(filter ? Filter : CityFilter, {
            setCheckedItems,
            checkedItems,
            setCity,
            city,
            filters: filter ? filters : cities,
          })}
        {!isMobile && <SearchInput setSearch={setSearch} />}
      </Flex>
      <Box
        opacity={openCard ? 0 : 1}
        transition={'all 0.5s'}
        pos="relative"
        borderRadius={'0.5em'}
        overflow={'hidden'}
        mt={{ base: '1em', md: '2.875em' }}
      >
        <Box
          px={{ base: '1.125em', md: '1.625em' }}
          pt={{ base: '1.25em', md: '1.875em' }}
          pb={{ base: 8, md: '6em' }}
          height={{
            base: isExpand ? '520px' : '397px',
            lg: isExpand ? '630px' : '330px',
          }}
          overflow={allowScroll ? 'auto' : 'hidden'}
          transition={'all 0.25s'}
          bg="custom.bgGray"
          ref={mainRef}
        >
          <Flex mx={{ base: '-0.25em', md: '-0.375em' }} flexWrap={'wrap'}>
            {filterData.length ? (
              filterData.map((d, i) => (
                <Box
                  px={{ base: '0.25em', md: '0.375em' }}
                  cursor={'pointer'}
                  onClick={() => setOpenCard(d)}
                  mt={{ base: i > 1 ? '0.625em' : 0, lg: i > 5 ? '12px' : 0 }}
                  width={{ base: '50%', lg: `${100 / 6}%` }}
                  key={i}
                >
                  <Box bg="white" pos="relative" borderRadius={'0.75em'}>
                    <Image src={pics[d.category]} />
                    <Box
                      pos="absolute"
                      top="10px"
                      left="6px"
                      fontWeight={'bold'}
                      style={{
                        writingMode: 'vertical-lr',
                      }}
                      fontSize={{ base: '0.875em', md: '1em' }}
                      color="white"
                      // pointerEvents={'none'}
                    >
                      {d.city}
                    </Box>
                    <Box
                      display={'inline-block'}
                      pos="absolute"
                      top="10px"
                      right="0.5rem"
                    >
                      {(isMobile || isSafari) ? (
                        <VerticalOverflowTitle
                          title={d.title}
                          mainRef={mainRef}
                        />
                      ) : (
                        <Box
                          fontSize={{ base: '0.875em', md: '1em' }}
                          fontWeight={'bold'}
                          height="6em"
                        >
                          <StyledText whiteSpace={'pre-wrap'}>
                            {d.title.trim()}
                          </StyledText>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Text>無此篩選資料</Text>
            )}
          </Flex>
        </Box>
        <Box
          pos="absolute"
          bg="custom.bgGray"
          py={{ base: '0.75em', lg: '1.125em' }}
          left="0"
          right="1em"
          bottom="0"
          textAlign={'center'}
        >
          <Box
            display={'inline-block'}
            onClick={() => {
              setScroll(true);
              setExpand(!isExpand);
            }}
            cursor={'pointer'}
          >
            <Text fontSize={{ base: '0.875em', lg: '1em' }} fontWeight={'bold'}>
              {isExpand ? '收合政見' : '展開更多政見'}
            </Text>
            <Box
              lineHeight={'1'}
              transform={isExpand ? 'rotate(180deg)' : 'none'}
            >
              <IconButton
                h="auto"
                bg="transparent"
                minH="auto"
                icon={
                  <MdOutlineKeyboardArrowDown size={isMobile ? '1em' : '2em'} />
                }
                _active={{}}
                _hover={{}}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {openCard && <Card data={openCard} onClose={() => setOpenCard(false)} />}
      {typeof filter === 'number' && (
        <Box
          pos="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          onClick={() => setFilter(false)}
        />
      )}
    </Box>
  );
};

export default Detail;
