import React, { useEffect, useMemo, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Image,
  Stack,
  Circle,
  Center,
  useMediaQuery,
  Spinner
} from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'

import Text from 'components/Text'
import Button from 'components/Button'
import BackgroundImage from 'components/BackgroundImage'

import arrow from './arrow_black.png'
import culture from './culture_card.png'
import economy from './economy_card.png'
import medicine from './medicine_card.png'
import security from './security_card.png'
import technique from './technique_card.png'
import environment from './environment_card.png'
import triangle from './triangle_black.png'

const category = {
  '經濟就業': { bgImg: economy, budgetBg: '#DFADE7', scheduleBg: '#A36AA9' },
  '社福醫療': { bgImg: medicine, budgetBg: '#F096AB', scheduleBg: '#F26178' },
  '治安交通': { bgImg: security, budgetBg: '#C2C6C9', scheduleBg: '#A0A0A0' },
  '環境永續': { bgImg: environment, budgetBg: '#CFEE8E', scheduleBg: '#426D61' },
  '文化教育': { bgImg: culture, budgetBg: '#FFDA7E', scheduleBg: '#F98A3C' },
  '數位科技與其他': { bgImg: technique, budgetBg: '#A9E1EF', scheduleBg: '#0075BF' },
}

const Block = ({ label, children }) => (
  <Box
    pt={{ base: '1.25rem', md: '1.5rem' }}
    pb={{ base: '0.375rem', md: '0.75rem' }}
    px="0.75rem"
    fontSize={{ base: '0.875em', md: '0.875em' }}
    bg="white"
    pos={'relative'}
    borderRadius={'4px'}
    whiteSpace="pre-wrap"
  >
    <Box
      px="1em"
      py="0.375em"
      pos="absolute"
      left="0"
      top="0"
      transform={'translateY(-50%)'}
      bg="custom.blockBgGray"
      color={'white'}
      borderRadius={'full'}
      fontSize={{base: '0.75rem', md: '1rem'}}
      fontWeight={'bold'}
    >{label}</Box>
    <Text>{children}</Text>
  </Box>
)

const Politics = ({ data }) => (
  <Accordion pt={{ base: '1.25em', md: '1em' }} pl={{base: '1.25em', md: '0'}} pr={{base: '1.25em', md: '0'}} pb="1.5em" allowToggle>
    {data?.children?.map((d, i) => (
      <AccordionItem pt={i && '0.625em'} border={'none'} key={i}>
        {({ isExpanded }) => (
          <>
            <Box bg="custom.bgGray" borderRadius={isExpanded ? '0.75em 0.75em 0 0' : 'full'} overflow={'hidden'}>
              <AccordionButton bg={category[data.category].scheduleBg} border="none" borderRadius={'full'}  p="0" _hover={{}}>
                <Circle fontWeight={'bold'} fontSize={{base: '1em', md: '1.25em'}} width="1.75rem" h="1.75rem" color="white" bg="custom.darkGray" lineHeight={1}>{isExpanded ? '—' : '＋'}</Circle>
                <Text
                  textAlign="left"
                  fontSize={{base: '0.875em', md: '1em'}}
                  whiteSpace="pre-wrap"
                  color="white"
                  ml="0.25rem"
                  mr="1rem"
                  fontWeight="bold"
                >
                  {d.title.trim()}
                </Text>
              </AccordionButton>
            </Box>
            <AccordionPanel
              transform={'translateY(-0.875em)'}
              bg="custom.bgGray"
              borderRadius={'10px'}
              px={{base: '1em', md: '1.875em'}}
              pt={{base: '3.125em', md: '2.875em'}}
              pb={{base: '1em', md: '1.25em'}}
            >
              <Stack spacing={'1.875em'}>
                <Block label="子政見負責局處">
                  {d.department}
                </Block>
                <Block label="子政見簡介">
                  {d.description}
                </Block>
                <Block label="子政見進度">
                  {d.progress}
                </Block>
              </Stack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    ))}
  </Accordion>
)

const extractNumbersFromText = (text) => {
  const regex = /\d+/g;
  const numbers = text.match(regex);
  return numbers;
}

const getDate = (year, month) => {
  const lastDayOfMonth = new Date(year, month, 0).getDate();
  const date = new Date(`${year}/${month}/${lastDayOfMonth}`)
  return date.getTime();
}

const Card = ({ onClose, data }) => {
  const [open, setOpen] = useState()
  const [isMobile] = useMediaQuery('(max-width: 895px)')
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  } ,[])
  const schedule = useMemo(() => {
    let newData
    if (!data.loading && data.schedule) {
      newData = sortBy(data.schedule.map((d) => {
        const [year, month] = extractNumbersFromText(d.date)
        return ({
          ...d,
          day: getDate(+year + 1911, +month)
        })
      }), o => o.day).reduce((res, d) => {
        if (d.day < new Date()) {
          res[0].push(d)
        } else {
          res[1].push(d)
        }
        return res
      }, [[], []])
    } else {
      newData = [[], [{ date: '時程規劃中', info: '進度規劃中' }]]
    }
    if (newData.every(d => !d.length)) {
      newData = [[], [{ date: '時程規劃中', info: '進度規劃中' }]]
    }
    return newData
  }, [data])
  return (
    <Modal size="auto" isCentered scrollBehavior="inside" isOpen={data} onClose={onClose}>
      <ModalContent borderRadius={'1.125em'} overflow={'hidden'} p="0" width={{ base: '90%', md: '710px' }}>
        <ModalBody opacity={+!isLoading} transition={'opacity 0.5s'} p="0">
          <Flex pos="relative" pl='0' pr={{base: '1.5em', md: '7.625em'}} pt={0}>
            <Box width={{base: '4.125em', lg: '9.75em'}}>
              <BackgroundImage src={category[data.category].bgImg} ratio={400 / 1417} />
            </Box>
            <Flex flexDir={'column'} justifyContent={{ base: 'space-between', md: 'flex-start' }} pl={{base: '0.75em', md: '4.25em'}} pt={{base: '2.25em', md: '5.625em'}} flex={1}>
              <Stack spacing={'0.75em'} >
                <Text color="custom.textGray" fontWeight={'black'} fontSize={{base: '1.5em', md: '2.25em'}}>{data.city}</Text>
                <Text fontWeight={'black'} fontSize={{base: '1.125em', md: '1.25em'}}>{data.title}</Text>
                <Text fontSize={{base: '0.875em', md: '1em'}}>{data.description}</Text>
              </Stack>
              <Flex flexWrap={'wrap'} mx={{base: '-0.125em', md: '-0.5rem'}} pt={{base: '1em', md: '1.75em'}}>
                {data?.tags.map((tag, i) => tag && (
                  <Box width={{base: `${100 / 3}%`}} px={{base: '0.125em', md: '0.5rem'}} key={i} mt={i > 2 && {base: '0.5em', md: '1em'}}>
                    <Box
                      border={'1px solid'}
                      borderRadius={'full'}
                      borderColor="custom.borderPink"
                      color="custom.pink"
                      textAlign={'center'}
                      fontSize={{base: '0.85em', md: '1em'}}
                      px={{base: '0.75em', md: '1.125em'}}
                      py={{base: '0.25em', md: '0.125em'}}
                      fontWeight={'bold'}
                    >#{tag}</Box>
                  </Box>
                ))}
              </Flex>
              {!isMobile && <Politics data={data} />}
            </Flex>
          </Flex>
          {isMobile && <Politics data={data} />}
          <Button
            width="100%"
            justifyContent="flex-start"
            color="custom.darkGray"
            bg="custom.lightGray"
            px={{base: '2em', md: '6.375rem'}}
            py={{base: '0.625em', md: '0.875rem'}}
            h="auto"
            onClick={() => setOpen(!open)}
            borderRadius="0"
            fontSize={{base: '1em', md: '1.25em' }}
          >
            規劃時程&政策預算
            <Box transformOrigin={'center'} transform={open && 'rotate(-90deg)'} width="0.5rem" ml="0.625em" display={'inline-block'}><Image src={arrow} /></Box>
          </Button>
          {open && (
            <Box>
              <Box px={{base: '2rem', md: '6.375rem'}} fontSize={{base: '0.875em', md: '1em'}} fontWeight={500} bg="custom.darkGray" py="0.625rem" color="white" pos="relative">
                審定預算
                <Box width={'5.625em'} pos="absolute" right="0" top="98%">
                  <Image src={triangle} />
                </Box>
              </Box>
              <Box fontWeight={'bold'} fontSize={'1.25rem'} px={{base: '2rem', md: '6.375rem'}} bg={category[data.category].budgetBg} py="0.625rem" color="custom.darkGray" pos="relative">
                {data.budget || '待審定'}
              </Box>
              <Box px={{base: '2em', md: '6.375em'}} pt={{base: '1em', md: '1.75em'}} pb={{base: '3.25em', md: '7em'}}>
                {schedule.map((d, i, { length }) => d.map((detail, k) => (
                  <Flex align={'center'} pos="relative" zIndex={length - i} mt={schedule[0].length && i && !k && { base: '1.5em', md:'3rem' }} key={k} >
                    <Center
                      pos="relative"
                      width={{base: '0.875em', md: '1.25em'}}
                      h={{base: '2em', md: '3.5em'}}
                      bg={i ? 'custom.darkGray' : category[data.category].scheduleBg}
                    >
                      <Circle w="6px" h="6px" bg="white" />
                      {!!i && !k && !!schedule[0].length && (
                        <Box
                          pos="absolute"
                          left="0"
                          bottom="100%"
                          width={'100%'}
                          bg="custom.darkGray"
                          h={{base: '1em', md: '2.5em'}}
                        >
                          <Box
                            top="0"
                            left="0"
                            pos="absolute"
                            borderStyle={'solid'}
                            borderColor={`white transparent transparent transparent`}
                            borderWidth={{base: '0.5em 0.4375em 0 0.4375em', md: '1em 0.625em 0 0.625em'}}
                          />
                        </Box>
                      )}
                      {k === d.length - 1 && ( i ? (
                        <Box
                          pos="absolute"
                          left="0"
                          top="100%"
                          width={'100%'}
                          bg="custom.darkGray"
                          h={{base: '1.75em', md: '4.5em'}}
                        >
                          <Box
                            top="100%"
                            left="0"
                            pos="absolute"
                            borderStyle={'solid'}
                            borderColor={`#323333 transparent transparent transparent`}
                            borderWidth={{base: '0.5em 0.4375em 0 0.4375em', md: '1em 0.625em 0 0.625em'}}
                          />
                        </Box>
                      ) : (
                        <Box
                          top="100%"
                          left="0"
                          pos="absolute"
                          borderStyle={'solid'}
                          borderColor={`${category[data.category].scheduleBg} transparent transparent transparent`}
                          borderWidth={{base: '0.5em 0.4375em 0 0.4375em', md: '1em 0.625em 0 0.625em'}}
                        />
                      ))}
                    </Center>
                    <Box height="1px" width={{base: '20px', md: '120px'}} bg={i ? 'custom.darkGray' : category[data.category].scheduleBg} />
                    <Box
                      borderRadius={'full'}
                      fontSize={{base: '0.875em', md: '1em' }}
                      px={{ base: '0.625em', md: '0.75em'}}
                      py={{ base: '0.25em', md: '0.5em'}}
                      bg={i ? 'custom.darkGray' : category[data.category].scheduleBg}
                      fontWeight={500}
                      color="white"
                    >
                      {detail.date}
                    </Box>
                    <Text flex={1} fontSize={{base: '0.875em', md: '1em' }} ml={{base: '1.25em'}}>{detail.info}</Text>
                  </Flex>
                )))}
              </Box>
            </Box>
          )}
        </ModalBody>
        <Center pos="absolute" left="50%" top="50%" height="100%" transform={'translate(-50%, -50%)'} opacity={+isLoading} ><Spinner /></Center>
        <ModalCloseButton _hover={{}} _active={{}} />
      </ModalContent>
    </Modal>
  )
}

export default Card
