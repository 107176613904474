import React, { useMemo, useState } from 'react'
import {
  Box,
  Center,
  Circle,
  Flex,
  Image,
  SimpleGrid,
} from '@chakra-ui/react'
import chunk from 'lodash/chunk'
import range from 'lodash/range';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import Text from 'components/Text'
import Link from 'components/Link'
import Button from 'components/Button'
import BackgroundImage from 'components/BackgroundImage';

const News = ({ data, isOpen }) => {
  // const [isMobile] = useMediaQuery('(max-width: 896px)')
  const [page, setPage] = useState(0)
  const news = useMemo(() => data.news.length ? chunk(data?.news, 4) : [[]] ,[data])
  // console.log(data)
  return (
    <Box
      pointerEvents={!isOpen && 'none'}
      opacity={isOpen ? 1 : 0}
      h={isOpen ? 'auto' : 0}
      transition={'all 0.5s'}
      mt={isOpen && { base: '2m', md: '2.635em'}}
      pb={{ base: '0.375em' }}
    >
      <SimpleGrid columns={2} spacing={{ base: '0.75em', md: '1.75em'}}>
        {news[page].map((d, i) => (
          <Box as={Link} href={`https://rwnews.tw/article.php?news=${d?.a0}`} target="_blank" key={i}>
            <Flex flexDir={'column'} h="100%">
              <Box><BackgroundImage src={d.a3} ratio={1401 / 788} /></Box>
              <Text
                mt="1em"
                color="custom.darkGray"
                fontSize={{ base: '1em', md: '1.25em' }}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >{d.a1}</Text>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
      {news.length > 1 && (
        <Flex align={'center'} gap={{ base: 2, md: 4 }} justifyContent={'center'} mt={{ base: '1em', md: '2.75em' }}>
          <Button
            bg="custom.darkGray"
            w={{ base: '1.25rem', md: '2.5rem' }}
            h={{ base: '1.25rem', md: '2.5rem' }}
            p="0"
            color="white"
            borderRadius="full"
            // isDisabled={!page}
            _hover={{}}
            onClick={() => {
              if (!page) {
                setPage(news.length - 1)
              } else {
                setPage(prev => prev - 1)
              }
            }}
            fontSize={{ base: '1em', md: '1.5em' }}
          >
            <IoIosArrowBack />
          </Button>
          <Box h="1px" width={{ base: '2em', md: '8.25em' }} bg="custom.darkGray" />
          {range(news.length).map((num) => (
            <Circle
              cursor={'pointer'}
              onClick={() => setPage(num)}
              bg={num === page ? 'custom.darkGray' : 'custom.circleBgGray'}
              w={{ base: '0.5em', md: '1em' }}
              h={{ base: '0.5em', md: '1em' }}
              key={num}
            />
          ))}
          <Box h="1px" width={{ base: '2em', md: '8.25em' }} bg="custom.darkGray" />
          <Button
            bg="custom.darkGray"
            w={{ base: '1.25rem', md: '2.5rem' }}
            h={{ base: '1.25rem', md: '2.5rem' }}
            p="0"
            color="white"
            borderRadius="full"
            // isDisabled={page === (news.length - 1)}
            _hover={{}}
            onClick={() => {
              if (page === (news.length - 1)) {
                setPage(0)
              } else {
                setPage(prev => prev + 1)
              }
            }}
            fontSize={{ base: '1em', md: '1.5em' }}
          >
            <IoIosArrowForward />
          </Button>
        </Flex>
      )}
      <Box mx="auto" w={{ base: '9.375em', md: '14.375em'}} mt={{ base: '1.125em', md: '1.25em'}}>
        <Link as={Link} href={data.link} target="_blank">
          <Image src={data.img} />
        </Link>
      </Box>
    </Box>
  )
}

export default News
