import React, { useState, createElement } from 'react'
import { Box, Flex, Image, Container } from '@chakra-ui/react'
import Text from 'components/Text'
import Button from 'components/Button'
import useSheetData from 'hooks/useSheetData';

import Analytics from './Analytics';
import Detail from './Detail';

import arrow from './arrow.png'
import blue_document from './blue_document.png'
import orange_document from './orange_document.png'

const info = [
  {
    title: '全臺縣市首長政見分析',
    excerpt: '選擇你想關注的城市，透過雷達圖觀察縣市長提出的政見與面向。',
    bg: 'custom.bgOrange',
    bgImg: orange_document,
    children: Analytics
  },
  {
    title: '全臺縣市首長政見總覽',
    excerpt: '選擇城市、族群等標籤，或透過關鍵字搜尋，追蹤政見進度。',
    bg: 'custom.bgBlue',
    bgImg: blue_document,
    children: Detail
  },
]

const Overview = () => {
  const [open, setOpen] = useState([])
  const data = useSheetData();
  // console.log(data)
  return (
    <Box bg="bgGray" id="track" pos="relative">
      <Container pt={{ base: '2em', md: '4.25em' }} pb={{ base: '3.5em', md: '2.75em' }}>
        <Text fontWeight={'black'} fontSize={{ base: '1.25em', lg: '2.25em'}}>讓我們一同見證・政見</Text>
        <Text fontWeight={'bold'} fontSize={{ base: '0.875em', lg: '1.25em'}} pr={{ base: 0, md: '40%' }} pt={{ base: '1rem' }}>
          你可以從自己關注的城市出發，觀察政見面向，也能透過城市、年齡或關鍵字，追蹤各項政見施行進度。
        </Text>
      </Container>
      {info.map((d, i) => (
        <Box
          pt={{ base: '1.5em', lg:'3em' }}
          pb={{ base: i ? '1.75em' : '3.75em', lg:'4.25em'}}
          pos="relative"
          overflow={'visible'}
          bg={d.bg}
          key={i}
        >
          <Box pos="absolute" left="0" top="-1.5em"><Image src={d.bgImg} /></Box>
          <Container>
            <Flex align="center" gap={{ base: '1em', lg: '7.5em' }}>
              <Text color="custom.darkGray" fontWeight={'black'} fontSize={{base: '1.25em', lg: '2.25em'}}>{d.title}</Text>
              <Button
                display={{ base:'none', md: 'inline-flex' }}
                color="white"
                bg="black"
                pl="1.5rem"
                pr="1.25rem"
                borderRadius="full"
                onClick={() => {
                  if (open.includes(i)) {
                    const index = open.findIndex(d => d === i)
                    open.splice(index, 1)
                    setOpen([...open])
                  } else {
                    open.push(i)
                    setOpen([...open])
                  }
                }}
                lineHeight={{ base: 1, md: 1.5 }}
              >
                {open.includes(i) ? '收合' : '展開'}
                <Box
                  mb={{ base: '0.125em', md: 0 }}
                  transformOrigin={'center'}
                  transform={open === i && 'rotate(90deg)'}
                  width="0.5rem"
                  ml="0.625em"
                  display={'inline-block'}
                >
                  <Image src={arrow} />
                </Box>
              </Button>
            </Flex>
            <Text fontWeight={'bold'} color="custom.darkGray" pt={{ base: '0.5rem', md: '1rem' }} fontSize={{ base: '0.875em', lg: '1.25em' }}>{d.excerpt}</Text>
            <Box textAlign={'right'} mt="0.625em">
              <Button
                display={{ base:'inline-flex', md: 'none' }}
                color="white"
                bg="black"
                px="0.75rem"
                borderRadius="full"
                fontSize={{ base: '0.875em', md: '0.875em' }}
                py={'0.375rem'}
                h="auto"
                alignItems="flex-start"
                lineHeight={1}
                onClick={() => {
                  if (open.includes(i)) {
                    const index = open.findIndex(d => d === i)
                    open.splice(index, 1)
                    setOpen([...open])
                  } else {
                    open.push(i)
                    setOpen([...open])
                  }
                }}
                _hover={{}}
                _active={{}}
              >
                {open.includes(i) ? '收合' : '展開'}
                <Box transformOrigin={'center'} transform={open.includes(i) && 'rotate(90deg)'} width="0.5rem" ml="0.625em" display={'inline-block'}><Image src={arrow} /></Box>
              </Button>
            </Box>
            {createElement(d.children, { index: i, open, data, pointerEvents: !open.includes(i) && 'none' })}
          </Container>
        </Box>
      ))}
    </Box>
  )
}

export default Overview
