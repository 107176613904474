import React, { useRef } from 'react';
import { Box, Flex, Image, Container } from '@chakra-ui/react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Link } from 'react-scroll';

import Text from 'components/Text';

import { btns } from 'containers/Header';

import triangle from './triangle.png';
import circle from './circle.png';
import half_circle from './half_circle.png';

import title from '../../header_title.png';

const MotionBox = motion(Box);

const AnimateCircle = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0, 0', '0.07, 0.07'],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 90]);
  return (
    <MotionBox
      pos="absolute"
      right={{ base: '-6px', md: '7.75em' }}
      bottom={{ base: '-1.375em', md: '-3.25em' }}
      width={{ base: '9.5em', md: '21.5em' }}
      zIndex={5}
      style={{ rotate }}
    >
      <Image src={circle} />
    </MotionBox>
  );
};

const Cover = () => {
  return (
    <Box
      bg="custom.bgGray"
      pos="relative"
      pt={{ base: '4.5em', md: '7.75em' }}
      pb={{ base: 8, md: '1.5em' }}
      overflow="visible"
    >
      <Box
        pos="absolute"
        right="0"
        bottom="0"
        width={{ base: '100%', md: '78.75%' }}
      >
        <Image src={triangle} />
      </Box>
      <Box
        pos="absolute"
        left="0"
        top={{ base: '6.25em', md: '3.125em' }}
        width={{ base: '4.625em', md: '5.875em' }}
      >
        <Image src={half_circle} />
      </Box>
      <AnimateCircle />
      <Container pos="relative" zIndex={55}>
        <Flex
          justifyContent={{ base: 'space-between', md: 'flex-start' }}
          fontSize={{ base: '1em', md: '1.25em' }}
        >
          <Text fontWeight={'bold'}>RIGHT・WRONG</Text>
          <Text fontWeight={'bold'} ml={{ base: '1em', md: '21.875rem' }}>
            RW NEWS
          </Text>
        </Flex>
        <Box width={{ base: '18.5em', md: '28.5em' }} pt={{ base: 2, md: 4 }}>
          <Image src={title} />
        </Box>
        <Box
          maxW="22.875rem"
          fontWeight={'black'}
          fontSize={{ base: '1.5em', md: '2.25em' }}
          pt={{ base: '1.375rem', md: 4 }}
          letterSpacing={1.1}
        >
          持續追蹤政見，
          <Box as="br" display={{ base: 'block', md: 'none' }} />
          延續每一張選票的價值。
        </Box>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          pt={{ base: '1.375em', md: 6 }}
          gap={{ base: '0.5rem', md: '1.625em' }}
        >
          {btns.map(({ icon, key, label }, i) => (
            <Box cursor={'pointer'} key={i}>
              <Flex
                as={Link}
                to={key}
                smooth
                offset={-50}
                border="1px solid black"
                borderRadius="full"
                bg="rgba(255, 255, 255, 0.65)"
                p={{ base: '0.5rem 1rem', md: '0.625rem 1.875rem' }}
                lineHeight={1}
                alignItems={'center'}
                display={'inline-flex'}
              >
                <Box width={'1.25em'}>
                  <Image src={icon} ratio={1} />
                </Box>
                <Text fontWeight={'bold'} ml="0.5rem" color="custom.darkGray">
                  {label}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default Cover;
