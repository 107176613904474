import React from 'react'
import { Box, Container, Flex, Image } from '@chakra-ui/react'
import logo from './logo.png'
import Text from 'components/Text'

const reasons = [
  { title: '擅於客觀、即時與精準的資訊分析', text: '我們在 2022 年九合一縣市大選前製作「2022 百里侯爭霸戰」網站，整合政府公開的相關資訊，呈現 94 位候選人的政見及財產；並在投開票當日即時呈現開票結果，進行客觀的選戰分析。' },
  { title: '由點連成線，將政見追蹤延伸成面', text: '以「百里侯」網站為基礎，除了關注選戰動向，更延伸佈局，持續追蹤選後的政見施行。\n\n而除了縣市首長外，今年也預計推出 2024 總統網站，提供選民更全面的追蹤資訊。' },
  { title: '客觀中立，持續追蹤至任期結束', text: '為了維持本次計畫的公平性，本網站不接受來自縣市政府的廣告，讓我們站在公正的立場上，見證每個政見。' },
]

const Footer = () => {
  return (
    <Box bg="custom.darkGray" color="white" pos="relative" id="about">
      <Container pt={{ base: '3.75em', md: '4.125em' }} pb={{ base: '1.125em', md: '2em' }}>
        <Box mx="auto" w={{ base: '5em', md: '7.25em' }}><Image src={logo} /></Box>
        <Text.Title mt={{ base: '3.5rem', md: '2.625rem' }} textAlign="center">為什麼由菱傳媒來追蹤？</Text.Title>
        <Flex flexDir={{ base: 'column', md: 'row' }} mt={{ base: '2.5rem', md: '3.125rem' }} gap={{base: 8, md: '7.75em'}}>
          {reasons.map((d ,i) => (
            <Box width={{ base: '100%', md: `${100 / 3}%` }} key={i}>
              <Text fontWeight="black" fontSize={{ base: '1.125em', md: '1.25em' }} color="custom.yellow">{d.title}</Text>
              <Text
                mt={{ base: '1.5rem', md: '1.375rem' }}
                fontWeight="bold"
                color="white"
                whiteSpace="pre-wrap"
                textAlign="justify"
              >{d.text}</Text>
            </Box>
          ))}
        </Flex>
        <Text mt={{ base: '4.5rem', md: '3.75rem' }} textAlign="center" fontSize={{ base: '0.875em', md: '0.875em' }}>菱傳媒版權所有 © {new Date().getFullYear()}</Text>
      </Container>
    </Box>
  )
}

export default Footer
