import { Box, Flex, Text } from '@chakra-ui/layout';
import React from 'react'
import { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts';

// const count = {
//   臺北市: { before: 12, after: 0 },
//   新北市: { before: 4, after: 67 },
//   桃園市: { before: 16, after: 0 },
//   臺中市: { before: 15, after: 271 },
//   臺南市: { before: 30, after: 0 },
//   高雄市: { before: 20, after: 96 },
//   基隆市: { before: 12, after: 0 },
//   新竹縣: { before: 10, after: 37 },
//   新竹市: { before: 33, after: 97 },
//   苗栗縣: { before: 19, after: 113 },
//   彰化縣: { before: 8, after: 44 },
//   南投縣: { before: 10, after: 0 },
//   嘉義縣: { before: 10, after: 44 },
//   嘉義市: { before: 10, after: 28 },
//   雲林縣: { before: 29, after: 96 },
//   屏東縣: { before: 10, after: 0 },
//   宜蘭縣: { before: 14, after: 70 },
//   花蓮縣: { before: 9, after: 0 },
//   臺東縣: { before: 35, after: 99 },
//   澎湖縣: { before: 23, after: 0 },
//   金門縣: { before: 6, after: 12 },
//   連江縣: { before: 10, after: 28 },
// }

const legend = [{ label: '選舉公報政見數', color: '#B1D9E5' }, { label: '就職後公布子政見數', color: '#70B8D5' }]

const CustomizedLabelList = ({ x, y, value, width, height, color, fill }) => {
  return value > 0 && (
    <g>
      <text
        x={width > 22 ? x + width - 4 : x + (value.toString().length > 1 ? width / 2 : width) + value.toString().length * 16 - 4}
        y={y + height / 2 + 5}
        fill={width > 22 ? fill : color}
        fontSize={16}
        textAnchor="end"
      >
        {value}
      </text>
    </g>
  )
}

const BarChartModule = ({ cityData = [], city, isMobile, chartData }) => {
  const data = useMemo(() => [{ name: '', countA: chartData?.[city].before, countB: chartData?.[city].after || cityData?.length }] || [] ,[city, cityData, chartData])
  return (
    <Box pt={{ base: '1em', lg: '1.875em' }} pos="relative">
      <Flex gap={{ base: 2, lg: 12 }}>
        {legend.map(({label, color}) => (
          <Flex gap={2} alignItems={'center'} key={label}>
            <Box w={{base: '1.5em', lg: '2em'}} h={{base: '0.75rem', lg: '1.125em'}} bg={color} />
            <Text fontSize={{ base: '0.875em', md: '0.875em' }}>{label}</Text>
          </Flex>
        ))}
      </Flex>
      <Box pt={{base: '1.25em', lg: '2em'}}>
        <ResponsiveContainer width={isMobile ? '100%' : 414} height={100}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              bottom: 10,
              left: -55
            }}
            layout="vertical"
          >
            <XAxis type="number" tickCount={8} domain={[0, 280]} />
            <YAxis type="category" tickLine={false} tick={false} />
            <Bar dataKey="countA" fill="#B1D9E5">
              <LabelList content={<CustomizedLabelList color="#B1D9E5" />} dataKey="countA" fill="#fff"/>
            </Bar>
            <Bar dataKey="countB" fill="#70B8D5">
              <LabelList content={<CustomizedLabelList color="#70B8D5" />} dataKey="countB" fill="#fff" position="insideRight" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default BarChartModule
