import { extendTheme, theme, defineStyleConfig  } from '@chakra-ui/react';
import get from 'lodash/get';
import breakpoints from './breakpoints';

export const containerWidth = [null, '48em', '60em', '80em'];

const font =
  'Open Sans, Arial, Noto Sans TC, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';

  const fonts = {
  heading: font,
  body: font,
  mono: 'Menlo, monospace',
};

const primary = 'blue';
const secondary = 'green';
const danger = 'red';

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    primary: get(theme.colors, `${primary}.500`),
    secondary: get(theme.colors, `${secondary}.500`),
    danger: get(theme.colors, `${danger}.500`),
    text: get(theme.colors, 'black'),
    custom: {
      darkGray: '#323333',
      bgGray: '#EFEFEF',
      textGray: '#B0B0B0',
      blockBgGray: '#A0A0A0',
      lightGray: '#DCDDDD',
      lineGray: '#C9CACA',
      circleBgGray: '#C9C8BD',
      red: '#E85130',
      bgOrange: '#F29200',
      bgBlue: '#99C8D7',
      btnBg: '#E75031',
      borderPink: '#F096AB',
      pink: '#F26178',
      yellow: '#F2AF0F'
    }
  },
  components: {
    Container: {
      baseStyle: {
        maxW: { base: '30em', md: '1440px' },
        px: { base: '2em', md: '8.5%', xl: '7.625em' },
        pos: 'relative'
      },
    },
    Button: {
      baseStyle: {
        minW: 'auto'
      },
      variants: {
        customize: {
          _hover: {},
          _active: {},
          minW: 'auto',
        },
      },
      defaultProps: {
        variant: 'customize'
      }
    }
  },
  breakpoints,
  containerWidth,
  headerHeight: '5em',
};

const customTheme = extendTheme(overrides);

export default customTheme;
