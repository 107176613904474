import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Label  } from 'recharts';
import { Box, Text, useOutsideClick } from '@chakra-ui/react';
import styled from '@emotion/styled';

const category = {
  經濟就業: '以經濟發展為考量，包含協助勞工就業、擴大招商，設立青創中心或更具規模的產業園區。',
  環境永續: '促進環境保育、以淨零碳排為目標，發展替代性能源，改善環境汙染或規劃公園綠地等。',
  社福醫療: '著眼於社會福利，如增加托嬰、托育、長期照護資源，或協助弱勢族群、規劃社會住宅等。',
  數位科技與其他: '應用大數據、區塊鍊等新興科技或設立相關局處，強化施政效率、提高資訊透明度；並納入未能其他類別的政見。',
  治安交通: '規劃地區、跨區交通網絡促進城市發展；或強化地方治理，改善警消權益、加強防災效率。',
  文化教育: '推動「軟實力」，舉辦節慶活動、規劃藝文場館或改善教育環境、促進多元文化發展。'
}

const StyledBox = styled(Box)`
  .recharts-polar-grid {
    line {
      stroke: #C9CACA;
    }
    path {
      stroke: #000000;
    }
  }
  .recharts-polar-radius-axis-tick {
    // text {
    //   fill: #000000;
    // }
    tspan {
      ::after {
        content: "%";
      }
    }
  }
`

const RenderCustomTick = ({ x, y, payload, data, payloads, setPayload, isMobile, show, ...props }) => {
  const customY = (payload.index === 2 || payload.index === 3) ? y + 12 : y
  const onLeft = payload.index > (data.length / 2 - 1)
  useEffect(() => {
    if (payloads.length < 6) {
      payloads.push({ x, y, value: payload.value, ...props })
    }
    setPayload([...payloads])
  }, [])
  return (
    <>
      {/* <Tooltip placement="bottom-start" ref={ref} isOpen={isMobile && isModalOpen} label={label(payload.value)}> */}
        <svg x={x - (payload.value.length * (isMobile ? 12 : 16) * (onLeft ? 1 : -1)) - (onLeft ? (isMobile ? 16 : 28) : -4)} y={customY - (isMobile ? 12 : 18)} width={isMobile ? (isMobile ? 12 : 16) : "24"} height={isMobile ? (isMobile ? 12 : 16) : "24"} viewBox="0 0 24 24" fill="none">
          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z" fill="black"/>
        </svg>
      {/* </Tooltip> */}
      <text fontSize={isMobile ? 12 : 16} x={x} y={customY} {...props}>
        {payload.value}
      </text>
    </>
  );
};

const RenderCustomRadiusTick = ({ x, y, isMobile, payload, index, ...props }) => {
  return payload.value && (
    <g>
      <text fontSize={isMobile ? 6 : 8} textAnchor="middle" x={x} y={y + (isMobile ? 16 : 20) - (4 - index) * 2} fill="#000000">
        {payload.value}%
      </text>
    </g>
  );
};


const RadarModule = ({ cityData = [], isMobile }) => {
  const data = useMemo(() => cityData.reduce((res, d) => {
      const index = res.findIndex(r => r.subject === d.category)
      if (index > -1) {
        res[index].count += 1
      }
    return res
  }, [
    { subject: '環境永續', count: 0, max: 100 },
    { subject: '社福醫療', count: 0, max: 100 },
    { subject: '數位科技與其他', count: 0, max: 100 },
    { subject: '治安交通', count: 0, max: 100 },
    { subject: '文化教育', count: 0, max: 100 },
    { subject: '經濟就業', count: 0, max: 100 },
  ]).map((d) => ({ ...d, count: d.count * 100 / cityData.length})) ,[cityData])
  // console.log(data)
  const [show, setShow] = useState()
  const [payloads, setPayload] = useState([])
  const ref = useRef()
  const [isModalOpen, setIsModalOpen] = useState(false)
  useOutsideClick({
    ref: ref,
    handler: () => setIsModalOpen(false),
  })
  const activeData = useMemo(() => show && payloads[payloads.findIndex(d => d.value === show)], [show])
  useEffect(() => {
    if (show && !isModalOpen) {
      setIsModalOpen(true)
    }
  },[show])
  return (
    <StyledBox height={isMobile ? 172 : 246} maxWidth={isMobile ? 300 : '100%'} mx="auto" mt={{ base: '1em', lg: '3.75em' }} pos="relative">
      <RadarChart width={isMobile ? 300 : 400} height={isMobile ? 172 : 246} startAngle={60} endAngle={-300} cx="50%" cy="50%" data={data}>
        <PolarRadiusAxis
          domain={[0, 100]}
          angle={90}
          tickCount={5}
          axisLine={false}
          tick={<RenderCustomRadiusTick isMobile={isMobile} />}
        />
        <PolarGrid stroke="#C9CACA" />
        <Radar dataKey="count" stroke="rgba(66, 175, 208, 0.5)" fill="rgba(66, 175, 208, 0.5)" dot={{ fill: '#42AFD0' }} />
        <Radar animationDuration={0} dataKey="max" fill="rgba(255, 255, 255, 0)" dot={{ fill: '#000000' }} />
        <PolarAngleAxis
          tick={<RenderCustomTick isMobile={isMobile} data={data} show={show} payloads={payloads} setPayload={setPayload} />}
          tickLine={false}
          stroke="#000000"
          dataKey="subject"
          domain={[0, 100]}
          dot={true}
          onMouseEnter={(e) => {
            if (!isMobile) {
              setShow(e.value)
            }
          }}
          onMouseLeave={() => {
            if (!isMobile) {
              setShow()
              setIsModalOpen(false)
            }
          }}
          onClick={(e) => {
            if (show === e.value) {
              setShow()
            } else {
              setShow(e.value)
            }
          }}
        />
      </RadarChart>
      {isModalOpen && show && (
        <Box
          ref={ref}
          p="0.75rem"
          borderRadius={'0.5rem'}
          bg="rgba(50, 51, 51, 0.9)"
          color="white"
          pos="absolute"
          top={(activeData.index === 2 || activeData.index === 3) ? activeData.y + 24 : activeData.y + 12}
          left={activeData.index > (data.length / 2 - 1) ? activeData.x - show.length * (isMobile ? 12 : 16) - (isMobile ? 16 : 28) : activeData.x}
          width={{base: '50%', lg: '40%'}}
          transform={{base: activeData.index === 1 ? 'translateX(-50%)' : activeData.index < 3 && 'translateX(-25%)', lg: activeData.index === 1 && 'translateX(-50%)'}}
          fontSize={{ base: '0.875em', md: '0.875em' }}
          zIndex={5}
        >
          <Text>{show}</Text>
          <Text mt="0.5rem">{category[show]}</Text>
        </Box>
      )}
    </StyledBox>
  )
}

export default RadarModule
