import React from 'react';
import { Box, Flex, Image, Container } from '@chakra-ui/react';
import Text from 'components/Text';

import bg from './bg.png';
import left from './left.png';
import right from './right.png';
import left_circles from './left_circles.png';
import right_circles from './right_circles.png';
import circles from './circles.png';

const Description = () => {
  return (
    <Box
      bg={`url(${bg})`}
      backgroundRepeat="repeat"
      pt={{ base: '5em', md: '6.875em' }}
      pb={{ base: '7em', md: '15.25em' }}
      pos="relative"
      id="description"
    >
      <Box
        pos="absolute"
        left="0"
        bottom="0"
        width={{ base: '15.125em', md: '36.75em' }}
      >
        <Image src={left} />
      </Box>
      <Box
        pos="absolute"
        display={{ base: 'none', md: 'block' }}
        right={{ base: '4.25em', md: '24.8%', '2xl': '27%' }}
        top={{ base: '6.625em', md: '12.625em' }}
        width={{ base: '2.75em', md: '6.875em' }}
      >
        <Image src={left_circles} />
      </Box>
      <Box
        pos="absolute"
        display={{ base: 'none', md: 'block' }}
        right={{ base: '0.75em', md: '15.3%', '2xl': '17.65%' }}
        top={'2.625em'}
        width={{ base: '2.625em', md: '9em' }}
        zIndex={6}
      >
        <Image src={right_circles} />
      </Box>
      <Box
        pos="absolute"
        display={{ base: 'block', md: 'none' }}
        right={'0.75em'}
        top={'2.625em'}
        width={'6.125em'}
        zIndex={6}
      >
        <Image src={circles} />
      </Box>
      <Box
        pos="absolute"
        right={{ base: '0' }}
        bottom={{ base: '0' }}
        width={{ base: '6.625em', md: '12.75em' }}
      >
        <Image src={right} />
      </Box>
      <Container pos={'relative'} zIndex={55}>
        <Flex flexDir={{ base: 'column', md: 'row' }}>
          <Text letterSpacing={{ base: '1.5px', md: '2.5px' }} fontWeight={'black'} fontSize={{ base: '1em', md: '1.5em' }}>
            選票的價值不該像條拋物線
            <br />
            高峰只存在短暫的選舉期間
          </Text>
          <Text
            mt={{ base: '1.5em', md: 0 }}
            ml={{ base: 0, md: '9.875em' }}
            fontWeight={'bold'}
            fontSize={{ base: '1em', md: '1.25em' }}
            letterSpacing={{ base: '1.5px', md: '2px' }}
          >
            從選前政見到選後實現的距離
            <br />
            就是臺灣下一階段的民主課題
            <br />
            邀請你透過這個平台
            <br />
            持續追蹤政見的施行進度與落地
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};

export default Description;
