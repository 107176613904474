import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import range from 'lodash/range'
import set from 'lodash/set'
import isObject from 'lodash/isObject'
import Button from 'components/Button'
import { StyledCheckbox } from './Filter'

const CityFilter = ({ city, setCity, filters }) => {
  // console.log(city)
  return (
    <Box
      pos="absolute"
      top="100%"
      left="0"
      right="0"
      bg="white"
      borderRadius="0.5em"
      width={{base: '100%', lg: '25em'}}
      px={{base: '1em', lg: '1.625em'}}
      pt={{base: '1em', lg: '2em'}}
      pb={{base: '1em', lg: '1.5em'}}
    >
      <Box textAlign={'right'}>
        <Button
          textDecoration="underLine"
          bg="transparent"
          color="black"
          minW="autp"
          px="0"
          fontSize={{ base: '0.875em', md: '1em' }}
          onClick={() => {
            setCity(range(filters.length).fill(false))
          }}
        >
          清除選取
        </Button>
      </Box>
      <Flex gap={'4px'} pt={{ base: '0.5em' }} flexWrap="wrap">
        {filters.map((opt, i) => (
          <Box key={i}>
            <StyledCheckbox
              icon={<div />}
              borderColor="#89897B"
              isChecked={city[i]}
              onChange={(e) => {
                if (city[i]) {
                  set(city, [i], false)
                } else {
                  set(city, [i], opt)
                }
                setCity([...city])
                if (typeof window.gtag === 'function') {
                  window.gtag("event", "click_button", {
                    button_name: isObject(opt) ? opt.name : opt
                  });
                }
              }}
            >
              {opt}
            </StyledCheckbox>
          </Box>
        ))}
      </Flex>
      {/* <Box textAlign={'center'} mt="1em">
        <Button
          onClick={() => {
            setFilter(false)
            if (typeof window.gtag === 'function') {
              window.gtag("event", "click_button", {
                button_name: 'comfirm'
              });
            }
          }}
        >確認</Button>
      </Box> */}
    </Box>
  )
}

export default CityFilter
