import React from 'react';
import { Box, Flex, IconButton, Image, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-scroll'
import { FiMenu } from "react-icons/fi";

import Button from 'components/Button';
import BackgroundImage from 'components/BackgroundImage';

import doc from './doc.png'
import book from './book.png'
import magnifier from './magnifier.png'
import title from '../header_title.png'
import Menu from './Menu';

export const btns = [
  { label: '觀看計畫簡介', name: '計畫簡介', icon: doc, key: 'description' },
  { label: '開始政見追蹤', name: '政見追蹤', icon: magnifier, key: 'track' },
  { label: '閱讀民調報導', name: '民調報導', icon: book, key: 'reports' },
]

const Header = ({ siteTitle, ...props }) => {
  const [isMobile] = useMediaQuery('(max-width: 895px)')
  const { onOpen, onClose, isOpen } = useDisclosure()
  return (
    <Flex
      position="fixed"
      bg="white"
      top={0}
      left={0}
      right={0}
      alignItems="center"
      justify={'space-between'}
      zIndex={99}
      as="header"
      px={{ base: '0.75em', md: '2.25em' }}
      py={{ base: '0.625em', md: '1.25em' }}
      {...props}
    >
      <Box
        cursor={'pointer'}
        width={{ base: '7.25em', sm: '12em', md: '14.375rem' }}
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }}
      >
        <BackgroundImage src={title} ratio={2280 / 318} />
      </Box>
      {isMobile ? (
        <IconButton
          icon={<FiMenu />}
          onClick={onOpen}
        />
      ) : (
        <Flex pr={{ base: 0, lg: 12 }} gap={{ base: 2 }}>
          {btns.map(({ icon, name, key }, i) => (
            <Button
              as={(p) => <Link to={key} offset={-50} smooth {...p} />}
              p={{ base: '0.5em', md: '0.5em 0.75em' }}
              w={{ base: '1.875em', md: 'auto' }}
              h={{ base: '1.875em', md: 'auto' }}
              border="1px solid black"
              borderRadius="full"
              bg="transparent"
              cursor="pointer"
              key={i}
            >
              {name}
            </Button>
          ))}
        </Flex>
      )}
      <Menu isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
};

export default Header;
