import React, { useState, useMemo } from 'react'
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Center,
  Flex,
  Image,
  Text
} from '@chakra-ui/react'
import { useMediaQuery } from '@chakra-ui/media-query'
import Map from './Map'
import arrow_left from './arrow_left.png'
import ChartData from './ChartData'

const Analytics = ({ data, index, open, ...props }) => {
  const [city, setCity] = useState()
  const [isMobile] = useMediaQuery('(max-width: 1151px)')
  const isOpen = useMemo(() => open.includes(index), [open, index])
  const cityData = useMemo(() => !data.loading ? data?.value?.data?.filter(d => d.city === city) : [] ,[city, data])
  return (
    <Flex transition={'all 0.5s'} height={isOpen ? 'auto' : 0} opacity={isOpen ? 1 : 0} pt={isOpen && { base: '4em', lg: '5.25em' }} {...props}>
      <Box flex={1} pr={{ base: 4, lg: '7.5em' }} maxW={{ base: '100%', md: '75%', lg: '100%' }} mx="auto">
        <Map data={data?.value?.chart} setCity={setCity} city={city} />
      </Box>
      {!isMobile && (city ? (
        <ChartData cityData={cityData} chartData={data?.value?.chart} city={city} />
      ) : (
        <Center w="40%" minWidth={'29.5em'} bg={"rgba(255, 255, 255, 0.6)"} px={{ base: '1em', lg: '2.25em' }} borderRadius="0.875em">
          <Box mr={{base: 0, lg: '1.25em'}}><Image src={arrow_left} /></Box>
          <Text color="custom.bgOrange" fontSize={{base: '1em', md: '1.25em'}}>請於左方點選想關注的城市</Text>
        </Center>
      ))}
      <Modal isCentered scrollBehavior="inside" isOpen={isMobile && city} onClose={() => setCity()}>
        <ModalContent width="90%">
          <ModalCloseButton />
          <ModalBody>
            <ChartData isMobile={isMobile} cityData={cityData} chartData={data?.value?.chart} city={city} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default Analytics
