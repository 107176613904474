import { Box, Text } from '@chakra-ui/layout'
import React from 'react'
import RadarModule from './Radar'
import BarChartModule from './BarChart'

const ChartData = ({ city, cityData, isMobile, chartData }) => {
  // console.log(cityData)
  return (
    <Box h={{ base: '100%', lg: '60%' }} w={{ base: '100%', lg: '40%' }} minWidth={{ base: '100%', lg: '29.5em' }} bg="white" py={{ base: '1.75em', lg: '2em' }} px={{ base: 0, lg: '2.25em' }} borderRadius={'0.875em'}>
      <Text fontSize={{ base: '1.5em', lg: '2.25rem' }} color="custom.textGray" fontWeight={'black'}>{city}</Text>
      <Box>
        <Text fontSize={{ base: '1em', lg: '1.25rem' }} fontWeight={'black'}>政見面向分析</Text>
        <Text pt="0.25rem" fontSize={{ base: '0.875em', lg: '1rem' }} fontWeight={'bold'}>將各縣市所提出的政見，透過雷達圖劃分為六個面向，可以看見該首長注重的政見面向。</Text>
        <RadarModule isMobile={isMobile} cityData={cityData} />
      </Box>
      <Box mt={{base: '2.5em', lg: '5em'}}>
        <Text fontSize={{ base: '1em', lg: '1.25rem' }} fontWeight={'black'}>就職後政見數量修正</Text>
        <Text pt="0.25rem" fontSize={{ base: '0.875em', lg: '1rem' }} fontWeight={'bold'}>在縣市長正式就職後，通常會進一步將選前政見展開成更多子項政見，成為各項實際執行計畫。</Text>
        <BarChartModule isMobile={isMobile} city={city} chartData={chartData} cityData={cityData} />
      </Box>
    </Box>
  )
}

export default ChartData
