import { AspectRatio, Box, keyframes } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useMeasure } from 'react-use';
import { motion } from 'framer-motion';

const colors = ['custom.darkGray', 'custom.yellow'];

const containerW = 205;
// const getAnimation = (i) => keyframes`
// 0%, 100% {
//   motion-offset: ${i ? 100 : 0}%;
//   offset-distance: ${i ? 100 : 0}%;
// }
// 50% {
//   motion-offset: ${i ? 0 : 100}%;
//   offset-distance: ${i ? 0 : 100}%;
// }
// `;
// const browser = new UAParser().getBrowser();
// const isSafari = browser.name === 'Safari';

const PingPong = () => {
  const [ref, { width }] = useMeasure();

  const points = useMemo(() => {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute(
      'd',
      'M24.39,26.67C126.24,50.75,202.51,149.33,202.51,267.28c0,95.22-49.71,177.82-122.46,218.79'
    );
    const totalLength = path.getTotalLength();
    const unit = totalLength / 100;
    const r = width / containerW;
    return Array.from({ length: 100 }, (_, i) => {
      const { x, y } = path.getPointAtLength(i * unit);
      return {
        x: x * r,
        y: y * r,
      };
    });
  }, [width]);
  return (
    <Box
      pos="absolute"
      top="-100%"
      right="0"
      w="100%"
      h="200%"
      overflow="hidden"
      ref={ref}
    >
      {colors.map((color, i) => {
        const thisPoints = (i ? points : points.slice().reverse()).concat(
          i ? points.slice().reverse() : points
        );
        return (
          <motion.div
            key={i}
            style={{
              position: 'absolute',
              width: '25%',
              top: 0,
              left: 0,
            }}
            animate={{
              x: thisPoints.map((p) => p.x),
              y: thisPoints.map((p) => p.y),
              transition: {
                duration: 3,
                repeat: Infinity,
              },
            }}
          >
            <AspectRatio ratio={1} transform="translate(-50%, -50%)">
              <Box bg={color} borderRadius="full" />
            </AspectRatio>
          </motion.div>
        );
      })}
    </Box>
  );
};

export default PingPong;
