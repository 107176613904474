import { useMediaQuery } from '@chakra-ui/media-query'
import SVG from 'components/SVG'
import React from 'react'

const cities = [
  {
    path: [
      'm78.9,95.24H5.8c-2.8,0-5-2.2-5-5V17.14c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm24.5,52.34c-.4,2.3-1.1,4.6-1.9,6.5.4.2.9.6,1.5,1,1.7,1,4,1.2,7,1.2s7.3-.2,10.1-.6c-.3.8-.8,2.2-.9,3-2,.1-6.5.3-9.4.3-3.3,0-5.4-.3-7.2-1.3-1-.6-1.9-1.4-2.4-1.4-.6,0-1.5,1.7-2.1,3.1l-2-2.8c.9-1,1.8-1.9,2.6-2.4.6-1.2,1.1-2.8,1.4-4.3h-3.1c.6-1.5,1.2-3.5,1.8-5.4h-2.2v-2.7h5.8c-.5,1.7-1.1,3.7-1.7,5.5h1l.5-.1,1.2.4Zm-4.2-6.5c-.3-1.1-.9-2.9-1.3-4.3l2.6-.8c.4,1.3,1.1,3,1.4,4.1l-2.7,1Zm10.3-.9h-6.2v-2.4h6.2v-1.8h2.9v1.8h6.5v2.4h-6.5v1.2h5.4v8.5h-5.4v1.1h6.8v2.4h-6.8v2.2h-2.9v-2.2h-6.2v-2.4h6.2v-1.1h-5.2v-8.5h5.2v-1.2Zm-2.5,4.5h2.5v-1.2h-2.5v1.2Zm2.5,3.1v-1.3h-2.5v1.3h2.5Zm2.9-4.4v1.2h2.6v-1.2h-2.6Zm2.6,3.2h-2.6v1.3h2.6v-1.3Z',
      'm49.7,52.74c-1-.7-3.1-1.9-4.6-2.7l1.6-2.2c1.4.6,3.6,1.7,4.7,2.4l-1.7,2.5Zm3.1,3.1c-1.3,2.6-2.9,5.4-4.4,7.9l-2.5-2c1.3-1.9,3.2-5,4.7-7.9l2.2,2Zm-1.9-9.7c-1-.8-3-2-4.4-2.9l1.7-2.1c1.4.7,3.5,1.9,4.5,2.7l-1.8,2.3Zm17.3,13.4v3h-16.3v-3h6.4v-13.8h-5.1v-3h13.8v3h-5.4v13.8h6.6Z'
    ],
    deskPath: [
      'M94.7,99.7H5c-2.8,0-5-2.2-5-5V5c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C99.7,97.4,97.4,99.7,94.7,99.7z',
      'M35.2,52c-0.3,1.8-0.9,3.7-1.6,5.2c0.3,0.2,0.7,0.5,1.2,0.8c1.4,0.8,3.2,0.9,5.6,0.9c2.3,0,5.9-0.2,8.1-0.5c-0.3,0.6-0.7,1.8-0.7,2.4c-1.6,0.1-5.2,0.2-7.5,0.2c-2.7,0-4.3-0.2-5.8-1.1c-0.8-0.5-1.6-1.1-2-1.1c-0.5,0-1.2,1.4-1.7,2.5l-1.6-2.3c0.7-0.8,1.4-1.5,2.1-1.9c0.5-1,0.9-2.2,1.2-3.5h-2.5c0.5-1.2,1-2.8,1.4-4.3h-1.8v-2.1h4.7c-0.4,1.4-0.9,3-1.4,4.4h0.8l0.4-0.1L35.2,52z M31.9,46.8c-0.2-0.9-0.8-2.4-1.1-3.5l2.1-0.6c0.4,1.1,0.9,2.4,1.2,3.3L31.9,46.8zM40.1,46.1h-5v-1.9h5v-1.4h2.3v1.4h5.2v1.9h-5.2v1h4.4v6.8h-4.4v0.9h5.5v1.9h-5.5v1.8h-2.3v-1.8h-5v-1.9h5v-0.9h-4.2V47h4.2V46.1z M38.1,49.7h2v-1h-2V49.7z M40.1,52.2v-1h-2v1H40.1z M42.5,48.7v1h2.1v-1H42.5z M44.5,51.2h-2.1v1h2.1V51.2z',
      'M55.4,52.3c-0.8-0.6-2.5-1.5-3.7-2.2l1.3-1.8c1.1,0.5,2.9,1.4,3.8,1.9L55.4,52.3z M57.9,54.9c-1,2.1-2.3,4.3-3.5,6.3l-2-1.6c1.1-1.5,2.6-4,3.8-6.3L57.9,54.9z M56.4,47c-0.8-0.6-2.4-1.6-3.5-2.3l1.4-1.7c1.1,0.6,2.8,1.5,3.6,2.1L56.4,47z M70.3,57.8v2.4H57.2v-2.4h5.1v-11h-4.1v-2.4h11.1v2.4H65v11H70.3z'
    ],
    name: '連江縣',
  },
  {
    path: [
      'm78.9,278.84H5.8c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm29.7,235.84v2.6h8v2.7h-3.9l2.5.9c-.9,1.4-1.8,2.9-2.6,4h5.4v2.7h-21.7v-2.7h4.9c-.4-1.1-1.2-2.8-2.1-4l2-.8h-3.6v-2.7h7.9v-2.6h-5.1v-1.2c-1,.5-2.1.9-3.2,1.3-.4-.9-1.2-2-2-2.7,4.7-1.3,9.2-3.9,11.6-7.6l2.8,1.3c-.2.3-.5.6-.7,1,2.4,2.2,6.5,4.3,10.2,5.3-.7.6-1.6,1.8-2,2.6-1.1-.4-2.3-.9-3.4-1.4v1.5h-5v-.2Zm-7,5.2c.9,1.2,1.8,2.9,2.2,4l-2,.9h3.6v-4.9h-3.8Zm9.8-7.9c-1.6-1-3-2.1-4.3-3.2-1.2,1.2-2.6,2.3-4,3.2h8.3Zm-2.8,7.9v4.9h3.1l-1.7-.7c.8-1.1,1.7-2.9,2.2-4.2h-3.6Z',
      'm54.7,236.44h-6.6v12.7h-2.9v-22.3h9.5v9.6Zm-6.6-7.2v1.3h3.8v-1.3h-3.8Zm3.8,4.9v-1.4h-3.8v1.4h3.8Zm14.3,11.5c0,1.7-.3,2.6-1.4,3-1.1.5-2.7.5-5.1.5-.1-.8-.6-2.2-1-3,1.6.1,3.4.1,3.9.1s.7-.2.7-.6v-9h-6.8v-9.7h9.8v18.7h-.1Zm-7-16.4v1.3h3.9v-1.3h-3.9Zm4,4.9v-1.5h-3.9v1.5h3.9Z'
    ],
    deskPath: [
      'M94.7,324.8H5c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C99.7,322.6,97.4,324.8,94.7,324.8z',
      'M40.6,277.7v2.1H47v2.1h-3.2l2,0.7c-0.7,1.1-1.5,2.3-2.1,3.2H48v2.2H30.7v-2.2h3.9c-0.3-0.9-1-2.2-1.7-3.2l1.6-0.7h-2.9v-2.1h6.4v-2.1H34v-1c-0.8,0.4-1.7,0.8-2.6,1.1c-0.3-0.7-1-1.6-1.6-2.2c3.8-1.1,7.4-3.1,9.3-6.1l2.3,1c-0.2,0.3-0.4,0.5-0.6,0.8c2,1.8,5.2,3.4,8.2,4.3c-0.5,0.5-1.3,1.5-1.6,2.1c-0.9-0.3-1.8-0.7-2.7-1.2v1.2H40.6z M35,282c0.7,1,1.5,2.3,1.7,3.2l-1.6,0.7h2.9V282H35z M42.9,275.6c-1.3-0.8-2.4-1.7-3.5-2.6c-1,1-2.1,1.8-3.2,2.6H42.9z M40.6,282v3.9h2.5l-1.3-0.6c0.6-0.9,1.4-2.3,1.8-3.3H40.6z',
      'M60.6,278.3h-5.3v10.2H53v-17.9h7.6V278.3z M55.4,272.5v1h3v-1H55.4z M58.4,276.4v-1.1h-3v1.1H58.4zM69.8,285.6c0,1.4-0.3,2.1-1.2,2.4c-0.9,0.4-2.2,0.4-4.1,0.4c-0.1-0.7-0.5-1.8-0.8-2.4c1.3,0.1,2.7,0.1,3.2,0.1s0.6-0.1,0.6-0.5v-7.2H62v-7.8h7.8V285.6z M64.3,272.5v1h3.2v-1H64.3z M67.4,276.4v-1.2h-3.2v1.2H67.4z'
    ],
    name: '金門縣',
  },
  {
    path: [
      'm78.9,556.94H5.8c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm20.4,516.24c-.8-.8-2.7-2-4.1-2.8l1.4-2.2c1.3.7,3.2,1.8,4.2,2.5l-1.5,2.5Zm2.3,3.1c-1.1,2.6-2.4,5.4-3.7,7.8l-2.2-2c1.1-1.9,2.7-4.9,4-7.8l1.9,2Zm-1.4-9.8c-.9-.8-2.6-2-3.9-2.8l1.4-2.1c1.2.6,2.9,1.7,3.9,2.5v-1h3.4v-1.7h2.8v1.7h3.5v2.4h-3.5v1.4h2.9v.2c1.8-1.2,3.7-3.3,4.7-5.4l2.7.9c-1.3,2.5-3.3,4.9-5.3,6.5-.5-.6-1.3-1.4-2-1.9v2h-8.6v-2.3h2.9v-1.4h-3.6v-1.1l-1.3,2.1Zm18.4,10.2c-1.4,3.3-3.7,5.9-6.7,7.8-.4-.7-1.2-1.6-1.9-2.1.2-.1.4-.2.6-.3-3.2.7-6.4,1.4-8.9,1.9l-.7-2.6c1.5-.2,3.5-.6,5.6-1,.5-1.3,1-3.2,1.3-4.5l2.7.7c-.5,1.1-.9,2.3-1.4,3.3l2.3-.4.2,1.9c1.8-1.4,3.3-3.3,4.1-5.4l2.8.7Zm-8.1-.9h-8.3v-5.6h8.3v5.6Zm-5.8.1c.6,1.1,1.1,2.7,1.2,3.7l-2.5.8c-.1-1-.5-2.6-1.1-3.8l2.4-.7Zm3.1-3.8h-3.1v1.7h3.1v-1.7Zm10.5-2.7c-1.3,2.6-3.3,4.9-5.5,6.5-.5-.6-1.3-1.5-2-2,1.9-1.2,3.8-3.3,4.8-5.4l2.7.9Z',
      'm47.8,515.64c-.8-.8-2.5-1.8-3.9-2.4l1.6-2.1c1.4.5,3.1,1.4,4,2.2l-1.7,2.3Zm-3.6,10.1c1-1.9,2.3-5.1,3.2-8l2.4,1.5c-.8,2.7-1.9,5.6-2.9,8l-2.7-1.5Zm4.5-16.6c-.8-.8-2.5-1.9-3.9-2.6l1.6-2.1c1.3.6,3.1,1.5,3.9,2.3l-1.6,2.4Zm17.9,15.3c0,1.2-.2,1.9-1,2.3-.7.5-1.8.5-3.3.5-.1-.7-.5-1.8-.9-2.4h2.1c.3,0,.4-.1.4-.4v-5.8c-.6.6-1.2,1.2-1.8,1.9l-.7-1.6c-.3,3.1-1.1,6.5-3,8.8-.5-.5-1.6-1.2-2.2-1.5.5-.6.9-1.3,1.3-2h-4.7v1.7h-2.5v-10.4h2.5v-3.8h-3.2v-2.7h3.2v-4h2.7v4h2.8v2.7h-2.8v3.8h2.4v7.9c1.1-2.7,1.2-5.8,1.2-8.4v-10h7.6v19.4h-.1Zm-11.3-6.3h-2.4v3.5h2.4v-3.5Zm6.3-3.1c0,.9,0,1.8-.1,2.8.7-.5,1.5-1.1,2.4-1.8v-1.9l-.8.6c-.3-.5-.9-1.3-1.5-2v2.3Zm0-7.3v3.4l.7-.5c.5.5,1.1,1.2,1.6,1.8v-4.7h-2.3Z'
    ],
    deskPath: [
      'M94.7,662.3H5c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C99.7,660.1,97.4,662.3,94.7,662.3z',
      'M33,612.6c-0.7-0.6-2.2-1.6-3.3-2.2l1.1-1.8c1.1,0.5,2.6,1.4,3.3,2L33,612.6z M34.8,615.1c-0.9,2.1-1.9,4.3-3,6.3l-1.7-1.6c0.9-1.5,2.2-4,3.2-6.3L34.8,615.1z M33.6,607.3c-0.7-0.6-2.1-1.6-3.1-2.2l1.1-1.7c1,0.5,2.4,1.4,3.1,2v-0.8h2.7v-1.3h2.2v1.3h2.8v1.9h-2.8v1.1H42v0.2c1.4-1,2.9-2.7,3.7-4.3l2.1,0.7c-1.1,2-2.6,3.9-4.3,5.2c-0.4-0.5-1-1.2-1.6-1.6v1.6h-6.9v-1.8h2.3v-1.1h-2.7v-0.9L33.6,607.3z M48.4,615.5c-1.1,2.6-3,4.8-5.3,6.2c-0.3-0.5-1-1.3-1.5-1.7c0.2-0.1,0.3-0.2,0.5-0.3c-2.5,0.6-5.1,1.1-7.1,1.5l-0.6-2.1c1.2-0.2,2.8-0.5,4.5-0.8c0.4-1,0.8-2.6,1-3.6l2.2,0.6c-0.4,0.9-0.8,1.8-1.1,2.6l1.9-0.3l0.1,1.5c1.4-1.1,2.6-2.7,3.3-4.3L48.4,615.5z M41.9,614.7h-6.7v-4.5h6.7V614.7z M37.2,614.9c0.5,0.9,0.9,2.2,1,3l-2,0.6c-0.1-0.8-0.4-2.1-0.9-3.1L37.2,614.9z M39.8,611.8h-2.5v1.3h2.5V611.8zM48.2,609.7c-1.1,2.1-2.6,4-4.4,5.2c-0.4-0.5-1.1-1.2-1.6-1.6c1.5-1,3.1-2.7,3.8-4.4L48.2,609.7z',
      'M54.9,612.2c-0.6-0.6-2-1.4-3.1-1.9l1.3-1.7c1.1,0.4,2.5,1.1,3.2,1.7L54.9,612.2z M52.1,620.3c0.8-1.6,1.8-4.1,2.6-6.4l1.9,1.2c-0.7,2.1-1.5,4.5-2.4,6.4L52.1,620.3z M55.7,607c-0.7-0.6-2-1.5-3.1-2.1l1.3-1.7c1.1,0.5,2.5,1.2,3.2,1.9L55.7,607z M70,619.2c0,1-0.2,1.5-0.8,1.9c-0.6,0.4-1.4,0.4-2.6,0.4c-0.1-0.5-0.4-1.4-0.7-2c0.7,0,1.4,0,1.7,0c0.3,0,0.3-0.1,0.3-0.4v-4.6c-0.5,0.5-1,1-1.4,1.5l-0.6-1.3c-0.2,2.5-0.9,5.2-2.4,7c-0.4-0.4-1.3-1-1.8-1.2c0.4-0.5,0.8-1,1-1.6h-3.8v1.4h-2v-8.2h2v-3h-2.6v-2.2h2.6v-3.2H61v3.2h2.3v2.2H61v3h1.9v6.3c0.9-2.1,1-4.6,1-6.7v-8H70V619.2zM60.9,614.2h-2v2.8h2V614.2z M66,611.7c0,0.7,0,1.4-0.1,2.2c0.6-0.4,1.2-0.9,1.9-1.4v-1.6l-0.6,0.5c-0.3-0.4-0.7-1-1.2-1.6V611.7z M66,605.8v2.8l0.5-0.4c0.4,0.4,0.9,0.9,1.3,1.4v-3.8H66z'
    ],
    name: '澎湖縣',
  },
  {
    path: [
      'm441.1,87.54h-73.1c-2.8,0-5-2.2-5-5V9.44c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm398.4,47.24c1.3,1.2,3.1,2.3,4.9,2.9-.6.5-1.5,1.6-2,2.3-1.3-.6-2.7-1.5-3.9-2.6v1.8h-4.5v1.6h8.1v2.4h-19v-2.4h7.8v-1.6h-4.4v-1.5c-1.2,1-2.6,1.9-4,2.5-.4-.7-1.3-1.7-1.9-2.3,1.8-.7,3.7-1.8,5-3.1h-4.8v-2.4h4.8v-7.4h-3.4v-2.4h3.4v-1.9h3v1.9h7.8v-1.9h3v1.9h3.4v2.4h-3.4v7.4h4.6v2.4h-4.5Zm-1.5,2.1c-.7-.6-1.3-1.3-1.8-2.1h-7c-.5.7-1.1,1.4-1.8,2.1h3.6v-1.5h3v1.5h4Zm-9.4-12v1.1h7.8v-1.1h-7.8Zm0,4.3h7.8v-1.1h-7.8v1.1Zm0,3.2h7.8v-1.1h-7.8v1.1Z',
      'm424.3,45.04v1.9h5.1v2.1h-5.1v1.2h4.6v2.1h-4.6v1.2h6.1v2.3h-15.7v-2.3h6.7v-1.2h-3.9v-1.6c-.1.2-.3.5-.5.7-.4-.4-1.5-1-2.1-1.3,1-1.3,1.7-3.1,2.1-4.9l2.4.5c-.1.4-.2.9-.4,1.3h2.3v-1.9h-3.3v-1.8c-.7.2-1.4.4-2.1.6-.3-.7-.9-1.7-1.4-2.3,2-.3,4-.9,5.8-1.7-.6-.5-1.1-1.1-1.6-1.6-.6.6-1.4,1.3-2.2,1.9-.3-.5-1-1.2-1.5-1.7-.4,1.3-.8,2.7-1.2,3.7,1.5,1.9,1.8,3.7,1.8,5.1,0,1.6-.3,2.6-1.1,3.2-.4.3-.8.5-1.4.6h-1.6c0-.7-.2-1.8-.7-2.5h1.1c.2,0,.5-.1.7-.2.3-.2.4-.7.4-1.5,0-1.1-.3-2.7-1.7-4.4.5-1.6,1-3.9,1.4-5.6h-1.9v19.6h-2.6v-22.2h5.4l.4-.1,1.9,1.1c-.2.8-.5,1.6-.8,2.5,2-1.5,3.4-3.3,4.1-4.9l2.8.5c-.2.4-.4.9-.7,1.4h5.6l.4-.1,1.8,1c-1,1.7-2.3,3.1-4,4.3,1.7.7,3.6,1.2,5.7,1.5-.5.5-1.3,1.6-1.6,2.3-.5-.1-1.1-.2-1.6-.3v1.7h-3.3v-.2Zm-2.8,4h-3.1c-.2.4-.4.8-.6,1.2h3.7v-1.2Zm5.1-6.1c-1.3-.4-2.6-1-3.8-1.6-1.2.6-2.6,1.2-3.9,1.6h7.7Zm-5.9-6.2c.6.6,1.3,1.2,2.1,1.7.9-.5,1.6-1.1,2.3-1.7h-4.4Z'
    ],
    deskPath: [
      'M453.2,164.1h-89.7c-2.8,0-5-2.2-5-5V69.4c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C458.2,161.9,456,164.1,453.2,164.1z',
      'M402.9,116.4c1.1,1,2.5,1.8,3.9,2.3c-0.5,0.4-1.2,1.3-1.6,1.8c-1.1-0.5-2.2-1.2-3.1-2.1v1.5h-3.6v1.3h6.5v2h-15.2v-2h6.3v-1.3h-3.5v-1.2c-1,0.8-2.1,1.5-3.2,2c-0.3-0.6-1-1.4-1.5-1.8c1.5-0.5,2.9-1.5,4-2.5H388v-1.9h3.8v-6H389v-1.9h2.8v-1.5h2.4v1.5h6.3v-1.5h2.4v1.5h2.7v1.9h-2.7v6h3.7v1.9H402.9z M401.6,118.1c-0.5-0.5-1-1.1-1.4-1.7h-5.6c-0.4,0.6-0.9,1.1-1.4,1.7h2.9v-1.2h2.4v1.2H401.6z M394.2,108.5v0.9h6.3v-0.9H394.2z M394.2,111.9h6.3V111h-6.3V111.9zM394.2,114.5h6.3v-0.9h-6.3V114.5z',
      'M423.6,114.6v1.5h4.1v1.7h-4.1v1h3.7v1.7h-3.7v1h4.9v1.9H416v-1.9h5.4v-1h-3.1v-1.3c-0.1,0.2-0.3,0.4-0.4,0.5c-0.4-0.3-1.2-0.8-1.7-1c0.8-1,1.4-2.5,1.7-4l1.9,0.4c-0.1,0.3-0.2,0.7-0.3,1.1h1.8v-1.5h-2.7v-1.5c-0.6,0.2-1.2,0.3-1.7,0.5c-0.2-0.5-0.7-1.4-1.1-1.9c1.6-0.3,3.2-0.7,4.7-1.4c-0.5-0.4-0.9-0.9-1.3-1.3c-0.5,0.5-1.1,1-1.8,1.5c-0.3-0.4-0.8-1-1.2-1.3c-0.3,1.1-0.7,2.1-1,3c1.2,1.6,1.5,3,1.5,4.1c0,1.3-0.3,2.1-0.9,2.6c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0-0.8,0-1.3,0c0-0.6-0.2-1.5-0.5-2c0.4,0,0.6,0,0.9,0c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.3-0.6,0.3-1.2c0-0.9-0.3-2.2-1.4-3.5c0.4-1.3,0.8-3.1,1.2-4.5h-1.6v15.7h-2.1v-17.8h4.4l0.3-0.1l1.6,0.9c-0.2,0.6-0.4,1.3-0.6,2c1.6-1.2,2.7-2.6,3.3-3.9l2.2,0.4c-0.2,0.4-0.4,0.7-0.6,1.1h4.5l0.4-0.1l1.4,0.8c-0.8,1.4-1.9,2.5-3.2,3.5c1.3,0.6,2.9,1,4.5,1.2c-0.4,0.4-1,1.3-1.3,1.8c-0.4-0.1-0.9-0.2-1.3-0.3v1.4H423.6z M421.4,117.8h-2.5c-0.2,0.3-0.3,0.7-0.5,1h2.9V117.8z M425.4,113c-1.1-0.3-2.1-0.8-3-1.3c-1,0.5-2.1,0.9-3.2,1.3H425.4z M420.7,108c0.5,0.5,1,1,1.7,1.4c0.7-0.4,1.3-0.9,1.9-1.4H420.7z'
    ],
    name: '基隆市',
  },
  {
    path: [
      'm441.1,182.04h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm402.5,132.14v1.8h-9.7v.8h7.6v1.7h-18v-1.7h7.4v-.8h-9.4v-1.8h9.4v-1.2h3v1.2h9.7Zm-9.7,19.6h9.5v2h-21.7v-2h9.3v-.8h-6.8v-1.7h6.8v-.7c-4.3.1-5,.2-5.6.3-.1-.4-.4-1.3-.6-1.8.4,0,.8-.1,1.3-.3.3-.1.9-.3,1.7-.7h-3.4v-.4h-2.6v-3.7h21.5v3.7h-2.8v-1.9h-16.1v.7h16v1.6h-1.8c1.1.6,2.1,1.4,2.8,1.9l-1.6,1.3h1.5v1.7h-7.3v.8h-.1Zm6.9-14.5v3.8h-16.4v-3.8h16.4Zm-2.9,1.5h-10.7v.9h10.7v-.9Zm-6.9,7.2c-.6.3-1.1.7-1.7.9l6.8-.1c-.5-.3-.9-.5-1.3-.7l.1-.1h-3.9Zm2.9,3.2h5.6c-.3-.3-.7-.6-1.1-.9l-4.5.1v.8Z',
      'm418.1,147.04l.5,2.6c-3.5,1.5-7.4,3-10.3,4.2l-1.3-3h0c1.7-.5,4.1-1.3,6.6-2.2v-8.9h-5.8v-3h5.8v-5.5h3.1v16.3l1.4-.5Zm8.1,3.6c.9,0,1.1-1.2,1.2-5.6.7.6,2,1.2,2.8,1.4-.3,5.2-1,7.1-3.8,7.1h-3c-2.7,0-3.6-1-3.6-4.5v-17.6h3.1v5.4h6.5v2.9h-6.5v9.3c0,1.3.1,1.6.9,1.6h2.4Z'
    ],
    deskPath: [
      'M453.2,285.4h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C458.2,283.2,456,285.4,453.2,285.4z',
      'M406.1,227.4v1.5h-7.7v0.7h6.1v1.4H390v-1.4h5.9v-0.7h-7.6v-1.5h7.6v-1h2.4v1H406.1z M398.3,243h7.6v1.6h-17.4V243h7.4v-0.6h-5.5V241h5.5v-0.6c-3.4,0.1-4,0.1-4.5,0.3c-0.1-0.3-0.3-1.1-0.5-1.5c0.3,0,0.7-0.1,1-0.3c0.2-0.1,0.8-0.3,1.4-0.6h-2.7V238h-2.1v-3h17.2v3h-2.2v-1.5h-12.9v0.6h12.8v1.3h-1.4c0.9,0.5,1.7,1.1,2.2,1.5L403,241h1.2v1.4h-5.9V243z M403.8,231.4v3h-13.1v-3H403.8z M401.5,232.6h-8.6v0.7h8.6V232.6z M396,238.4c-0.5,0.3-0.9,0.5-1.3,0.8l5.5-0.1c-0.4-0.2-0.8-0.4-1.1-0.6l0.1-0.1H396z M398.3,241h4.5c-0.2-0.2-0.6-0.5-0.9-0.7l-3.6,0.1V241z',
      'M418.6,239.3l0.4,2.1c-2.8,1.2-5.9,2.4-8.3,3.4l-1.1-2.4h0c1.4-0.4,3.3-1.1,5.3-1.8v-7.1h-4.6v-2.4h4.6v-4.4h2.5v13L418.6,239.3z M425.1,242.1c0.7,0,0.9-0.9,1-4.5c0.6,0.5,1.6,1,2.3,1.1c-0.2,4.1-0.8,5.7-3,5.7h-2.4c-2.2,0-2.9-0.8-2.9-3.6v-14.1h2.5v4.4h5.2v2.4h-5.2v7.4c0,1.1,0.1,1.3,0.8,1.3H425.1z'
    ],
    name: '臺北市',
  },
  {
    path: [
      'm441.1,370.94h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm402.5,319.34v1.8h-9.7v.8h7.6v1.7h-18v-1.7h7.4v-.8h-9.4v-1.8h9.4v-1.2h3v1.2h9.7Zm-9.7,19.5h9.5v2h-21.7v-2h9.3v-.8h-6.8v-1.7h6.8v-.7c-4.3.1-5,.2-5.6.3-.1-.4-.4-1.3-.6-1.8.4,0,.8-.1,1.3-.3.3-.1.9-.3,1.7-.7h-3.4v-.4h-2.6v-3.7h21.5v3.7h-2.8v-1.9h-16.1v.7h16v1.6h-1.8c1.1.6,2.1,1.4,2.8,1.9l-1.6,1.3h1.5v1.7h-7.3v.8h-.1Zm6.9-14.4v3.8h-16.4v-3.8h16.4Zm-2.9,1.4h-10.7v.9h10.7v-.9Zm-6.9,7.2c-.6.3-1.1.7-1.7.9l6.8-.1c-.5-.3-.9-.5-1.3-.7l.1-.1h-3.9Zm2.9,3.3h5.6c-.3-.3-.7-.6-1.1-.9l-4.5.1v.8Z',
      'm429.1,322.44v12.5h-3.1v-1.2h-5.6v7.8h-3.1v-7.8h-5.7v1.4h-3v-12.6h8.6v-4.3h3.1v4.3h8.8v-.1Zm-11.9,8.2v-5.4h-5.6v5.4h5.6Zm8.8,0v-5.4h-5.6v5.4h5.6Z'
    ],
    deskPath: [
      'M453.2,511.9h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C458.2,509.7,456,511.9,453.2,511.9z',
      'M406.5,453.8v1.5h-7.7v0.7h6.1v1.4h-14.5v-1.4h5.9v-0.7h-7.6v-1.5h7.6v-1h2.4v1H406.5z M398.8,469.4h7.6v1.6H389v-1.6h7.4v-0.6h-5.5v-1.4h5.5v-0.6c-3.4,0.1-4,0.1-4.5,0.3c-0.1-0.3-0.3-1.1-0.5-1.5c0.3,0,0.7-0.1,1-0.3c0.2-0.1,0.8-0.3,1.4-0.6h-2.7v-0.3h-2.1v-3h17.2v3h-2.2v-1.5h-12.9v0.6H404v1.3h-1.4c0.9,0.5,1.7,1.1,2.2,1.5l-1.3,1.1h1.2v1.4h-5.9V469.4z M404.3,457.9v3h-13.1v-3H404.3z M402,459h-8.6v0.7h8.6V459z M396.4,464.8c-0.5,0.3-0.9,0.5-1.3,0.8l5.5-0.1c-0.4-0.2-0.8-0.4-1.1-0.6l0.1-0.1H396.4z M398.8,467.4h4.5c-0.2-0.2-0.6-0.5-0.9-0.7l-3.6,0.1V467.4z',
      'M427.9,456.2v10h-2.5v-1h-4.5v6.2h-2.5v-6.2h-4.5v1.1h-2.4v-10.1h6.9v-3.5h2.5v3.5H427.9z M418.4,462.9v-4.3h-4.5v4.3H418.4z M425.4,462.9v-4.3h-4.5v4.3H425.4z'
    ],
    name: '臺中市',
  },
  {
    path: [
      'm441.1,465.34h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm397.5,427.74c-.2.4-.4.8-.7,1.1h5.5v2.2h-15.4c0,.4-.1.8-.1,1.1h4.6s0,.6-.1.9c-.2,2.5-.5,3.7-1,4.2-.4.4-.9.6-1.6.7-.6,0-1.5.1-2.6,0,0-.7-.3-1.5-.6-2.1.8.1,1.5.1,1.9.1.3,0,.5,0,.7-.2.1-.2.3-.6.4-1.5h-2.3c-.7,1.8-2.2,3.1-5.1,4-.2-.6-.8-1.5-1.3-1.9,1.9-.5,3-1.1,3.6-2h-2.6v-2.1h3.4c.1-.3.1-.7.2-1.1h-4v-2.2h5.8c-.1-.4-.4-.8-.6-1.1l1.9-.2h-4.6v-5h17.1v5h-4.4l1.9.1Zm5-11.4v2.1h-9.7v1h7.9v2h-18.5v-2h7.6v-1h-9.4v-2.1h9.4v-1.4h3v1.4h9.7Zm-16.9,9.3h11.5v-1.2h-11.5v1.2Zm2.9,1.8c.4.4.7,1,.8,1.4h4.5c.2-.5.4-1,.5-1.4h-5.8Zm3.9,4.4h8.9v6.3h-2.7v-.6h-3.5v.6h-2.6v-6.3h-.1Zm2.6,2v1.7h3.5v-1.7h-3.5Z',
      'm411.1,431.44c-.5,1.9-1.3,4.1-2.1,5.4-.5-.3-1.5-.9-2-1.2.8-1.2,1.5-3.1,1.9-4.8l2.2.6Zm14.3-2v6.6c0,1-.2,1.4-.9,1.7-.6.3-1.6.3-2.8.3-.1-.6-.4-1.3-.7-1.8h1.6c.2,0,.3-.1.3-.3v-6.4c-2.6.2-3.3.3-3.8.5-.1-.4-.4-1.6-.7-2.2v2.3h-4.1v7.7h-2.5v-7.7h-4.5v-2.3h1v-12.1h9.3v12.1h.8c.7-.1,1.4-.6,2.4-1.3.5-.3,1.6-1.2,2.8-2.3-3.2.2-3.9.3-4.4.5-.1-.5-.4-1.6-.7-2.1.5-.1.9-.3,1.5-.7s2.5-1.8,3.9-3.3c-1.7.6-3.5,1.2-5.1,1.6-.1-.6-.5-1.6-.9-2.1,2.9-.8,6-2.1,8-3.4l1.9,2.2c-1.1.6-2.4,1.2-3.7,1.6l2.1,1.1c-1,.9-2,1.8-3.1,2.5l2.8-.1c.6-.6,1.1-1.3,1.7-1.9l2,1.4c-2,2.2-4.2,4.2-6.5,5.9l3.9-.1c-.3-.6-.7-1.3-1-1.9l2-1c.9,1.5,2.1,3.6,2.7,4.8l-2.1,1.1c-.1-.3-.2-.6-.4-1l-2.8.1Zm-14.7-11.6v1.2h4.4v-1.2h-4.4Zm4.4,3h-4.4v1.2h4.4v-1.2Zm-4.4,4.1h4.4v-1h-4.4v1Zm0,2.9h4.4v-1.1h-4.4v1.1Zm5.9,3c.6,1.1,1.3,2.6,1.6,3.6l-2,.9c-.2-1-.9-2.5-1.5-3.7l1.9-.8Zm5.8.3c-.7,2-1.8,4.3-3,5.9-.5-.4-1.4-1-2-1.3,1.1-1.4,2.1-3.5,2.5-5.2l2.5.6Zm5.5-.8c.9,1.6,2,3.8,2.5,5.1l-2.3,1.1c-.5-1.3-1.5-3.6-2.4-5.3l2.2-.9Z'
    ],
    deskPath: [
      'M453.2,625.2h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C458.2,623,456,625.2,453.2,625.2z',
      'M402.2,576.5c-0.2,0.3-0.4,0.6-0.5,0.9h4.4v1.7h-12.4c0,0.3-0.1,0.6-0.1,0.9h3.7c0,0,0,0.5-0.1,0.7c-0.2,2-0.4,3-0.8,3.4c-0.3,0.4-0.8,0.5-1.3,0.5c-0.5,0-1.2,0.1-2.1,0c0-0.5-0.2-1.2-0.5-1.7c0.6,0.1,1.2,0.1,1.5,0.1c0.3,0,0.4,0,0.6-0.2c0.1-0.2,0.3-0.5,0.4-1.2h-1.9c-0.6,1.5-1.7,2.5-4.1,3.2c-0.2-0.5-0.7-1.2-1.1-1.6c1.5-0.4,2.4-0.9,2.9-1.6h-2.1V580h2.8c0.1-0.3,0.1-0.6,0.1-0.9h-3.2v-1.7h4.7c-0.1-0.3-0.3-0.7-0.5-0.9l1.5-0.2h-3.7v-4h13.7v4h-3.6L402.2,576.5zM406.2,567.3v1.7h-7.7v0.8h6.3v1.6h-14.8v-1.6h6.1V569h-7.5v-1.7h7.5v-1.1h2.4v1.1H406.2z M392.7,574.7h9.2v-1h-9.2V574.7zM395,576.2c0.3,0.3,0.5,0.8,0.6,1.1h3.6c0.2-0.4,0.3-0.8,0.4-1.1H395z M398.1,579.7h7.1v5.1H403v-0.5h-2.8v0.5h-2.1V579.7zM400.2,581.4v1.4h2.8v-1.4H400.2z',
      'M413.1,579.4c-0.4,1.5-1.1,3.3-1.7,4.4c-0.4-0.3-1.2-0.7-1.6-1c0.6-1,1.2-2.5,1.5-3.8L413.1,579.4zM424.6,577.8v5.3c0,0.8-0.2,1.1-0.7,1.4c-0.5,0.2-1.3,0.2-2.3,0.2c-0.1-0.5-0.3-1.1-0.5-1.5c0.5,0,1.1,0,1.3,0c0.2,0,0.3-0.1,0.3-0.2V578c-2.1,0.1-2.7,0.2-3,0.4c-0.1-0.4-0.4-1.3-0.6-1.8v1.9h-3.3v6.2h-2v-6.2H410v-1.9h0.8v-9.7h7.4v9.7h0.7v0c0.6-0.1,1.1-0.5,1.9-1.1c0.4-0.3,1.3-1,2.3-1.9c-2.6,0.2-3.1,0.3-3.5,0.4c-0.1-0.4-0.4-1.3-0.6-1.7c0.4-0.1,0.8-0.3,1.2-0.6c0.5-0.3,2-1.4,3.2-2.7c-1.4,0.5-2.8,0.9-4.1,1.3c-0.1-0.5-0.4-1.3-0.7-1.7c2.3-0.7,4.8-1.7,6.4-2.7l1.5,1.7c-0.9,0.5-1.9,0.9-3,1.3l1.7,0.9c-0.8,0.7-1.6,1.4-2.5,2l2.3-0.1c0.5-0.5,0.9-1,1.3-1.5l1.6,1.2c-1.6,1.7-3.4,3.4-5.2,4.7l3.1-0.1c-0.3-0.5-0.6-1.1-0.8-1.5l1.6-0.8c0.8,1.2,1.7,2.9,2.2,3.9l-1.7,0.9c-0.1-0.2-0.2-0.5-0.3-0.8L424.6,577.8z M412.8,568.5v1h3.5v-1H412.8z M416.3,570.9h-3.5v1h3.5V570.9z M412.8,574.2h3.5v-0.8h-3.5V574.2z M412.8,576.5h3.5v-0.9h-3.5V576.5z M417.5,578.9c0.5,0.9,1.1,2.1,1.3,2.9l-1.6,0.7c-0.2-0.8-0.7-2-1.2-3L417.5,578.9z M422.2,579.2c-0.6,1.6-1.4,3.5-2.4,4.7c-0.4-0.3-1.1-0.8-1.6-1c0.9-1.1,1.7-2.8,2-4.2L422.2,579.2z M426.5,578.5c0.7,1.3,1.6,3.1,2,4.1l-1.8,0.9c-0.4-1.1-1.2-2.9-1.9-4.3L426.5,578.5z'
    ],
    name: '嘉義縣',
  },
  {
    path: [
      'm441.1,276.44h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm402,231.34h-3.2v12c0,1.6-.3,2.5-1.3,2.9-1,.5-2.5.5-4.5.5-.1-.9-.5-2.2-.9-3.1,1.3.1,2.7.1,3.2.1.4,0,.6-.1.6-.5v-11.9h-2.8c-.7,1.6-1.5,3-2.3,4.2-.5-.5-1.8-1.4-2.5-1.8.5-.7,1.1-1.5,1.6-2.4h-3v15.5h-3v-15.5h-.2c-.7,1.6-1.5,3.1-2.3,4.3-.6-.5-2-1.4-2.7-1.8,1.9-2.4,3.5-6.3,4.3-10.2l3.1.6c-.3,1.4-.7,2.8-1.2,4.2h5.7v1.8c.9-2,1.6-4.3,2.1-6.6l3,.6c-.3,1.4-.7,2.9-1.2,4.2h7.9v2.9h-.4Z',
      'm410,240.14c-.5,1.9-1.3,4.1-2.1,5.4-.5-.3-1.5-.9-2-1.2.8-1.2,1.5-3.1,1.9-4.8l2.2.6Zm14.4-2v6.6c0,1-.2,1.4-.9,1.7-.6.3-1.6.3-2.8.3-.1-.6-.4-1.3-.7-1.8h1.6c.2,0,.3-.1.3-.3v-6.4c-2.6.2-3.3.3-3.8.5-.1-.4-.4-1.6-.7-2.2v2.3h-4.1v7.7h-2.5v-7.7h-4.5v-2.3h1v-12.1h9.3v12.1h.8c.7-.1,1.4-.6,2.4-1.3.5-.3,1.6-1.2,2.8-2.3-3.2.2-3.9.3-4.4.5-.1-.5-.4-1.6-.7-2.1.5-.1.9-.3,1.5-.7s2.5-1.8,3.9-3.3c-1.7.6-3.5,1.2-5.1,1.6-.1-.6-.5-1.6-.9-2.1,2.9-.8,6-2.1,8-3.4l1.9,2.2c-1.1.6-2.4,1.2-3.7,1.6l2.1,1.1c-1,.9-2,1.8-3.1,2.5l2.8-.1c.6-.6,1.1-1.3,1.7-1.9l2,1.4c-2,2.2-4.2,4.2-6.5,5.9l3.9-.1c-.3-.6-.7-1.3-1-1.9l2-1c.9,1.5,2.1,3.6,2.7,4.8l-2.1,1.1c-.1-.3-.2-.6-.4-1l-2.8.1Zm-14.8-11.6v1.2h4.4v-1.2h-4.4Zm4.4,3h-4.4v1.2h4.4v-1.2Zm-4.4,4h4.4v-1h-4.4v1Zm0,3h4.4v-1.1h-4.4v1.1Zm6,2.9c.6,1.1,1.3,2.6,1.6,3.6l-2,.9c-.2-1-.9-2.5-1.5-3.7l1.9-.8Zm5.8.4c-.7,2-1.8,4.3-3,5.9-.5-.4-1.4-1-2-1.3,1.1-1.4,2.1-3.5,2.5-5.2l2.5.6Zm5.4-.9c.9,1.6,2,3.8,2.5,5.1l-2.3,1.1c-.5-1.3-1.5-3.6-2.4-5.3l2.2-.9Z'
    ],
    deskPath: [
      'M453.2,398.7h-89.7c-2.8,0-5-2.2-5-5V304c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C458.2,396.4,456,398.7,453.2,398.7z',
      'M406.8,345.9h-2.6v9.6c0,1.3-0.2,2-1.1,2.4c-0.8,0.4-2,0.4-3.6,0.4c-0.1-0.7-0.4-1.8-0.8-2.5c1,0.1,2.2,0.1,2.5,0.1c0.3,0,0.5-0.1,0.5-0.4v-9.5h-2.2c-0.6,1.3-1.2,2.4-1.9,3.3c-0.4-0.4-1.5-1.1-2-1.4c0.4-0.5,0.9-1.2,1.3-1.9h-2.4v12.4h-2.4v-12.4h-0.2c-0.6,1.3-1.2,2.5-1.9,3.4c-0.5-0.4-1.6-1.1-2.2-1.5c1.6-1.9,2.8-5.1,3.4-8.2l2.5,0.5c-0.3,1.1-0.6,2.3-1,3.4h4.5v1.4c0.7-1.6,1.3-3.4,1.7-5.3l2.4,0.5c-0.3,1.1-0.6,2.3-1,3.4h6.3V345.9z',
      'M413.2,352.9c-0.4,1.5-1.1,3.3-1.7,4.4c-0.4-0.3-1.2-0.7-1.6-1c0.6-1,1.2-2.5,1.5-3.8L413.2,352.9zM424.7,351.3v5.3c0,0.8-0.2,1.1-0.7,1.4c-0.5,0.2-1.3,0.2-2.3,0.2c-0.1-0.5-0.3-1.1-0.5-1.5c0.5,0,1.1,0,1.3,0c0.2,0,0.3-0.1,0.3-0.2v-5.1c-2.1,0.1-2.7,0.2-3,0.4c-0.1-0.4-0.4-1.3-0.6-1.8v1.9h-3.3v6.2h-2v-6.2h-3.6V350h0.8v-9.7h7.4v9.7h0.7v0c0.6-0.1,1.1-0.5,1.9-1.1c0.4-0.3,1.3-1,2.3-1.9c-2.6,0.2-3.1,0.3-3.5,0.4c-0.1-0.4-0.4-1.3-0.6-1.7c0.4-0.1,0.8-0.3,1.2-0.6c0.5-0.3,2-1.4,3.2-2.7c-1.4,0.5-2.8,0.9-4.1,1.3c-0.1-0.5-0.4-1.3-0.7-1.7c2.3-0.7,4.8-1.7,6.4-2.7l1.5,1.7c-0.9,0.5-1.9,0.9-3,1.3l1.7,0.9c-0.8,0.7-1.6,1.4-2.5,2l2.3-0.1c0.5-0.5,0.9-1,1.3-1.5l1.6,1.2c-1.6,1.7-3.4,3.4-5.2,4.7l3.1-0.1c-0.3-0.5-0.6-1.1-0.8-1.5l1.6-0.8c0.8,1.2,1.7,2.9,2.2,3.9l-1.7,0.9c-0.1-0.2-0.2-0.5-0.3-0.8L424.7,351.3z M412.9,342v1h3.5v-1H412.9z M416.4,344.4h-3.5v1h3.5V344.4z M412.9,347.7h3.5v-0.8h-3.5V347.7z M412.9,350h3.5v-0.9h-3.5V350z M417.7,352.4c0.5,0.9,1.1,2.1,1.3,2.9l-1.6,0.7c-0.2-0.8-0.7-2-1.2-3L417.7,352.4z M422.3,352.6c-0.6,1.6-1.4,3.5-2.4,4.7c-0.4-0.3-1.1-0.8-1.6-1c0.9-1.1,1.7-2.8,2-4.2L422.3,352.6z M426.7,352c0.7,1.3,1.6,3.1,2,4.1l-1.8,0.9c-0.4-1.1-1.2-2.9-1.9-4.3L426.7,352z'
    ],
    name: '新竹縣',
  },
  {
    path: [
      'm562.2,170.74h-73.1c-2.8,0-5-2.2-5-5v-73.2c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5.1-5,5.1Z',
      'm504.9,135.84c-.6,1.8-1.6,3.8-2.5,5.1-.4-.4-1.4-1-1.9-1.3.8-1.2,1.7-2.9,2.2-4.4l2.2.6Zm3.5-1.4v8.3h-2.9v-8.3h-4.6v-2.5h4.6v-1.9h-4.8v-2.5h2.4c-.1-.7-.4-1.8-.7-2.6l2-.5h-3.4v-2.4h4.3c-.2-.7-.5-1.4-.7-2l3-.7c.4.8.8,1.8,1.1,2.7h3.6v2.4h-3.1l2,.4c-.4.9-.7,1.9-1.1,2.7h2.6v2.4h-4.3v1.9h3.9v2.5h-3.9v.1Zm-3.6-10c.4.8.7,1.9.8,2.6l-1.8.5h3.6c.4-.9.8-2.2,1.1-3.1h-3.7Zm19,6.1h-2.3v12.3h-2.9v-12.3h-2.3v.3c0,3.7-.3,8.8-2.4,12-.5-.5-1.7-1.5-2.3-1.8.5-.7.8-1.5,1.1-2.4l-1.8,1.1c-.4-1-1.2-2.6-1.9-3.7l1.9-1c.7,1,1.4,2.3,1.9,3.3.7-2.4.8-5.1.8-7.4v-8.9c2.7-.5,5.7-1.3,7.6-2.1l2.3,2.2c-2,.8-4.6,1.4-7.1,1.9v3.9h7.5v2.6h-.1Z',
      'm538.8,135.74l.5,2.6c-3.5,1.5-7.4,3-10.3,4.2l-1.3-3h0c1.7-.5,4.1-1.3,6.6-2.2v-8.9h-5.7v-3h5.8v-5.5h3.1v16.2l1.3-.4Zm8.2,3.5c.9,0,1.1-1.2,1.2-5.6.7.6,2,1.2,2.8,1.4-.3,5.2-1,7.1-3.8,7.1h-3c-2.7,0-3.6-1-3.6-4.5v-17.6h3.1v5.4h6.5v2.9h-6.5v9.3c0,1.3.1,1.6.9,1.6h2.4Z'
    ],
    deskPath: [
      'M572.3,284.5h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C577.3,282.3,575.1,284.5,572.3,284.5z',
      'M510.8,238.5c-0.5,1.4-1.3,3.1-2,4.1c-0.4-0.3-1.1-0.8-1.6-1.1c0.7-0.9,1.3-2.3,1.8-3.5L510.8,238.5zM513.6,237.4v6.6h-2.3v-6.6h-3.7v-2h3.7v-1.5h-3.8v-2h1.9c-0.1-0.6-0.3-1.5-0.6-2.1l1.6-0.4h-2.7v-1.9h3.4c-0.2-0.5-0.4-1.1-0.6-1.6l2.4-0.6c0.3,0.7,0.7,1.5,0.9,2.2h2.9v1.9h-2.5l1.6,0.3c-0.3,0.8-0.6,1.5-0.9,2.2h2.1v2h-3.4v1.5h3.1v2H513.6z M510.7,229.4c0.3,0.7,0.6,1.5,0.7,2.1l-1.4,0.4h2.9c0.3-0.7,0.7-1.7,0.9-2.5H510.7z M525.9,234.3H524v9.8h-2.3v-9.8h-1.9v0.2c0,2.9-0.3,7.1-2,9.6c-0.4-0.4-1.3-1.2-1.8-1.4c0.4-0.6,0.7-1.2,0.9-1.9l-1.4,0.9c-0.3-0.8-1-2.1-1.6-3l1.6-0.8c0.5,0.8,1.2,1.9,1.5,2.6c0.6-1.9,0.6-4.1,0.6-5.9v-7.1c2.2-0.4,4.6-1,6.1-1.7l1.8,1.7c-1.6,0.7-3.7,1.1-5.7,1.5v3.1h6V234.3z',
      'M538,238.4l0.4,2.1c-2.8,1.2-5.9,2.4-8.3,3.4l-1.1-2.4h0c1.4-0.4,3.3-1.1,5.3-1.8v-7.1h-4.6v-2.4h4.6v-4.4h2.5v13L538,238.4z M544.5,241.2c0.7,0,0.9-0.9,1-4.5c0.6,0.5,1.6,1,2.3,1.1c-0.2,4.1-0.8,5.7-3,5.7h-2.4c-2.2,0-2.9-0.8-2.9-3.6v-14.1h2.5v4.4h5.2v2.4H542v7.4c0,1.1,0.1,1.3,0.8,1.3H544.5z'
    ],
    name: '新北市',
  },
  {
    path: [
      'm562.2,361.34h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm513.5,313.24c-.1.7-.2,1.3-.3,1.9h9.2v13.6c0,1.4-.3,2.1-1.4,2.5-1,.4-2.5.4-4.5.4-.1-.7-.6-1.8-.9-2.5,1.2.1,2.8.1,3.1,0,.4,0,.6-.1.6-.5v-10.8h-4.8l2.5.5c-.3.9-.7,1.8-1,2.6h1.8v2.3h-4.2v1.6h4.8v2.4h-4.8v3.7h-2.8v-3.7h-4.6v-2.4h4.6v-1.6h-4.2v-2.3h1.9c-.2-.7-.6-1.7-1-2.5l2.3-.6c.4.8.9,2,1.1,2.7l-1.3.4h3.6c.4-1,.7-2.2.9-3.1h-9.2v13.8h-3v-16.5h8.1c.1-.6.2-1.2.3-1.9h-9.3v-2.7h9.6c0-.7.1-1.4.1-2.1l3.3.3c-.1.6-.2,1.2-.2,1.8h9.3v2.8h-9.6v-.1Z',
      'm534.1,322.44v5.9c0,1.4-.3,2.2-1.1,2.6-.8.5-2,.5-3.8.5-.1-.8-.5-1.9-.8-2.7h2.3c.3,0,.5-.1.5-.5v-5.2l-2.5.6-.8-2.8c.9-.2,2.1-.4,3.3-.7v-4.4h-3v-2.7h3v-4.7h2.9v4.7h2.6v2.7h-2.6v3.7l1.8-.4.4,2.7-2.2.7Zm15.7-2.5c-.9,3-2.3,5.4-4.3,7.2,1.7.8,3.6,1.5,5.8,1.9-.6.7-1.4,1.9-1.9,2.7-2.5-.6-4.6-1.5-6.4-2.7-2,1.2-4.3,2.1-6.8,2.7-.3-.8-.9-2-1.4-2.7,2.1-.4,4.1-1,5.8-1.9-1.4-1.4-2.5-3-3.4-4.8l.9-.3h-1.5v-2.8h10.8l.5-.1,1.9.8Zm-12.4-1c-.3-.5-1.4-1.7-2-2.1,2.9-1.3,3.3-3.1,3.3-4.9v-2.7h8.8v5.4c0,.7,0,1,.4,1h1.3c.4,0,1-.1,1.4-.2.1.7.1,1.8.2,2.5-.4.1-1,.2-1.6.2h-1.7c-2.4,0-3-1-3-3.5v-2.6h-3.2c.2,2.5-.6,5.2-3.9,6.9Zm2.6,3.1c.7,1.3,1.8,2.5,3.1,3.5,1.2-.9,2.1-2.1,2.9-3.5h-6Z'
    ],
    deskPath: [
      'M572.3,510.9h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C577.3,508.7,575.1,510.9,572.3,510.9z',
      'M517.2,455.6c-0.1,0.5-0.2,1-0.3,1.5h7.3V468c0,1.2-0.3,1.7-1.1,2c-0.8,0.3-2,0.3-3.6,0.3c-0.1-0.6-0.5-1.5-0.8-2c1,0.1,2.2,0.1,2.5,0c0.4,0,0.5-0.1,0.5-0.4v-8.7H518l2,0.4c-0.3,0.7-0.6,1.5-0.8,2.1h1.5v1.8h-3.4v1.3h3.8v1.9h-3.8v3H515v-3h-3.7v-1.9h3.7v-1.3h-3.3v-1.8h1.5c-0.2-0.6-0.5-1.4-0.8-2l1.9-0.5c0.4,0.7,0.7,1.6,0.9,2.2l-1.1,0.3h2.9c0.3-0.8,0.6-1.8,0.8-2.5h-7.4v11.1h-2.4v-13.2h6.5c0.1-0.5,0.2-1,0.2-1.5h-7.5v-2.2h7.7c0-0.6,0.1-1.2,0.1-1.7l2.6,0.2c-0.1,0.5-0.1,1-0.2,1.4h7.4v2.2H517.2z',
      'M533.7,463v4.8c0,1.2-0.2,1.7-0.9,2.1c-0.7,0.4-1.6,0.4-3,0.4c-0.1-0.6-0.4-1.6-0.7-2.2c0.8,0,1.6,0,1.9,0c0.3,0,0.4-0.1,0.4-0.4v-4.1l-2,0.5l-0.6-2.3c0.8-0.2,1.7-0.4,2.6-0.6v-3.5H529v-2.2h2.4v-3.8h2.3v3.8h2.1v2.2h-2.1v2.9l1.5-0.4l0.3,2.2L533.7,463z M546.4,460.9c-0.7,2.4-1.9,4.3-3.4,5.8c1.3,0.7,2.9,1.2,4.6,1.5c-0.5,0.5-1.2,1.5-1.5,2.2c-2-0.5-3.7-1.2-5.2-2.2c-1.6,1-3.4,1.7-5.4,2.1c-0.2-0.7-0.8-1.6-1.2-2.2c1.7-0.3,3.3-0.8,4.6-1.5c-1.1-1.1-2-2.4-2.8-3.9l0.7-0.3h-1.2v-2.2h8.7l0.4-0.1L546.4,460.9z M536.4,460.1c-0.3-0.4-1.1-1.4-1.6-1.7c2.4-1.1,2.7-2.5,2.7-3.9v-2.1h7.1v4.4c0,0.6,0,0.8,0.3,0.8h1.1c0.3,0,0.8-0.1,1.1-0.1c0,0.6,0.1,1.4,0.2,2c-0.3,0.1-0.8,0.1-1.3,0.1h-1.3c-2,0-2.4-0.8-2.4-2.8v-2.1l-2.6,0C539.7,456.6,539.1,458.8,536.4,460.1z M538.5,462.6c0.6,1.1,1.4,2,2.5,2.8c0.9-0.8,1.7-1.7,2.3-2.8H538.5z'
    ],
    name: '南投縣',
  },
  {
    path: [
      'm562.2,456.64h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm509.6,408.34h2.2v2.8h-2.2v2.1h-2.7l2.7.8c-.5,1-1.1,2.1-1.8,3.1v12.3h-2.9v-8.5c-.7.7-1.4,1.4-2.1,2-.5-.6-1.7-1.6-2.4-2.1,2.6-1.8,5-4.7,6.4-7.7v-2h-5.6v-2.8h5.6v-2.2h2.9v2.2h-.1Zm10.2,18c1,0,1.2-.6,1.3-3.7.6.5,1.9,1,2.7,1.2-.3,4-1.1,5.2-3.7,5.2h-3.8c-3,0-3.8-.9-3.8-4v-11.5h2.9v3.6h7.7v2.8h-7.7v5.2c0,1.1.1,1.2,1.2,1.2h3.2Zm-5-15.2h-2.2v-2.8h2.3v-2.2h3v2.2h5.4v2.8h-5.4v2.1h-3v-2.1h-.1Z',
      'm535,419.64c-.5,1.7-1.2,3.5-2.1,4.9.4.3.9.6,1.5,1,1.7,1,4,1.2,6.9,1.2s7.4-.2,10.2-.6c-.3.7-.8,2.1-.8,2.9-2.1.1-6.5.3-9.4.3-3.3,0-5.4-.2-7.2-1.3-1-.6-1.9-1.4-2.4-1.4-.6,0-1.5,1.6-2.2,3.1l-1.8-2.6c.8-.9,1.6-1.7,2.4-2.2.7-.9,1.3-2.1,1.8-3.4h-3c.6-1.1,1.4-2.7,2.1-4.1h-2.4v-2.4h6.2c-.6,1.4-1.4,2.9-2.1,4.3h.6l.4-.1,1.3.4Zm-2-9.8h-4.5v-2.4h4.5v-1.2h2.9v1.2h3.2v2.4h-3.2v1.3h-2.9v-1.3Zm-1,.9c.6.9,1.3,2.1,1.7,2.8l-2.4,1.1c-.4-.8-1.1-2-1.6-3l2.3-.9Zm9.1,15.5v-1.2h-6.4v-2.1h6.4v-.9h-5.2v-7.1h5.2v-.9h-6v-2h6v-1.1h2.7v1.1h6.3v2h-6.3v.9h5.5v7.1h-5.5v.9h6.6v2.1h-6.6v1.2h-2.7Zm-2.5-8.6h2.5v-1h-2.5v1Zm2.5,2.6v-1h-2.5v1h2.5Zm2.3-10.4h-3.4v-2.4h3.4v-1.2h2.9v1.2h4.3v2.4h-4.3v.9h-2.9v-.9Zm.5,6.8v1h2.6v-1h-2.6Zm2.6,2.6h-2.6v1h2.6v-1Z'
    ],
    deskPath: [
      'M572.3,624.1h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C577.3,621.8,575.1,624.1,572.3,624.1z',
      'M514.4,566.5h1.7v2.2h-1.7v1.7h-2.2l2.2,0.7c-0.4,0.8-0.9,1.7-1.5,2.5v9.8h-2.4v-6.8c-0.6,0.6-1.1,1.1-1.7,1.6c-0.4-0.5-1.4-1.3-2-1.7c2.1-1.4,4-3.8,5.1-6.1v-1.6h-4.5v-2.2h4.5v-1.8h2.4V566.5z M522.6,581c0.8,0,0.9-0.5,1.1-2.9c0.5,0.4,1.5,0.8,2.1,0.9c-0.3,3.2-0.9,4.2-3,4.2h-3c-2.4,0-3-0.7-3-3.2v-9.2h2.4v2.9h6.2v2.2h-6.2v4.1c0,0.9,0.1,1,1,1H522.6z M518.6,568.8h-1.8v-2.2h1.8v-1.8h2.4v1.8h4.4v2.2H521v1.7h-2.4V568.8z',
      'M534.8,575.6c-0.4,1.4-1,2.8-1.7,4c0.3,0.2,0.7,0.5,1.2,0.8c1.3,0.8,3.2,0.9,5.6,0.9c2.4,0,5.9-0.2,8.1-0.5c-0.3,0.6-0.6,1.7-0.7,2.3c-1.7,0.1-5.2,0.2-7.5,0.2c-2.7,0-4.3-0.2-5.8-1c-0.8-0.5-1.5-1.1-1.9-1.1c-0.5,0-1.2,1.3-1.7,2.5l-1.5-2.1c0.6-0.8,1.3-1.4,1.9-1.8c0.6-0.7,1.1-1.7,1.4-2.7h-2.4c0.5-0.9,1.1-2.1,1.7-3.3h-2v-1.9h5c-0.5,1.1-1.1,2.3-1.7,3.4h0.5l0.3-0.1L534.8,575.6z M533.2,567.7h-3.6v-2h3.6v-1h2.4v1h2.6v2h-2.6v1h-2.4V567.7z M532.4,568.5c0.5,0.7,1.1,1.7,1.4,2.2l-1.9,0.9c-0.3-0.6-0.9-1.6-1.3-2.4L532.4,568.5z M539.7,580.9v-1h-5.1v-1.7h5.1v-0.8h-4.2v-5.7h4.2v-0.7h-4.8v-1.6h4.8v-0.9h2.2v0.9h5v1.6h-5v0.7h4.4v5.7h-4.4v0.8h5.3v1.7h-5.3v1L539.7,580.9z M537.7,574h2v-0.8h-2V574zM539.7,576.1v-0.8h-2v0.8H539.7z M541.6,567.7h-2.7v-2h2.7v-1h2.4v1h3.5v2h-3.5v0.7h-2.4V567.7z M541.9,573.2v0.8h2.1v-0.8H541.9z M544,575.3h-2.1v0.8h2.1V575.3z'
    ],
    name: '花蓮縣',
  },
  {
    path: [
      'm562.2,266.04h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm522.2,233.04v2.7h-22.2v-2.7h4.6v-12.9h12.8v12.9h4.8Zm-18.8-11.4h-2.9v-5.9h9.4c-.2-.7-.5-1.5-.8-2.1l2.9-.7c.5.9,1,2,1.2,2.8h8.7v5.9h-3v-3.2h-15.4v3.2h-.1Zm4.1,1.1v1.7h6.9v-1.7h-6.9Zm6.9,4.1h-6.9v1.8h6.9v-1.8Zm-6.9,6.2h6.9v-1.9h-6.9v1.9Z',
      'm532,216.64h-4.5v-2.4h4.5v-1.1h2.9v1.1h3.2v2.4h-3.2v1h-2.9v-1Zm16.7,16.8c0,1.2-.2,1.8-1,2.3-.7.4-1.8.5-3.2.5-.1-.6-.4-1.5-.7-2.2l-1,1.3c-.6-.6-2.1-1.7-3.1-2.5v3h-2.3v-2.9c-1.1,1-2.6,2-3.9,2.5-.3-.5-1-1.3-1.5-1.7,1.4-.4,2.9-1,4-1.8h-2.7v-4.9h4.3v-.6h-4.9v-1.5h4.9v-.8h-6.1v12.2h-2.8v-18.3h9.2v5.9h1.6v-5.9h9.4v15.4h-.2Zm-17.4-13.7v.7h3.7v-.7h-3.7Zm3.7,2.8v-.7h-3.7v.7h3.7Zm0,8h1.2c-.2-.4-.5-1-.8-1.4l1-.4c.3.4.7,1,.9,1.3l-1,.5h1.4v-1.9h-2.6v1.9h-.1Zm14.7-16.4v2.4h-4.3v1.1h-2.9v-1.1h-3.4v-2.4h3.4v-1.1h2.9v1.1h4.3Zm-8,15c-.4.6-.7,1-1,1.4h1.3v-1.9h-2.7v1.9h1.2l-.8-.4c.3-.4.6-1.1.8-1.5l1.2.5Zm3.8,4.7c.3,0,.4-.1.4-.4v-9.2h-6.2v.7h5.1v1.5h-5.1v.6h4.4v4.9h-3c1.1.6,2.5,1.5,3,1.8.6.1,1.2.1,1.4.1Zm-3.5-14.1v.7h3.9v-.7h-3.9Zm0,2.8h3.9v-.7h-3.9v.7Z'
    ],
    deskPath: [
      'M572.3,397.7h-89.7c-2.8,0-5-2.2-5-5V303c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C577.3,395.5,575.1,397.7,572.3,397.7z',
      'M525.4,354.6v2.2h-17.8v-2.2h3.7v-10.3h10.2v10.3H525.4z M510.3,345.4h-2.3v-4.7h7.5c-0.2-0.6-0.4-1.2-0.7-1.7l2.3-0.5c0.4,0.7,0.8,1.6,1,2.3h7v4.7h-2.4v-2.6h-12.3V345.4z M513.6,346.3v1.4h5.5v-1.4H513.6zM519.1,349.7h-5.5v1.5h5.5V349.7z M513.6,354.6h5.5v-1.5h-5.5V354.6z',
      'M533.2,341.5h-3.6v-2h3.6v-0.9h2.4v0.9h2.6v2h-2.6v0.8h-2.4V341.5z M546.6,355c0,1-0.2,1.5-0.8,1.8c-0.6,0.3-1.4,0.4-2.5,0.4c-0.1-0.5-0.3-1.2-0.6-1.7l-0.8,1.1c-0.5-0.5-1.7-1.4-2.5-2v2.4h-1.8v-2.3c-0.9,0.8-2.1,1.6-3.2,2c-0.3-0.4-0.8-1.1-1.2-1.4c1.1-0.3,2.3-0.8,3.2-1.4h-2.2v-3.9h3.4v-0.5h-3.9v-1.2h3.9v-0.6h-4.9v9.8h-2.2v-14.7h7.3v4.7h1.3v-4.7h7.5V355z M532.6,344v0.5h3V344H532.6z M535.6,346.2v-0.6h-3v0.6H535.6z M535.6,352.6h1c-0.2-0.3-0.4-0.8-0.7-1.1l0.8-0.3c0.3,0.3,0.6,0.8,0.7,1.1l-0.8,0.4h1.1v-1.5h-2.1V352.6z M547.4,339.5v2h-3.5v0.9h-2.4v-0.9h-2.7v-2h2.7v-0.9h2.4v0.9H547.4zM541,351.5c-0.3,0.5-0.6,0.8-0.8,1.1h1.1v-1.5h-2.1v1.5h0.9l-0.7-0.3c0.2-0.4,0.5-0.9,0.7-1.2L541,351.5z M544,355.3c0.2,0,0.3-0.1,0.3-0.3v-7.4h-5v0.6h4.1v1.2h-4.1v0.5h3.5v3.9h-2.4c0.9,0.5,2,1.2,2.4,1.4C543.4,355.3,543.9,355.3,544,355.3zM541.2,344v0.6h3.1V344H541.2z M541.2,346.2h3.1v-0.5h-3.1V346.2z'
    ],
    name: '宜蘭縣',
  },
  {
    path: [
      'm441.1,559.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm401.4,508.44v1.8h-9.7v.8h7.6v1.7h-18v-1.7h7.4v-.8h-9.4v-1.8h9.4v-1.2h3v1.2h9.7Zm-9.6,19.5h9.5v2h-21.8v-2h9.3v-.8h-6.8v-1.7h6.8v-.7c-4.3.1-5,.2-5.6.3-.1-.4-.4-1.3-.6-1.8.4,0,.8-.2,1.3-.3.3-.1.9-.3,1.7-.7h-3.4v-.4h-2.6v-3.7h21.5v3.7h-2.8v-1.9h-16.1v.7h16v1.6h-1.8c1.1.6,2.1,1.4,2.8,1.9l-1.6,1.3h1.5v1.7h-7.3v.8Zm6.8-14.4v3.8h-16.4v-3.8h16.4Zm-2.9,1.4h-10.7v.9h10.7v-.9Zm-6.9,7.2c-.6.3-1.1.7-1.7.9l6.8-.1c-.5-.3-.9-.5-1.3-.7l.1-.1h-3.9Zm3,3.3h5.6c-.3-.3-.7-.6-1.1-.9l-4.5.1v.8Z',
      'm421.5,522.94c2.1,1.9,5.2,3.7,8.1,4.7-.7.6-1.6,1.8-2.1,2.6-2.9-1.2-5.9-3.4-8.2-5.8v6.1h-3.1v-6c-2.2,2.4-5.2,4.4-8,5.6-.5-.7-1.4-1.9-2-2.5,2.8-1,5.8-2.7,7.9-4.7h-5v-9.5h7.3v-1.4h-9.4v-2.8h9.4v-2h3.1v2h9.6v2.8h-9.6v1.4h7.6v9.5h-5.6Zm-9.7-5.8h4.4v-1.5h-4.4v1.5Zm0,3.6h4.4v-1.5h-4.4v1.5Zm7.5-5.1v1.5h4.6v-1.5h-4.6Zm4.6,3.6h-4.6v1.5h4.6v-1.5Z'
    ],
    deskPath: [
      'M453.2,735.6h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5h89.7c2.8,0,5,2.2,5,5v89.7C458.2,733.4,456,735.6,453.2,735.6z',
      'M405.9,677.4v1.5h-7.7v0.7h6.1v1.4h-14.5v-1.4h5.9v-0.7h-7.6v-1.5h7.6v-1h2.4v1H405.9z M398.2,693.1h7.6v1.6h-17.4v-1.6h7.4v-0.6h-5.5v-1.4h5.5v-0.6c-3.4,0.1-4,0.1-4.5,0.3c-0.1-0.3-0.3-1.1-0.5-1.5c0.3,0,0.7-0.1,1-0.3c0.2-0.1,0.8-0.3,1.4-0.6h-2.7v-0.3h-2.1v-3h17.2v3h-2.2v-1.5h-12.9v0.6h12.8v1.3H402c0.9,0.5,1.7,1.1,2.2,1.5l-1.3,1.1h1.2v1.4h-5.9V693.1z M403.7,681.5v3h-13.1v-3H403.7z M401.4,682.7h-8.6v0.7h8.6V682.7z M395.8,688.4c-0.5,0.3-0.9,0.5-1.3,0.8l5.5-0.1c-0.4-0.2-0.8-0.4-1.1-0.6l0.1-0.1H395.8z M398.2,691.1h4.5c-0.2-0.2-0.6-0.5-0.9-0.7l-3.6,0.1V691.1z',
      'M422,689.1c1.7,1.6,4.1,3,6.5,3.8c-0.6,0.5-1.3,1.4-1.7,2.1c-2.3-1-4.7-2.7-6.6-4.7v4.9h-2.5v-4.8c-1.8,1.9-4.1,3.5-6.4,4.5c-0.4-0.6-1.1-1.5-1.6-2c2.3-0.8,4.6-2.2,6.3-3.8h-4v-7.6h5.8v-1.1h-7.5v-2.2h7.5v-1.6h2.5v1.6h7.7v2.2h-7.7v1.1h6.1v7.6H422z M414.3,684.4h3.5v-1.2h-3.5V684.4z M414.3,687.3h3.5v-1.2h-3.5V687.3z M420.2,683.2v1.2h3.7v-1.2H420.2zM423.9,686.1h-3.7v1.2h3.7V686.1z'
    ],
    name: '臺東縣',
  },
  {
    path: [
      'm322.9,183.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm267,146.84c-.2-.6-.7-1.5-1.1-2.5v11.7h-2.8v-8.7c-.5,1.7-1.2,3.2-1.8,4.3-.2-.9-.9-2.2-1.4-3,1.4-1.9,2.5-5.5,3.1-8.4h-2.7v-2.7h2.8v-4.6h2.8v4.6h2v2.7h-2v.5c.7.9,2.5,3.4,2.9,4.1l-1.8,2Zm5.1-14.1h2.7v10.5c0,4.8-.8,10-5.5,12.9-.4-.6-1.3-1.7-1.9-2.1,2.8-1.4,3.9-3.7,4.4-6.2-.9.9-1.8,1.7-2.7,2.5l-1.4-2.8c1.1-.6,2.8-1.8,4.4-2.9v-2.7l-1.9.8c-.3-1.4-1.1-3.7-1.8-5.4l2.2-.9c.6,1.2,1.1,2.6,1.5,3.8v-7.5h0Zm8.7,20.4c.4,0,.5-.7.6-2.9.6.5,1.7,1,2.5,1.2-.3,3.1-.9,4.4-2.8,4.4h-2c-2.1,0-2.7-.9-2.7-3.9v-19.1h2.8v7.9c.6-1.4,1.3-3.2,1.7-4.5l2.7,1.1c-.9,1.8-1.9,3.9-2.7,5.2l-1.7-.8v2.5l.6-.6c1.3,1.4,3.2,3.3,4,4.5l-1.9,1.8c-.6-.9-1.6-2.2-2.7-3.4v5.5c0,1.1,0,1.3.5,1.3h1.1v-.2Z',
      'm309.9,133.74v22.4h-2.9v-.9h-15.6v.9h-2.8v-22.4h21.3Zm-2.9,18.8v-16.2h-15.6v16.2h15.6Zm-6.7-3.4c-.1.5,0,1.4,0,1.8-4.2,1-5,1.3-5.4,1.5-.1-.4-.4-1.2-.7-1.5.4-.2.8-.4.8-1.4v-.9c-.7.2-1.3.5-2,.7-.3-.4-1-1.2-1.4-1.6,1.6-.3,3.3-.8,4.7-1.5h-2.8v-3.6h11.5v3.6h-5.4c-.7.6-1.6,1.1-2.5,1.5v2.1l3.2-.7Zm5.8-7.2h-13.9v-1.6h5.6v-1h-4.4v-1.6h4.4v-1h2.7v1h4.6v1.6h-4.6v1h5.6v1.6h0Zm-10.1,3h6.4v-1h-6.4v1Zm10.4,2.7c-.6.5-1.4.9-2.1,1.3.9.6,1.6,1.2,2.2,1.7l-1.9,1.1c-1.2-1.2-3.9-3.1-6-4.3l1.8-1c.7.4,1.5.9,2.3,1.5.7-.5,1.4-1,1.9-1.4l1.8,1.1Z'
    ],
    deskPath: [
      'M334,284.5h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H334c2.8,0,5,2.2,5,5v89.7C339,282.3,336.8,284.5,334,284.5z',
      'M274.8,236.6c-0.2-0.5-0.5-1.2-0.9-2v9.3h-2.2v-7c-0.4,1.3-0.9,2.6-1.5,3.4c-0.2-0.7-0.7-1.8-1.1-2.4c1.1-1.6,2-4.4,2.5-6.8h-2.2V229h2.3v-3.7h2.2v3.7h1.6v2.2h-1.6v0.4c0.6,0.7,2,2.7,2.3,3.3L274.8,236.6z M279,225.3h2.2v8.4c0,3.8-0.7,8-4.4,10.3c-0.3-0.5-1.1-1.3-1.5-1.7c2.3-1.1,3.2-3,3.5-5c-0.7,0.7-1.5,1.4-2.2,2l-1.1-2.2c0.9-0.5,2.2-1.4,3.5-2.4c0-0.3,0-0.7,0-1v-1.1l-1.5,0.6c-0.2-1.2-0.9-3-1.5-4.4l1.7-0.7c0.5,0.9,0.9,2.1,1.2,3V225.3z M285.9,241.7c0.3,0,0.4-0.5,0.5-2.3c0.5,0.4,1.3,0.8,2,0.9c-0.2,2.5-0.8,3.5-2.2,3.5h-1.6c-1.7,0-2.2-0.7-2.2-3.2v-15.3h2.2v6.3c0.5-1.1,1-2.5,1.3-3.6l2.2,0.9c-0.7,1.4-1.5,3.1-2.2,4.1l-1.3-0.6v2l0.5-0.5c1.1,1.1,2.6,2.6,3.2,3.6l-1.5,1.5c-0.5-0.7-1.3-1.8-2.2-2.8v4.4c0,0.9,0,1,0.4,1H285.9z',
      'M309.2,226.1V244h-2.3v-0.7h-12.5v0.7h-2.2v-17.9H309.2z M306.9,241.2v-12.9h-12.5v12.9H306.9zM301.5,238.5c-0.1,0.4,0,1.1,0,1.4c-3.4,0.8-4,1-4.3,1.2c-0.1-0.4-0.3-1-0.5-1.2c0.3-0.1,0.7-0.4,0.7-1.1v-0.7c-0.5,0.2-1.1,0.4-1.6,0.5c-0.2-0.3-0.8-1-1.1-1.3c1.3-0.3,2.7-0.7,3.8-1.2h-2.3v-2.9h9.2v2.9H301c-0.6,0.5-1.3,0.9-2,1.2v1.7L301.5,238.5z M306.2,232.7h-11.1v-1.3h4.5v-0.8H296v-1.3h3.5v-0.8h2.2v0.8h3.7v1.3h-3.7v0.8h4.5V232.7z M298.1,235.1h5.2v-0.8h-5.2V235.1z M306.4,237.2c-0.5,0.4-1.1,0.7-1.7,1.1c0.7,0.5,1.3,1,1.8,1.4l-1.5,0.9c-1-0.9-3.1-2.5-4.8-3.5l1.4-0.8c0.6,0.3,1.2,0.8,1.9,1.2c0.6-0.4,1.1-0.8,1.5-1.1L306.4,237.2z'
    ],
    name: '桃園市',
  },
  {
    path: [
      'm322.9,371.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm274.5,326.84h-14.2v-2.3h3.3c-.1-.4-.3-.9-.5-1.3l1.4-.4h-3.6v-2.2h5.2c-.1-.4-.3-.8-.5-1.1l2.4-.8c.3.6.7,1.3.9,1.9h5.2v2.2h-3.2l1.4.3c-.3.5-.6,1-.9,1.4h2.8-.1c2-1.1,4.4-3.2,5.7-5.1l2.8,1.1c-1.7,2.4-4.1,4.6-6.4,6-.4-.5-1.1-1.2-1.8-1.8v2.1h.1Zm-5.7,11.9v3.6h-2.8v-3.6h-5.1v-2.3h5.1v-1.3h-4v-7.3h11.2v7.3h-4.3v1.3h5.1v2.3h-5.2Zm-4.1-8.1h5.6v-1.1h-5.6v1.1Zm0,2.7h5.6v-1.1h-5.6v1.1Zm.8-10.4c.3.5.6,1.2.7,1.7h2.6c.2-.5.5-1.2.7-1.7h-4Zm18.1,11c-2,3.5-5.1,6.2-8.5,8.1-.4-.7-1.3-1.8-2-2.4,3.1-1.4,6.2-3.9,7.8-6.8l2.7,1.1Zm-.5-6.6c-1.9,2.5-4.6,4.7-7.2,6.3-.5-.6-1.3-1.6-2.1-2.1,2.3-1.2,5.1-3.2,6.5-5.3l2.8,1.1Z',
      'm296.6,320.04c-.7,1.6-1.5,3.2-2.4,4.7v17.5h-2.8v-13.5c-.8,1-1.6,1.9-2.4,2.6-.3-.7-1.1-2.3-1.6-3.1,2.5-2.2,5-5.7,6.4-9.3l2.8,1.1Zm9.8,19.1c1.3,0,1.5-1,1.7-5.2.7.5,1.8,1.1,2.6,1.2-.3,4.8-1.1,6.5-4.1,6.5h-4.2c-3.1,0-3.9-1-3.9-4.4v-17.9h2.9v6.3h8.7v2.8h-8.7v8.7c0,1.5.2,1.7,1.4,1.7h3.6v.3Z'
    ],
    deskPath: [
      'M334,510.9h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H334c2.8,0,5,2.2,5,5v89.7C339,508.7,336.8,510.9,334,510.9z',
      'M280.4,458.1H269v-1.8h2.7c-0.1-0.4-0.3-0.7-0.4-1.1l1.2-0.3h-2.9v-1.8h4.2c-0.1-0.3-0.3-0.6-0.4-0.9l1.9-0.7c0.3,0.5,0.6,1,0.8,1.5h4.1v1.8h-2.5l1.1,0.2c-0.3,0.4-0.5,0.8-0.7,1.1h2.3l-0.1,0c1.6-0.9,3.5-2.5,4.5-4.1l2.3,0.9c-1.4,1.9-3.3,3.7-5.1,4.8c-0.4-0.4-0.9-1-1.5-1.4V458.1z M275.9,467.6v2.9h-2.3v-2.9h-4.1v-1.9h4.1v-1h-3.2v-5.9h9v5.9h-3.5v1h4.1v1.9H275.9z M272.5,461.1h4.5v-0.9h-4.5V461.1z M272.5,463.3h4.5v-0.9h-4.5V463.3z M273.2,454.9c0.3,0.4,0.5,1,0.6,1.4h2.1c0.2-0.4,0.4-0.9,0.6-1.4H273.2z M287.7,463.8c-1.6,2.8-4.1,5-6.8,6.5c-0.4-0.6-1.1-1.4-1.6-1.9c2.5-1.1,4.9-3.1,6.2-5.4L287.7,463.8z M287.3,458.5c-1.5,2-3.7,3.8-5.7,5c-0.4-0.5-1.1-1.3-1.7-1.7c1.8-0.9,4.1-2.6,5.2-4.3L287.3,458.5z',
      'M298.1,452.6c-0.5,1.3-1.2,2.6-1.9,3.8v14.1h-2.3v-10.8c-0.6,0.8-1.3,1.5-1.9,2.1c-0.2-0.6-0.9-1.9-1.3-2.5c2-1.8,4-4.6,5.2-7.5L298.1,452.6z M306,467.9c1,0,1.2-0.8,1.4-4.1c0.5,0.4,1.5,0.9,2.1,1c-0.3,3.9-0.9,5.2-3.3,5.2h-3.3c-2.5,0-3.2-0.8-3.2-3.5v-14.3h2.3v5.1h7v2.3h-7v7c0,1.2,0.1,1.4,1.1,1.4H306z'
    ],
    name: '彰化縣',
  },
  {
    path: [
      'm322.9,465.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm277.9,428.24c-.2.4-.4.8-.7,1.1h5.5v2.2h-15.4c0,.4-.1.8-.1,1.1h4.6s0,.6-.1.9c-.2,2.5-.5,3.7-1,4.2-.4.4-.9.6-1.6.7-.6,0-1.5.1-2.6,0,0-.7-.3-1.5-.6-2.1.8.1,1.5.1,1.9.1.3,0,.5,0,.7-.2.1-.2.3-.6.4-1.5h-2.3c-.7,1.8-2.2,3.1-5.1,4-.2-.6-.8-1.5-1.3-1.9,1.9-.5,3-1.1,3.6-2h-2.6v-2.1h3.4c.1-.3.1-.7.2-1.1h-4v-2.2h5.8c-.1-.4-.4-.8-.6-1.1l1.9-.2h-4.6v-5h17.1v5h-4.4l1.9.1Zm5-11.5v2.1h-9.7v1h7.9v2h-18.5v-2h7.6v-1h-9.4v-2.1h9.4v-1.4h3v1.4h9.7Zm-16.9,9.3h11.5v-1.2h-11.5v1.2Zm2.9,1.9c.4.4.7,1,.8,1.4h4.5c.2-.5.4-1,.5-1.4h-5.8Zm3.9,4.4h8.9v6.3h-2.7v-.6h-3.5v.6h-2.6v-6.3h-.1Zm2.6,2v1.7h3.5v-1.7h-3.5Z',
      'm300.7,421.64v2.8h8v8.4c0,1.5-.3,2.3-1.3,2.7-1,.5-2.5.5-4.4.5-.1-.9-.6-2.1-1-2.9,1.2.1,2.7.1,3.1,0,.4,0,.5-.1.5-.4v-5.5h-4.9v11.3h-3.1v-11.2h-4.6v8.8h-3v-11.7h7.6v-2.8h-9.7v-2.9h10c-.4-.9-.8-1.9-1.3-2.7l2.9-.9c.6,1.1,1.4,2.6,1.9,3.7h9.2v2.9h-9.9v-.1Z'
    ],
    deskPath: [
      'M334,624.1h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H334c2.8,0,5,2.2,5,5v89.7C339,621.8,336.8,624.1,334,624.1z',
      'M283.2,575.3c-0.2,0.3-0.4,0.6-0.5,0.9h4.4v1.7h-12.4c0,0.3-0.1,0.6-0.1,0.9h3.7c0,0,0,0.5-0.1,0.7c-0.2,2-0.4,3-0.8,3.4c-0.3,0.4-0.8,0.5-1.3,0.5c-0.5,0-1.2,0.1-2.1,0c0-0.5-0.2-1.2-0.5-1.7c0.6,0.1,1.2,0.1,1.5,0.1c0.3,0,0.4,0,0.6-0.2c0.1-0.2,0.3-0.5,0.4-1.2h-1.9c-0.6,1.5-1.7,2.5-4.1,3.2c-0.2-0.5-0.7-1.2-1.1-1.6c1.5-0.4,2.4-0.9,2.9-1.6h-2.1v-1.7h2.8c0.1-0.3,0.1-0.6,0.1-0.9h-3.2v-1.7h4.7c-0.1-0.3-0.3-0.7-0.5-0.9l1.5-0.2h-3.7v-4h13.7v4h-3.6L283.2,575.3zM287.2,566.1v1.7h-7.7v0.8h6.3v1.6h-14.8v-1.6h6.1v-0.8h-7.5v-1.7h7.5V565h2.4v1.1H287.2z M273.6,573.6h9.2v-1h-9.2V573.6zM276,575.1c0.3,0.3,0.5,0.8,0.6,1.1h3.6c0.2-0.4,0.3-0.8,0.4-1.1H276z M279.1,578.6h7.1v5.1H284v-0.5h-2.8v0.5h-2.1V578.6zM281.2,580.2v1.4h2.8v-1.4H281.2z',
      'M301.5,570v2.2h6.4v6.7c0,1.2-0.2,1.8-1.1,2.2c-0.8,0.4-2,0.4-3.5,0.4c-0.1-0.7-0.5-1.7-0.8-2.3c1,0.1,2.2,0.1,2.5,0c0.3,0,0.4-0.1,0.4-0.3v-4.4h-4v9H299v-9h-3.7v7h-2.4v-9.3h6.1V570h-7.8v-2.3h8c-0.3-0.7-0.7-1.5-1-2.2l2.4-0.7c0.5,0.9,1.2,2.1,1.5,2.9h7.4v2.3H301.5z'
    ],
    name: '嘉義市',
  },
  {
    path: [
      'm322.9,277.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm282.5,232.74h-3.2v12c0,1.6-.3,2.5-1.3,2.9-1,.5-2.5.5-4.5.5-.1-.9-.5-2.2-.9-3.1,1.3.1,2.7.1,3.2.1.4,0,.6-.1.6-.5v-11.9h-2.8c-.7,1.6-1.5,3-2.3,4.2-.5-.5-1.8-1.4-2.5-1.8.5-.7,1.1-1.5,1.6-2.4h-3v15.5h-3v-15.5h-.2c-.7,1.6-1.5,3.1-2.3,4.3-.6-.5-2-1.4-2.7-1.8,1.9-2.4,3.5-6.3,4.3-10.2l3.1.6c-.3,1.4-.7,2.8-1.2,4.2h5.7v1.8c.9-2,1.6-4.3,2.1-6.6l3,.6c-.3,1.4-.7,2.9-1.2,4.2h7.9v2.9h-.4Z',
      'm299.7,231.24v2.8h8v8.4c0,1.5-.3,2.3-1.3,2.7-1,.5-2.5.5-4.4.5-.1-.9-.6-2.1-1-2.9,1.2.1,2.7.1,3.1.1s.5-.1.5-.4v-5.5h-4.9v11.2h-3.1v-11.2h-4.6v8.8h-3v-11.7h7.6v-2.8h-9.7v-2.9h10c-.4-.9-.8-1.9-1.3-2.7l2.9-.9c.6,1.1,1.4,2.6,1.9,3.7h9.2v2.9h-9.9v-.1Z'
    ],
    deskPath: [
      'M334,397.7h-89.7c-2.8,0-5-2.2-5-5V303c0-2.8,2.2-5,5-5H334c2.8,0,5,2.2,5,5v89.7C339,395.5,336.8,397.7,334,397.7z',
      'M287.8,344.9h-2.6v9.6c0,1.3-0.2,2-1.1,2.4c-0.8,0.4-2,0.4-3.6,0.4c-0.1-0.7-0.4-1.8-0.8-2.5c1,0.1,2.2,0.1,2.5,0.1c0.3,0,0.5-0.1,0.5-0.4v-9.5h-2.2c-0.6,1.3-1.2,2.4-1.9,3.3c-0.4-0.4-1.5-1.1-2-1.4c0.4-0.5,0.9-1.2,1.3-1.9h-2.4v12.4h-2.4v-12.4h-0.2c-0.6,1.3-1.2,2.5-1.9,3.4c-0.5-0.4-1.6-1.1-2.2-1.5c1.6-1.9,2.8-5.1,3.4-8.2l2.5,0.5c-0.3,1.1-0.6,2.3-1,3.4h4.5v1.4c0.7-1.6,1.3-3.4,1.7-5.3l2.4,0.5c-0.3,1.1-0.6,2.3-1,3.4h6.3V344.9z',
      'M301.6,343.7v2.2h6.4v6.7c0,1.2-0.2,1.8-1.1,2.2c-0.8,0.4-2,0.4-3.5,0.4c-0.1-0.7-0.5-1.7-0.8-2.3c1,0.1,2.2,0.1,2.5,0c0.3,0,0.4-0.1,0.4-0.3v-4.4h-4v9h-2.5v-9h-3.7v7H293v-9.3h6.1v-2.2h-7.8v-2.3h8c-0.3-0.7-0.7-1.5-1-2.2l2.4-0.7c0.5,0.9,1.2,2.1,1.5,2.9h7.4v2.3H301.6z'
    ],
    name: '新竹市',
  },
  {
    path: [
      'm322.9,559.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm281.8,509.44v2.5h-22v-2.5h9.6c-.2-.6-.4-1.2-.6-1.7l3.1-.7c.3.8.7,1.7,1,2.5h8.9v-.1Zm-.6,18.3c0,1.3-.3,1.8-1.3,2.2-.9.3-2.2.3-4,.3-.2-.6-.5-1.5-.9-2.1h-7.1v1h-2.7v-6.6h10.9v5.5h-.3,2c.3,0,.5-.1.5-.4v-6h-14.9v8.8h-2.9v-11.2h20.8v8.5h-.1Zm-2.4-9.5h-15.8v-5.3h15.8v5.3Zm-3.1-3.3h-9.7v1.4h9.7v-1.4Zm-2.3,9.7h-5.5v1.4h5.5v-1.4Z',
      'm309.7,529.34h-8v1.2h-2.6v-14.4c-.5.7-1,1.4-1.5,2-.3-.6-1.1-1.8-1.6-2.4.5-.6,1-1.3,1.5-2.1h-4.9c-.8,4.8-2,9-3.9,12-.5-.4-1.8-1.3-2.5-1.7,1.8-2.5,2.9-6.1,3.6-10.3h-2.5v-2.8h2.9c.2-1.2.2-2.4.3-3.7l2.8.2c-.1,1.2-.2,2.3-.4,3.4h4.9v2.2c1-1.7,1.8-3.7,2.4-5.7l2.5.7c-.4,1.2-.9,2.5-1.4,3.8h3.3c-.3-1-.9-2.4-1.4-3.5l2.5-.8c.6,1,1.3,2.5,1.5,3.4l-2.3.8h4.5v2.6h-2.7v2.4h2.6v2.5h-2.6v2.4h2.6v2.5h-2.6v2.4h2.9v2.9h.1Zm-13-8.6c.8,2.6,1.7,5.9,2,8.1l-2.3.8c-.1-.5-.2-1.1-.3-1.7-4.7.8-5.5,1-6.1,1.3-.1-.5-.6-1.8-.9-2.5.5-.2.9-.9,1.4-1.9s2-5.1,2.7-8.8l2.8.6c-.4,1.5-.9,3.2-1.5,4.7l2.2-.6Zm-1.1,4.9c-.3-1.4-.7-2.8-1.1-4.1-.6,1.6-1.3,3.1-1.9,4.5l3-.4Zm6.1-11.3v2.4h2.5v-2.4h-2.5Zm2.4,5h-2.5v2.4h2.5v-2.4Zm-2.4,7.3h2.5v-2.4h-2.5v2.4Z'
    ],
    deskPath: [
      'M334,737.2h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H334c2.8,0,5,2.2,5,5v89.7C339,735,336.8,737.2,334,737.2z',
      'M286.9,680v2h-17.7v-2h7.7c-0.2-0.5-0.3-1-0.5-1.4l2.5-0.6c0.2,0.6,0.5,1.4,0.8,2H286.9z M286.4,694.6c0,1-0.3,1.5-1,1.8c-0.7,0.2-1.8,0.3-3.2,0.2c-0.1-0.5-0.4-1.2-0.7-1.7h-5.7v0.8h-2.2v-5.3h8.7v4.4H282c0.7,0,1.4,0,1.6,0c0.3,0,0.4-0.1,0.4-0.3v-4.8H272v7h-2.3v-8.9h16.6V694.6z M284.5,687h-12.6v-4.3h12.6V687z M282,684.4h-7.7v1.1h7.7V684.4zM280.2,692.2h-4.4v1.1h4.4V692.2z',
      'M309.2,695.9h-6.4v0.9h-2.1v-11.5c-0.4,0.6-0.8,1.2-1.2,1.6c-0.2-0.5-0.9-1.5-1.3-1.9c0.4-0.5,0.8-1.1,1.2-1.7h-3.9c-0.6,3.9-1.6,7.2-3.1,9.6c-0.4-0.3-1.5-1.1-2-1.4c1.4-2,2.3-4.9,2.9-8.3h-2v-2.3h2.4c0.1-1,0.2-1.9,0.3-2.9l2.3,0.2c-0.1,0.9-0.2,1.9-0.3,2.8h3.9v1.7c0.8-1.4,1.5-3,1.9-4.6l2,0.6c-0.3,1-0.7,2-1.2,3h2.7c-0.2-0.8-0.7-1.9-1.1-2.8l2-0.6c0.5,0.8,1,2,1.2,2.7l-1.8,0.7h3.6v2.1h-2.2v1.9h2.1v2h-2.1v1.9h2.1v2h-2.1v2h2.3V695.9zM298.8,689c0.7,2.1,1.3,4.7,1.6,6.5l-1.9,0.7c-0.1-0.4-0.1-0.9-0.2-1.3c-3.7,0.6-4.4,0.8-4.9,1.1c-0.1-0.4-0.5-1.4-0.7-2c0.4-0.1,0.7-0.7,1.1-1.5c0.4-0.8,1.6-4.1,2.2-7l2.3,0.5c-0.3,1.2-0.7,2.5-1.2,3.8L298.8,689z M297.9,693c-0.3-1.1-0.6-2.3-0.9-3.3c-0.5,1.3-1,2.5-1.5,3.6L297.9,693z M302.8,683.9v1.9h2v-1.9H302.8z M304.8,687.9h-2v1.9h2V687.9zM302.8,693.7h2v-2h-2V693.7z'
    ],
    name: '高雄市',
  },
  {
    path: [
      'm322.9,653.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm282.1,617.74h-4.2v5.5h-3v-5.5h-4c-.5,2.1-1.7,4.1-4.8,5.5-.4-.6-1.3-1.7-2-2.2,2.1-.9,3.1-2,3.7-3.3h-3.3v-2.5h3.8v-2.6h-3.2v-2.4h2.9c-.2-.6-.6-1.3-.9-1.9l2.2-.6h-4.9v2.6c0,4-.4,9.7-2.8,13.2-.5-.5-1.9-1.4-2.6-1.7,2.2-3.1,2.4-7.8,2.4-11.4v-9.2h19.8v6.7h-4.7l2.8.7c-.4.6-.8,1.2-1.3,1.8h3.4v2.4h-3.5v2.6h4.2v2.3Zm-17.8-12.6h13.6v-1.6h-13.6v1.6Zm5.6,2.5c.4.6.8,1.4,1.1,1.9l-2,.7h6c.4-.8.8-1.8,1.1-2.6h-6.2Zm5,7.6v-2.6h-3.7v2.6h3.7Z',
      'm302,615.64c2.1,1.9,5.2,3.7,8.1,4.7-.7.6-1.6,1.8-2.1,2.6-2.9-1.2-5.9-3.4-8.2-5.8v6.1h-3.2v-5.9c-2.2,2.4-5.2,4.4-8,5.6-.5-.7-1.4-1.9-2-2.5,2.8-1,5.8-2.7,7.9-4.7h-5v-9.5h7.3v-1.4h-9.4v-2.8h9.4v-2h3.1v2h9.6v2.8h-9.6v1.4h7.6v9.5h-5.5v-.1Zm-9.7-5.8h4.4v-1.5h-4.4v1.5Zm0,3.6h4.4v-1.5h-4.4v1.5Zm7.4-5.1v1.5h4.6v-1.5h-4.6Zm4.6,3.6h-4.6v1.5h4.6v-1.5Z'
    ],
    deskPath: [
      'M334,850.4h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H334c2.8,0,5,2.2,5,5v89.7C339,848.2,336.8,850.4,334,850.4z',
      'M287.3,805.5h-3.4v4.4h-2.4v-4.4h-3.2c-0.4,1.7-1.3,3.3-3.8,4.4c-0.3-0.5-1.1-1.4-1.6-1.8c1.7-0.7,2.5-1.6,2.9-2.6h-2.7v-2h3v-0.3v-1.8h-2.6v-2h2.3c-0.2-0.5-0.5-1.1-0.8-1.5l1.7-0.5h-3.9v2.1c0,3.2-0.3,7.7-2.3,10.5c-0.4-0.4-1.5-1.1-2.1-1.4c1.8-2.5,1.9-6.3,1.9-9.2V792h15.9v5.3h-3.8l2.3,0.6c-0.3,0.5-0.7,1-1,1.5h2.8v2h-2.8v2.1h3.4L287.3,805.5z M273.1,795.4H284V794h-10.9V795.4z M277.5,797.4c0.3,0.5,0.7,1.1,0.9,1.5l-1.6,0.5h4.8c0.3-0.7,0.7-1.4,0.9-2.1H277.5z M281.5,803.5v-2.1h-3v1.9v0.2H281.5z',
      'M303.2,803.8c1.7,1.6,4.1,3,6.5,3.8c-0.6,0.5-1.3,1.4-1.7,2.1c-2.3-1-4.7-2.7-6.6-4.7v4.9h-2.5v-4.8c-1.8,1.9-4.1,3.5-6.4,4.5c-0.4-0.6-1.1-1.5-1.6-2c2.3-0.8,4.6-2.2,6.3-3.8h-4v-7.6h5.8v-1.1h-7.5v-2.2h7.5v-1.6h2.5v1.6h7.7v2.2h-7.7v1.1h6.1v7.6H303.2z M295.4,799.2h3.5V798h-3.5V799.2z M295.4,802.1h3.5v-1.2h-3.5V802.1z M301.4,798v1.2h3.7V798H301.4zM305.1,800.8h-3.7v1.2h3.7V800.8z'
    ],
    name: '屏東縣',
  },
  {
    path: [
      'm200.7,369.84h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm145.4,321.94h-5.2v-2.7h5.2v-2.2h2.9v2.2h2.7v2.7h-2.7v2.4h-2.9v-2.4Zm-3.1,3.1h18v15.3h-3v-1.1h-12.2v1.1h-2.8v-15.3Zm2.9,2.7v3h4.5v-3h-4.5Zm4.5,8.8v-3.1h-4.5v3.1h4.5Zm12.7-17.4v2.7h-5.3v2.4h-3v-2.4h-2.4v-2.7h2.4v-2.2h3v2.2h5.3Zm-5,8.6h-4.8v3h4.7v-3h.1Zm0,8.8v-3.1h-4.8v3.1h4.8Z',
      'm176.5,334.84c-1.7,2.1-4.7,3.9-7.7,4.8-.4-.7-1.3-1.9-1.9-2.4,2.8-.6,5.7-1.8,7.2-3.3l2.4.9Zm3.7-1.2v6.6h-3v-6.6h-9.6v-2.6h9.6v-1.5h3v1.6h9.7v2.6h-9.7v-.1Zm-5.7-13h-6.7v-2.6h21.9v2.6h-7.1v1.5h5.7v6.9h-19.1v-6.9h5.3v-1.5Zm-2.6,6.1h2.5v-2.1h-2.5v2.1Zm8-6.1h-2.6v1.5h2.6v-1.5Zm-2.7,6.1h2.6v-2.1h-2.6v2.1Zm6.2,7c2.2,1.1,5.4,2.8,7,4.1l-2.3,1.9c-1.4-1.2-4.5-3.1-6.8-4.3l2.1-1.7Zm-.8-9.1v2.1h2.7v-2.1h-2.7Z'
    ],
    deskPath: [
      'M214,512.1h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H214c2.8,0,5,2.2,5,5v89.7C219,509.8,216.8,512.1,214,512.1z',
      'M153.3,461h-4.1v-2.2h4.1v-1.7h2.3v1.7h2.1v2.2h-2.1v1.9h-2.3V461z M150.8,463.6h14.4v12.2h-2.4V475h-9.7v0.9h-2.3V463.6z M153.1,465.7v2.4h3.6v-2.4H153.1z M156.8,472.8v-2.5h-3.6v2.5H156.8z M166.9,458.8v2.2h-4.2v1.9h-2.4V461h-1.9v-2.2h1.9v-1.7h2.4v1.7H166.9z M162.9,465.7h-3.8v2.4h3.8V465.7z M162.9,472.8v-2.5h-3.8v2.5H162.9z',
      'M178.2,471.4c-1.4,1.7-3.8,3.2-6.2,3.8c-0.3-0.6-1-1.5-1.6-2c2.3-0.5,4.6-1.5,5.8-2.6L178.2,471.4zM181.2,470.5v5.3h-2.4v-5.3h-7.7v-2.1h7.7v-1.3h2.4v1.3h7.7v2.1H181.2z M176.6,460h-5.4V458h17.6v2.1h-5.7v1.2h4.5v5.6h-15.3v-5.6h4.2V460z M174.5,464.9h2v-1.7h-2V464.9z M180.9,460h-2.1v1.2h2.1V460z M178.8,464.9h2.1v-1.7h-2.1V464.9z M183.7,470.6c1.8,0.9,4.3,2.3,5.6,3.3l-1.8,1.5c-1.2-1-3.6-2.5-5.5-3.5L183.7,470.6z M183.1,463.2v1.7h2.2v-1.7H183.1z'
    ],
    name: '苗栗縣',
  },
  {
    path: [
      'm200.7,463.34h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.8-2.2,5-5,5Z',
      'm162.6,431.34h-5.3c1.6,1.2,3.4,2.7,4.4,3.8l-2.7,1.2c-.3-.3-.6-.7-1-1.1-12.3.5-13.7.5-14.8.8-.2-.6-.6-2-.9-2.7.5-.1.9-.3,1.5-.7.3-.2.9-.6,1.6-1.2h-5.4v-2.2h22.6v2.1Zm-19.7-9.5h-2.6v-5.3h9.4v-.8h-7.8v-2.1h18.4v2.1h-7.8v.8h9.6v5.3h-2.7v-3.4h-6.8v6.6h-2.9v-6.6h-6.8v3.4h0Zm-.6,1.6c1.5-.3,3.6-1,5.8-1.6-1.1-.5-3.2-1.3-4.7-1.8l1-1.6c1.5.4,3.7,1.1,4.8,1.6l-1,1.7.8-.2.3,1.9c-2.1.8-4.2,1.6-6,2.3l-1-2.3Zm17.4,4.6h-16.8v-2.1h16.8v2.1Zm-4.2,5c-.5-.4-1.1-.9-1.6-1.3l1-.4h-5.5c-.8.7-1.6,1.3-2.4,1.9l8.5-.2Zm3.8-12.8c-1.3.6-3.2,1.1-4.6,1.5,1.8.5,4.1,1.3,5.4,1.9l-.9,2c-1.3-.7-4.1-1.7-6.1-2.3l.8-1.6-.9-1.4c1.4-.3,3.7-1.2,5-1.8l1.3,1.7Z',
      'm176.3,428.04c-.4-.8-1.2-2-1.9-3.1v11.2h-2.9v-10c-.9,2.3-2,4.4-3.2,5.8-.3-.8-1-2.1-1.5-2.9,1.7-2,3.4-5.3,4.2-8.3h-3.5v-2.8h3.9v-5.2h2.9v5.2h3.3v2.8h-3.3v.4c.7.7,3.2,3.6,3.8,4.3l-1.8,2.6Zm9.8-7.2c1,3.4,2.7,6.9,4.6,9-.7.5-1.7,1.5-2.2,2.3-1.1-1.5-2.1-3.6-2.9-5.9v10h-3v-10.1c-1.2,2.7-2.7,5.1-4.3,6.7-.3-.8-1.1-2-1.7-2.7,2.3-2.2,4.3-5.7,5.5-9.2h-3.6v-2.8h4.1v-5.2h3v5.2h4.4v2.8h-3.9v-.1Z'
    ],
    deskPath: [
      'M214,624.2h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H214c2.8,0,5,2.2,5,5v89.7C219,622,216.8,624.2,214,624.2z',
      'M167,579.8h-4.3c1.3,1,2.7,2.2,3.5,3l-2.2,1c-0.2-0.3-0.5-0.6-0.8-0.9c-9.8,0.4-11,0.4-11.8,0.7c-0.1-0.5-0.5-1.6-0.8-2.2c0.4-0.1,0.8-0.3,1.2-0.6c0.3-0.2,0.8-0.5,1.3-1h-4.3V578H167V579.8z M151.3,572.2h-2.1v-4.2h7.6v-0.7h-6.2v-1.7h14.8v1.7h-6.2v0.7h7.7v4.2h-2.2v-2.8h-5.5v5.3h-2.3v-5.3h-5.5V572.2z M150.8,573.5c1.2-0.3,2.9-0.8,4.6-1.3c-0.9-0.4-2.6-1.1-3.8-1.4l0.8-1.3c1.2,0.3,2.9,0.9,3.8,1.3l-0.8,1.4l0.7-0.2l0.3,1.6c-1.7,0.6-3.4,1.3-4.8,1.8L150.8,573.5zM164.7,577.2h-13.5v-1.7h13.5V577.2z M161.4,581.2c-0.4-0.4-0.9-0.7-1.3-1l0.8-0.3h-4.4c-0.6,0.5-1.3,1.1-1.9,1.6L161.4,581.2zM164.4,570.9c-1.1,0.5-2.6,0.9-3.7,1.2c1.5,0.4,3.3,1.1,4.4,1.5l-0.7,1.6c-1.1-0.5-3.3-1.4-4.9-1.9l0.7-1.3l-0.7-1.2c1.2-0.3,2.9-1,4-1.4L164.4,570.9z',
      'M178,577.2c-0.4-0.7-1-1.6-1.6-2.5v9h-2.3v-8c-0.7,1.8-1.6,3.5-2.5,4.6c-0.2-0.7-0.8-1.7-1.2-2.3c1.4-1.6,2.7-4.2,3.4-6.6H171v-2.2h3.2V565h2.3v4.1h2.6v2.2h-2.6v0.3c0.6,0.6,2.6,2.9,3,3.4L178,577.2z M185.9,571.4c0.8,2.7,2.2,5.5,3.7,7.2c-0.6,0.4-1.4,1.2-1.8,1.8c-0.9-1.2-1.7-2.9-2.4-4.8v8H183v-8.1c-0.9,2.1-2.1,4.1-3.4,5.4c-0.3-0.7-0.9-1.6-1.3-2.2c1.9-1.8,3.5-4.6,4.4-7.4h-2.9v-2.2h3.3V565h2.4v4.1h3.5v2.2H185.9z'
    ],
    name: '雲林縣',
  },
  {
    path: [
      'm200.7,556.94h-73.1c-2.8,0-5-2.2-5-5v-73.1c0-2.8,2.2-5,5-5h73.1c2.8,0,5,2.2,5,5v73.1c0,2.7-2.2,5-5,5Z',
      'm161.3,505.44v1.8h-9.7v.8h7.6v1.7h-18v-1.7h7.4v-.8h-9.4v-1.8h9.4v-1.2h3v1.2h9.7Zm-9.6,19.6h9.5v2h-21.8v-2h9.3v-.8h-6.8v-1.7h6.8v-.7c-4.3.1-5,.2-5.6.3-.1-.4-.4-1.3-.6-1.8.4,0,.8-.2,1.3-.3.3-.1.9-.3,1.7-.7h-3.4v-.4h-2.6v-3.7h21.5v3.7h-2.8v-1.9h-16.1v.7h16v1.6h-1.8c1.1.6,2.1,1.4,2.8,1.9l-1.6,1.3h1.5v1.7h-7.3v.8h0Zm6.8-14.5v3.8h-16.4v-3.8h16.4Zm-2.9,1.5h-10.7v.9h10.7v-.9Zm-6.9,7.2c-.6.3-1.1.7-1.7.9l6.8-.1c-.5-.3-.9-.5-1.3-.7l.1-.1h-3.9Zm3,3.2h5.6c-.3-.3-.7-.6-1.1-.9l-4.5.1v.8Z',
      'm179,509.04c-.1.7-.2,1.3-.3,1.9h9.2v13.6c0,1.4-.3,2.1-1.4,2.5-1,.4-2.5.4-4.5.4-.1-.7-.6-1.8-.9-2.5,1.2.1,2.8.1,3.1.1.4,0,.6-.1.6-.5v-10.8h-4.8l2.5.5c-.3.9-.7,1.8-1,2.6h1.8v2.3h-4.2v1.6h4.8v2.4h-4.8v3.7h-2.8v-3.7h-4.7v-2.4h4.6v-1.6h-4.1v-2.3h1.9c-.2-.7-.6-1.7-1-2.5l2.3-.6c.4.8.9,2,1.1,2.7l-1.3.4h3.6c.4-1,.7-2.2.9-3.1h-9v13.8h-3v-16.5h8.1c.1-.6.2-1.2.3-1.9h-9.3v-2.8h9.6c0-.7.1-1.4.1-2.1l3.3.3c-.1.6-.2,1.2-.2,1.8h9.3v2.8h-9.8v-.1Z'
    ],
    deskPath: [
      'M214,736.4h-89.7c-2.8,0-5-2.2-5-5v-89.7c0-2.8,2.2-5,5-5H214c2.8,0,5,2.2,5,5v89.7C219,734.1,216.8,736.4,214,736.4z',
      'M167.1,678.3v1.5h-7.7v0.7h6.1v1.4H151v-1.4h5.9v-0.7h-7.6v-1.5h7.6v-1h2.4v1H167.1z M159.3,693.9h7.6v1.6h-17.4v-1.6h7.4v-0.6h-5.5v-1.4h5.5v-0.6c-3.4,0.1-4,0.1-4.5,0.3c-0.1-0.3-0.3-1.1-0.5-1.5c0.3,0,0.7-0.1,1-0.3c0.2-0.1,0.8-0.3,1.4-0.6h-2.7v-0.3h-2.1v-3h17.2v3h-2.2v-1.5h-12.9v0.6h12.8v1.3h-1.4c0.9,0.5,1.7,1.1,2.2,1.5l-1.3,1.1h1.2v1.4h-5.9V693.9z M164.8,682.3v3h-13.1v-3H164.8z M162.5,683.5h-8.6v0.7h8.6V683.5z M157,689.3c-0.5,0.3-0.9,0.5-1.3,0.8l5.5-0.1c-0.4-0.2-0.8-0.4-1.1-0.6l0.1-0.1H157z M159.3,691.9h4.5c-0.2-0.2-0.6-0.5-0.9-0.7l-3.6,0.1V691.9z',
      'M181.2,681.1c-0.1,0.5-0.2,1-0.3,1.5h7.3v10.9c0,1.2-0.3,1.7-1.1,2c-0.8,0.3-2,0.3-3.6,0.3c-0.1-0.6-0.5-1.5-0.8-2c1,0.1,2.2,0.1,2.5,0c0.4,0,0.5-0.1,0.5-0.4v-8.7H182l2,0.4c-0.3,0.7-0.6,1.5-0.8,2.1h1.5v1.8h-3.4v1.3h3.8v1.9h-3.8v3H179v-3h-3.7v-1.9h3.7v-1.3h-3.3v-1.8h1.5c-0.2-0.6-0.5-1.4-0.8-2l1.9-0.5c0.4,0.7,0.7,1.6,0.9,2.2l-1.1,0.3h2.9c0.3-0.8,0.6-1.8,0.8-2.5h-7.4v11.1h-2.4v-13.2h6.5c0.1-0.5,0.2-1,0.2-1.5h-7.5v-2.2h7.7c0-0.6,0.1-1.2,0.1-1.7l2.6,0.2c-0.1,0.5-0.1,1-0.2,1.4h7.4v2.2H181.2z'
    ],
    name: '臺南市',
  },
]

const Map = ({ city, setCity, data }) => {
  const [isMobile] = useMediaQuery('(max-width: 1152px)')
  return (
    <SVG viewBox={isMobile ? "0 0 568 655" : "0 0 577.3 850.4"}>
      {cities.map((d, i) => {
        const hasData = data?.[d.name]?.after
        return (
          <g
            id={d.name}
            cursor={hasData ? 'pointer' : 'not-allowed'}
            onClick={() => {
              if (hasData) {
                if (city === d.name) {
                  setCity()
                } else {
                  setCity(d.name)
                }
              }
            }}
            opacity={hasData ? 1 : 0.6}
            key={i}
          >
            {d[isMobile ? 'path' : 'deskPath'].map((p, key) => (
              <path d={p} fill={key ? (d.name === city ? '#fff' : '#323333') : (d.name === city ? '#000' : '#fff')} key={key}/>
            ))}
          </g>
        )}
      )}
    </SVG>
  )
}

export default Map
