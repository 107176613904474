import { css } from '@emotion/react'

export default css`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&family=Noto+Sans:wght@400;700;900&family=Noto+Serif+TC:wght@400;500;700&family=Open+Sans:wght@400;500;600;700;800&display=swap');
  body {
    min-width: 100%;
    min-height: 100%;
  }

  img {
    width: 100%;
  }
`;
