import { useAsync } from 'react-use';
import getCacheTime from './getCacheTime';

// const sampleTags = [{ a1: '施政民調' }, { a1: '主題報導' }, { a1: '專題組織' }];

const useNews = () =>
  useAsync(async () => {
    const res = await fetch(`/data/news.json?t=${getCacheTime()}`);
    const json = await res.json();
    return json;
    // return json.map((item, i) => ({
    //   ...item,
    //   tags: [sampleTags[i % sampleTags.length]],
    // }));
  });

export default useNews;
