import React from 'react'
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react'
// import { WiMoonFirstQuarter } from 'react-icons/wi'
import styled from '@emotion/styled'
import range from 'lodash/range'
import set from 'lodash/set'
import isObject from 'lodash/isObject'
import Button from 'components/Button'

export const StyledCheckbox = styled(Checkbox)`
  font-weight: 500;
  .chakra-checkbox__label {
    font-size: 1em;
    margin-left: 4px;
  }
  .chakra-checkbox__control {
    border-width: 0.5px;
    width: 11px;
    height: 11px;
    background-color: white;
    svg { font-size: 1em; }
  }
  .chakra-checkbox__control[data-checked] {
    background-color: black;
  }
  .chakra-checkbox__control[data-checked][data-hover] {
    background-color: black;
  }
  .chakra-checkbox__control[data-focus] {
    box-shadow: none;
  }
  @media screen and (max-width: 480px) {
    .chakra-checkbox__control {
      width: 10px;
      height: 10px;
      svg { font-size: 0.875em; }
    }
    .chakra-checkbox__label {
      font-size: 0.75rem;
    }
    .chakra-checkbox__control[data-hover] {
      background-color: white;
    }
  }
`

const Filter = ({ checkedItems, setCheckedItems, filters, ...props }) => {
  // console.log(checkedItems)
  return (
    <Box
      pos="absolute"
      top="100%"
      left="0"
      right="0"
      bg="white"
      borderRadius="0.5em"
      width={{base: '100%', lg: '25em'}}
      px={{base: '1em', lg: '1.625em'}}
      pt={{base: '1em', lg: '2em'}}
      pb={{base: '1em', lg: '1.5em'}}
      {...props}
    >
      <Box textAlign={'right'}>
        <Button
          textDecoration="underLine"
          bg="transparent"
          color="black"
          minW="autp"
          px="0"
          onClick={() => {
            setCheckedItems(filters.map(d => range(d.options.length).fill(false)))
          }}
          fontSize={{ base: '0.875em', md: '1em' }}
        >
          清除選取
        </Button>
      </Box>
      {filters.map(({ name, options }, k) => (
        <Box key={k} pt={k && '1em'} borderBottom="1px solid #89897B" pb="0.625em">
          <Flex justifyContent={'space-between'}>
            <Flex alignItems={'center'}>
              {/* <Box transform={'rotate(22.5deg)'} mr="0.5em"><WiMoonFirstQuarter size="1.25em" /></Box> */}
              <Text fontWeight={500} fontSize={{base: '0.875em', lg: '1em'}}>{name}</Text>
            </Flex>
          </Flex>
          <Flex gap={'4px'} pt={{ base: '0.5em' }} flexWrap="wrap">
            {options.map((opt, i) => (
              <Box key={i}>
                <StyledCheckbox
                  icon={<div />}
                  borderColor="#89897B"
                  isChecked={checkedItems[k][i]}
                  onChange={(e) => {
                    set(checkedItems, [k, i], e.target.checked)
                    setCheckedItems([...checkedItems])
                    if (typeof window.gtag === 'function') {
                      window.gtag("event", "click_button", {
                        button_name: isObject(opt) ? opt.name : opt
                      });
                    }
                  }}
                >
                  {isObject(opt) ? opt.name : opt}
                </StyledCheckbox>
              </Box>
            ))}
          </Flex>
        </Box>
      ))}
      {/* <Box textAlign={'center'} mt="1em">
        <Button
          onClick={() => {
            setFilter(false)
            if (typeof window.gtag === 'function') {
              window.gtag("event", "click_button", {
                button_name: 'comfirm'
              });
            }
          }}
        >確認</Button>
      </Box> */}
    </Box>
  )
}

export default Filter
