import React from 'react'
import { Text } from '@chakra-ui/react'

const ReText = (props) => {
  return (
    <Text fontWeight="500" {...props} />
  )
}

ReText.Title = (props) => <ReText fontSize={{base: '1.5em', md: '2.25em'}} fontWeight={900} {...props} />;

export default ReText
