import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

// import theme from '../components/ThemeProvider/theme';
import headerContext from '../contexts/header/context';

import Header from './Header';

const Layout = () => {
  const { hideHeader, setHideHeader } = useContext(headerContext);
  useEffect(() => {
    let scrollTimeout;

    function handleScroll() {
      setHideHeader(true);

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setHideHeader(false);
      }, 300); // 設定滑動停止的閾值，這裡設定為200毫秒
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Header
        transform={hideHeader ? 'translateY(-100%)' : 'none'}
        transition="transform 0.5s"
        siteTitle=""
      />
      <Box as="main" minHeight="100vh">
        <Outlet />
      </Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
