import React, { useState, useMemo } from 'react';
import { Box, Circle, Container, Flex, Image } from '@chakra-ui/react';
import { useMeasure } from 'react-use';
import { scroller } from 'react-scroll';
import Text from 'components/Text';
import Button from 'components/Button';
import useNews from 'hooks/useNews';

import News from './News';
import wave from './wave.png';
import report_circle from './report_circle.png';
import policy from './policy.png';
import topic from './topic.png';
import group from './group.png';

import arrow from '../Overview/arrow.png';
import { sortBy } from 'lodash';

const reports = [
  {
    label: '施政民調',
    key: '施政滿意度',
    desc: '菱傳媒每半年將會公布施政滿意度網路調查，結合政見追蹤專區，全面檢視施政情況。',
    id: 'policy',
    img: policy,
    link: 'https://rwnews.tw/search_result.php?tag=施政滿意度&utm_term=施政滿意度',
  },
  {
    label: '主題報導',
    key: '政見新聞',
    desc: '統整各式深度內容報導，深入淺出，探討各式政見內容與進度。',
    id: 'topic',
    img: topic,
    link: 'https://rwnews.tw/search_result.php?tag=政見新聞&utm_term=政見新聞',
  },
  {
    label: '民團專題',
    key: '民團看政見',
    desc: '與公民團體、學者專家共組監督聯盟，持續對話探討各個議題面向。',
    id: 'group',
    img: group,
    link: 'https://rwnews.tw/search_result.php?tag=民團看政見&utm_term=民團看政見',
  },
];

const Report = () => {
  const news = useNews();
  const [ref, { width }] = useMeasure();
  const [open, setOpen] = useState();
  const newsData = useMemo(
    () =>
      !news.loading
        ? reports.map((report) => ({
            ...report,
            news: sortBy(news?.value.filter((d) =>
              d?.tags?.some((t) => t === report.label || t === report.key)
            ), (o) => new Date(o.a6)).reverse(),
          }))
        : [],
    [news]
  );
  return (
    <Box ref={ref} id="reports">
      <Box bg="custom.bgBlue" pos="relative" overflow={'hidden'}>
        <Box pos="absolute" left="0" right="0" top="0">
          <Image src={wave} />
        </Box>
        <Container pt={{ base: '5.875em', md: '18em' }}>
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            justifyContent={'space-between'}
            color="white"
            pb={{ base: '3.5em', md: '3.75em' }}
            gap={4}
          >
            <Text
              fontSize={{ base: '1.125em', md: '2.25em' }}
              fontWeight="black"
              width={{ base: 'auto', md: '22.875rem' }}
            >
              你的持續關注，
              <br />
              能讓民主選舉更具意義
            </Text>
            <Text
              pt={{ base: 4, md: 0 }}
              pr={{ base: 0, md: '4.5rem' }}
              fontSize={{ base: '1em', md: '1.25em' }}
              width={{ base: 'auto', md: '32rem' }}
              fontWeight="bold"
            >
              我們將持續更新各式主題報導、每半年公布縣市長滿意度民調，也會與公民組織合作深度專題。
              <br />
              <br />
              你能從自身所在意的主題類別出發，與我們一起從多種面向探討政見與議題。
            </Text>
          </Flex>
        </Container>
      </Box>
      <Box pr={{ base: '3em', md: '4.75em' }} pos="relative">
        <Box
          pos="absolute"
          top="50%"
          bottom={0}
          right={0}
          width={{ base: '2em', md: '4.75em' }}
          bg="custom.bgGray"
        />
        <Box
          width={{ base: '5.75em', md: '150px' }}
          pos="absolute"
          right="0"
          top="0"
        >
          <Image src={report_circle} />
        </Box>
        <Box bg="custom.yellow" py={{ base: '1.75em', md: '3em' }}>
          <Box
            pl={{
              base: width < 480 ? '2rem' : `${(width - 480) / 2 + 32}px`,
              md: `${(width * 85) / 1000}px`,
              xl: width < 1440 ? '7.625em' : `${(width - 1440) / 2 + 122}px`,
            }}
          >
            <Text fontSize={{ base: '1.5em', md: '2.25em' }} fontWeight="bold">
              民調&報導
            </Text>
          </Box>
        </Box>
      </Box>
      <Box bg="custom.bgGray" pos="relative" id="news">
        <Container
          pt={{ base: '3.125em', md: '5em' }}
          pb={{ base: '1em', md: '3.25em' }}
        >
          {newsData.map((d, i) => (
            <Box mt={i && { base: '2em', md: '2.625em' }} id={d.id} key={i}>
              <Box
                display={'inline-flex'}
                bg="white"
                gap={{ base: 1, md: '0.625em' }}
                px={{ base: '1.25em', md: '2.5em' }}
                py={{ base: '0.375em', md: 2 }}
                borderRadius={'1em 1em 0 0'}
                alignItems={'center'}
              >
                <Circle
                  w={{ base: '0.625em', md: '1.125em' }}
                  h={{ base: '0.625em', md: '1.125em' }}
                  bg="custom.btnBg"
                />
                <Text as="span" fontSize={{ base: '0.875em', md: '1.25em' }}>
                  {d.label}
                </Text>
              </Box>
              <Box
                px={{ base: '1.25em', md: '3.75em' }}
                pt={{ base: '1.25em', md: '2em' }}
                pb={{ base: '0.375em', md: '1.25em' }}
                bg="white"
              >
                <Text fontSize={{ base: '0.875em', md: '1em' }}>{d.desc}</Text>
                <Box
                  mt={{ base: '0.875em', md: '1.5em' }}
                  mb={{ base: '0.375em', md: '1.5em' }}
                  height="1px"
                  bg="custom.lineGray"
                />
                <News data={d} isOpen={open === i} />
                <Box
                  pos="relative"
                  transform={{ base: 'none', md: 'translateX(2.5em)' }}
                  textAlign={'right'}
                >
                  <Button
                    color="white"
                    bg="custom.darkGray"
                    px={{ base: '0.75rem', md: '1.625rem' }}
                    borderRadius="full"
                    fontSize={{ base: '0.875em', md: '1em' }}
                    py={'0.375rem'}
                    h="auto"
                    lineHeight={{ base: 1, md: 1.5 }}
                    onClick={() => {
                      if (open === i) {
                        setOpen();
                      } else {
                        setOpen(i);
                      }
                      if (document) {
                        let dom = document.getElementById('news')
                        if (dom) {
                          dom.scrollIntoView({ behavior: 'smooth' }, true)
                        }
                      }
                    }}
                    _hover={{}}
                    _active={{}}
                  >
                    {open === i ? '收合' : '展開'}
                    <Box
                      mb={{ base: '0.125em', md: 0 }}
                      transformOrigin={'center'}
                      transform={open === i && 'rotate(90deg)'}
                      width="0.5rem"
                      ml="0.625em"
                      display={'inline-block'}
                    >
                      <Image src={arrow} />
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Container>
      </Box>
    </Box>
  );
};

export default Report;
